import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

// Components
import { TapappsPOSComponent } from './tapapps-pos.component';

// Feature components

// Routing
import { tapappsposRouting } from './tapapps-pos.routing';

// Main service
import { TapappsPOSService } from './tapapps-pos.service';

// Feature services

@NgModule({
    imports: [
        SharedModule,
        tapappsposRouting
    ],
    declarations: [
        // Feature declarations
        TapappsPOSComponent
    ],
    providers: [
        // Feature providers
        TapappsPOSService
    ]
})
export class TapappsPOSModule {}
