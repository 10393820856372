// Core
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { PlatformService } from '../../../../platform.service';
import { CardsAppService } from '../../cards-app.service';
import { TemplateService } from '../template.service';
import { LogService } from '../../../../shared/log.service';

// Components and directives
import { AbstractListComponent } from '../../../../abstract/app/feature/list/abstract-list.component';

@Component({
    selector: 'cards-template-list',
    templateUrl: 'template-list.component.html'
})
export class TemplateListComponent extends AbstractListComponent {
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public platformService: PlatformService,
        public appService: CardsAppService,
        public service: TemplateService,
        public logService: LogService
    ) {
        super( route, router, location, platformService, appService, service, logService );
    }
}
