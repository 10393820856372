import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as yaml from 'js-yaml';

@Component( {
  selector: 'xiroco-yaml-json',
  templateUrl: 'yaml-json.component.html'
} )
export class YamlJsonComponent implements OnInit, OnDestroy {

  public submitted = false;
  public errorMessage: string;
  public serverError: boolean = true;
  public cardFields: string[] = [];

  public titleField: string = '';
  public featuredFields: string[] = [];

  public theTitle: string = 'hola';
  public editing: boolean = false;
  public wrong: boolean = false;

  @Input() obj: Object;
  @Input() id: string;
  @Input() title: string;
  @Input() lines: number = 20;
  @Output() docValueChange = new EventEmitter();

  public tmp: object;
  public JSONString: string;
  public YAMLString: string;
  public goodValue: object;
  public YAMLError: any;
  public JSONError: any;
  public editionFormat: string = 'YAML';

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public location: Location
  ) { }

  getRandomId() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for ( let i = 0; i < 5; i++ ) {
      text += possible.charAt( Math.floor( Math.random() * possible.length ) );
    }
    return text;
  }

  ngOnInit() {
    const self = this;
    if ( !this.id ) {
      this.id = this.getRandomId();
    }
    console.log( self.obj );
    self.tmp = self.obj;
    console.log( self.tmp );

    self.initStringFields();

  }

  /**
   * This method initialize YAML and JSON string fields with initial value
   */
  initStringFields(): void {
    if ( this.obj ) {
      if ( Object.keys( this.obj ).length === 0 ) {
        this.YAMLString = '';
      } else {
        this.YAMLString = yaml.dump( this.obj );
      }
      this.JSONString = JSON.stringify( this.obj, null, 2 );
    }
  }

  setYAML() {
    this.editionFormat = 'YAML';
  }
  setJSON() {
    this.editionFormat = 'JSON';
  }


  getObjectCopy( source: object ): object {
    return JSON.parse( JSON.stringify( source ) );
  }

  ngOnDestroy() { }

  canCancel(): boolean {
    return this.editing;
  }

  cancel(): void {
    this.undo();
    this.editing = false;
  }

  canUndo(): boolean {
    return true;
  }

  undo(): void {
    this.initStringFields();
  }

  canConfirm(): boolean {
    return this.editing && !this.YAMLError && !this.JSONError;
  }

  edit(): void {
    this.editing = true;
  }

  confirm() {
    this.editing = false;
    if ( this.editionFormat === 'JSON' ) {
      try {
        this.tmp = JSON.parse( this.JSONString );
        this.YAMLString = yaml.dump( this.tmp );
        this.docValueChange.emit( this.tmp );
      } catch ( error ) {
        console.error( error );
      }
    } else {
      this.tmp = yaml.load( this.YAMLString );
      this.JSONString = JSON.stringify( this.tmp, null, 2 );
      this.docValueChange.emit( this.tmp );
    }


  }

}
