/**
 * Model
 */
import { IServiceIssues,  } from './service-issues.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class ServiceIssues extends AbstractModel {
    constructor( obj: IServiceIssues = <IServiceIssues>{} ) {

        super( obj );

        // Default values
        if ( typeof( this.data.groupId ) === 'undefined' ) this.data.groupId = '';
        if ( typeof( this.data.itemId ) === 'undefined' ) this.data.itemId = '';
        if ( typeof( this.data.serviceId ) === 'undefined' ) this.data.serviceId = '';
        if ( typeof( this.data.issueId ) === 'undefined' ) this.data.issueId = '';

        // Fixed info
        this.type = 'serviceIssues';
        this.app = 'chatandbot';
        this.params.collection = 'serviceIssues';
        this.params.type = 'serviceIssues';

    }
}
