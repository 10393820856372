/**
 * Service
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../../../abstract/app/feature/abstract.service';

import { PlatformService } from '../../../platform.service';
import { ChatAndBotService } from '../chatandbot.service';
import { Item } from './item.model';

import { LogService } from '../../../shared/log.service';



@Injectable()
export class ItemService extends AbstractService {

    // Get cache from local storage (if exists)
    public localStorage: boolean = true;

    constructor(
        public http: HttpClient,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public logService: LogService
        
    ) {
        super( 'item', http, platformService, appService, logService );
    }

    /**
     * This method returns an empty doc that it is necessary
     * to set up details component form
     * Here you can implement (i.e.) new id counter
     * @returns {Item}
     */
    public getNewDoc( doc: any ) {
        return new Item( doc );
    }

}
