// Core modules
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

// Services
import { PlatformService } from '../../platform.service';
import { AuthService } from '../..//auth/auth.service';

// Components
import { AppGadgetComponent } from './app-gadget/app-gadget.component';
import { AbstractAppService } from '../../abstract/app/abstract-app.service';
import { AppService } from '../../apps/xiroco/app/app.service';
import { CardsAppService } from '../../apps/cards/cards-app.service';
import { XirocoService } from '../../apps/xiroco/xiroco.service';
import { TapappsService } from '../../apps/tapapps/tapapps.service';
import { ChatAndBotService } from '../../apps/chatandbot/chatandbot.service';
import { TapappsPOSService } from '../../apps/tapapps-pos/tapapps-pos.service';
import { BitacoraService } from '../../apps/bitacora/bitacora.service';


@Component( {
  selector: 'app-home',
  templateUrl: 'home.component.html'
} )
export class HomeComponent implements OnInit {

  public appService: AbstractAppService;

  constructor( private router: Router,
               private platformService: PlatformService,
               private authService: AuthService,
               private changeDetectorRef: ChangeDetectorRef,
               private cardsAppService: CardsAppService,
               private xirocoService: XirocoService,
               private tapappsService: TapappsService,
               private chatandbotService: ChatAndBotService,
               private tapappsposService: TapappsPOSService,
               private bitacoraService: BitacoraService
              ) {}

  ngOnInit() {
    this.platformService.showNav = true;
    this.platformService.loaderVisible = false;
    this.platformService.showHomeButton = false;

    // Hide bottom bar in this page
    this.platformService.showBottomNavBar = false;
    this.changeDetectorRef.detectChanges();

    this.appService = this.platformService.getAppService( 'xiroco' );


  }


  getAppsServices(): any {
    return this.platformService.getAppsServices();
  }



  signedIn(): boolean {
    return this.authService.isSignedIn();
  }

  

  showSignUpButton(): boolean {
    return true;
  }



  /**
   * Redirect to sing up page
   */
  goToSignUp(): void {
    this.router.navigate( [ 'signup' ] );
  }

  

  activeServer(): string {
    return this.platformService.activeServer;
  }

  toggleServer(): void {
    this.platformService.setActiveServer( this.activeServer() === 'localhost' ? 'remoteServer' : 'localhost' );
  }

}
