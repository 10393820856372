// Core
import { Component } from '@angular/core';

// Services and directives
import { PlatformService } from '../../platform.service';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html'
})
export class LoaderComponent {

    constructor( private platformService: PlatformService ) {}

    visible(): boolean {
        return this.platformService.loaderVisible;
    }

}
