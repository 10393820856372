// Modules
import { Routes, RouterModule } from '@angular/router';

// Main component
import { TapappsComponent }    from './tapapps.component';

// Feature components

// Services
import { AuthGuardService } from '../../auth/auth-guard.service';

const tapappsRoutes: Routes = [
    { path: 'tapapps', component: TapappsComponent, canActivate: [ AuthGuardService ] },
    { path: 'tapapps', canActivate: [ AuthGuardService ],
        children: [
            // Feature routes
        ]
    },
];

export const tapappsRouting = RouterModule.forChild( tapappsRoutes );
