import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { PlatformService } from '../../platform.service';

import { IUser } from '../../apps/xiroco/user/user.interface';

@Component( {
  selector: 'xiroco-user-account',
  templateUrl: 'user-account.component.html'
} )
export class UserAccountComponent implements OnInit, OnDestroy {

  public user: IUser;
  public currentPassword: string = "";
  public newPassword: string = "";
  public repeatNewPassword: string = "";
  public changePassword: boolean = false;
  public showGPSInfo: boolean = false;
  public showUserAppsSettings: boolean = false;
  public googleMapsApiKey: string = 'AIzaSyB7coz2n47gGxmYXohGGygwOodwihOvBPc';

  constructor(
    public router: Router,
    public authService: AuthService,
    private platformService: PlatformService
  ) {
    this.user = platformService.getUser();
  }

  ngOnInit() {
    // In this page it is not necessary to show user info
    this.platformService.showSignIn = false;

    // Show home button
    this.platformService.showHomeButton = true;

  }

  ngOnDestroy() {
    // Show user account button again
    this.platformService.showSignIn = true;
  }

  onSubmit() {
    this.saveChanges();
  }

  i18n( key: string ): string {
    return this.platformService.i18n( key );
  }

  saveChanges() {
    const self = this;

    self.platformService.loaderVisible = true;
    self.authService.saveUser( self.user ).subscribe(
      data => {
        self.authService.saveUserData( self.user );
        self.platformService.loaderVisible = false;
        self.platformService.addMessage( self.i18n( 'user_settings_saved' ), self.platformService.INFO_MESSAGE );

      },
      errorResponse => {
        this.platformService.loaderVisible = false;
        console.error( 'User saveChanges error', errorResponse );
        self.platformService.addMessage( self.i18n( errorResponse.error.message ) );

      }
    );


  }

  removeAccount( event: any ) {

  }

  cancel( event: any ) {
    event.preventDefault();
    this.router.navigate( [''] );
  }

  get platformSettings() {
    return JSON.stringify( this.platformService.settings, null, 2 );
  }

  set platformSettings( value: string ) {
    try {
      this.platformService.settings = JSON.parse( value );
    } catch ( e ) {
      console.error( 'Error when parse JSON value' );
    }
  }

  get userSettings() {
    return JSON.stringify( this.user, null, 2 );
  }

  set userSettings( value: string ) {
    try {
      this.user = JSON.parse( value );
    } catch ( e ) {
      console.error( 'Error when parse JSON value' );
    }
  }

  get disableGPS(): boolean {
    return this.user.data.disableGPS;
  }

  set disableGPS( value: boolean ) {
    this.platformService.setGPSStatus( value );
    this.user.data.disableGPS = value;
  }


  getGPSPosition(): any {
    return this.platformService.position.coords
      ? this.platformService.position
      : {
        coords: {
          "latitude": "no signal",
          "longitude": "no signal",
          "accuracy": "no signal",
          "altitude": "no signal",
          "altitudeAccuracy": "no signal",
          "heading": "no signal",
          "speed": "no signal"
        },
        "timestamp": "no signal"
      };
  }

  getGoogleMapURL() {
    if ( this.platformService.position.coords ) {
      var latlon = this.platformService.position.coords.latitude + "," + this.platformService.position.coords.longitude;
      var img_url = 'https://maps.googleapis.com/maps/api/staticmap?center='
        + latlon
        + '&zoom=14&size=400x300&sensor=false&key='
        + this.googleMapsApiKey;
      return img_url;
    } else {
      return 'no-map.png';
    }

  }

}
