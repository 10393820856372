// Core
import { NgModule } from '@angular/core';
import { HttpModule, JsonpModule, XHRBackend } from '@angular/http'
import { SharedModule } from '../../shared/shared.module';

// Components
import { ChatAndBotComponent } from './chatandbot.component';

// Feature components
import { GroupListComponent } from './group/list/group-list.component';
import { GroupCardComponent } from './group/card/group-card.component';
import { GroupDetailsComponent } from './group/details/group-details.component';

import { ItemListComponent } from './item/list/item-list.component';
import { ItemCardComponent } from './item/card/item-card.component';
import { ItemDetailsComponent } from './item/details/item-details.component';

import { IssueListComponent } from './issue/list/issue-list.component';
import { IssueCardComponent } from './issue/card/issue-card.component';
import { IssueDetailsComponent } from './issue/details/issue-details.component'

import { ServiceListComponent } from './service/list/service-list.component';
import { ServiceCardComponent } from './service/card/service-card.component';
import { ServiceDetailsComponent } from './service/details/service-details.component';


import { ServiceIssuesListComponent } from './service-issues/list/service-issues-list.component';
import { ServiceIssuesCardComponent } from './service-issues/card/service-issues-card.component';
import { ServiceIssuesDetailsComponent } from './service-issues/details/service-issues-details.component';

import { SynonymListComponent } from './synonym/list/synonym-list.component';
import { SynonymCardComponent } from './synonym/card/synonym-card.component';
import { SynonymDetailsComponent } from './synonym/details/synonym-details.component';

import { TemplateListComponent } from './template/list/template-list.component';
import { TemplateCardComponent } from './template/card/template-card.component';
import { TemplateDetailsComponent } from './template/details/template-details.component';

import { OrderListComponent } from './order/list/order-list.component';
import { OrderCardComponent } from './order/card/order-card.component';
import { OrderDetailsComponent } from './order/details/order-details.component';

import { ChatListComponent } from './chat/list/chat-list.component';
import { ChatCardComponent } from './chat/card/chat-card.component';
import { ChatDetailsComponent } from './chat/details/chat-details.component';

import { ButtonModule } from 'primeng/primeng';
import { AutoCompleteModule } from 'primeng/primeng';

// Routing
import { chatandbotRouting } from './chatandbot.routing';

// Models
import { Group } from './group/group.model';
import { Item } from './item/item.model';

// Main service
import { ChatAndBotService } from './chatandbot.service';

// Feature services
import { ChatService } from './chat/chat.service';
import { GroupService } from './group/group.service';
import { IssueService } from './issue/issue.service';
import { ItemService } from './item/item.service';
import { OrderService } from './order/order.service';
import { ServiceService } from './service/service.service';
import { ServiceIssuesService } from './service-issues/service-issues.service';
import { SynonymService } from './synonym/synonym.service';
import { TemplateService } from './template/template.service';

// PrimeNG
import { DropdownModule } from 'primeng/components/dropdown/dropdown';


@NgModule({
    imports: [
        SharedModule,
        chatandbotRouting,
        ButtonModule,
        AutoCompleteModule,
        DropdownModule
    ],
    declarations: [
        // Feature declarations
        ChatAndBotComponent,
        GroupListComponent, GroupCardComponent, GroupDetailsComponent,
        ItemListComponent, ItemCardComponent, ItemDetailsComponent,
        IssueListComponent, IssueCardComponent, IssueDetailsComponent,
        ServiceListComponent, ServiceCardComponent, ServiceDetailsComponent,
        ServiceIssuesListComponent, ServiceIssuesCardComponent, ServiceIssuesDetailsComponent,
        OrderListComponent, OrderCardComponent, OrderDetailsComponent,
        TemplateListComponent, TemplateCardComponent, TemplateDetailsComponent,
        SynonymListComponent, SynonymCardComponent, SynonymDetailsComponent,
        ChatListComponent, ChatCardComponent, ChatDetailsComponent
    ],
    providers: [
        // Feature providers
        ChatAndBotService,
        ChatService,
        GroupService,
        IssueService,
        ItemService,
        OrderService,
        ServiceService,
        ServiceIssuesService,
        SynonymService,
        TemplateService
    ]
})
export class ChatAndBotModule {}
