import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';

import { AbstractDetailsComponent } from '../../../../abstract/app/feature/details/abstract-details.component';

import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { IssueService } from '../../issue/issue.service';

import { Issue } from '../../issue/issue.model';

import { LogService } from '../../../../shared/log.service';
import { AuthService } from '../../../../auth/auth.service';
import { Helper } from '../../../../shared/helper.service';

@Component( {
  selector: 'chatandbot-issue-details',
  templateUrl: 'issue-details.component.html'
} )
export class IssueDetailsComponent extends AbstractDetailsComponent {

  public doc: Issue;

  constructor( public route: ActivatedRoute,
               public router: Router,
               public platformService: PlatformService,
               public appService: ChatAndBotService,
               public service: IssueService,
               public location: Location,
               public logService: LogService,
               public helper: Helper,
               public authService: AuthService,
               public formBuilder: FormBuilder ) {
    super( route, router, platformService, appService, service, location, logService, helper, authService, formBuilder );
  }

  triggers(): Object {
    let self = this;
    return {
      remove: ( position: number ) => {
        self.doc.data.rivescript.triggers.splice( position, 1 );
      }
    }
  }

  addNewTrigger(): void {
    this.doc.data.rivescript.triggers.push( '' );
  }



  addNewResponse(): void {
    this.doc.data.rivescript.responses.push( '' );
  }
  responses(): Object {
    let self = this;
    return {
      remove: ( position: number ) => {
        self.doc.data.rivescript.responses.splice( position, 1 );
      }
    }
  }

}
