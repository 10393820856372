// Core
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// Services and directives
import { AppService } from '../../../../app/app.service';
import { PlatformService } from '../../../../../../platform.service';

@Component( {
  selector: 'xiroco-app-feature-card',
  templateUrl: 'app-feature-card.component.html'
} )
export class FeatureCardComponent implements OnInit {

  @Input( 'group' ) cardFromGroup: FormGroup;

  public newFieldInputValue: string = '';

  constructor(
    public service: AppService,
    public platformService: PlatformService
  ) { }

  ngOnInit() { }

  getFeaturedFields() {
    if ( this.cardFromGroup.controls['featuredFields'] ) {
      return this.cardFromGroup.controls['featuredFields'].value;
    } else {
      console.error( 'ERROR IN FeatureCardComponent.getFeaturedFields no control featuredFields' );
      return [];
    }
  }

  removeFeaturedField( i: number ): void {
    const self = this;
    self.cardFromGroup.controls['featuredFields'].value.splice( i, 1 );

  }

  // TODO: Implements validator to disable add button if no data in field and avoid duplicates

  addField() {
    this.cardFromGroup.controls['featuredFields'].value.push( this.newFieldInputValue );
    this.newFieldInputValue = '';
  }

  getAvilableFields() {
    var disponibles = ['uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete'];
    var usados = ['seis', 'tres'];

    console.log( disponibles );
    console.log( usados );

    return disponibles.filter( valor => {
      console.log( usados.indexOf( valor ) );
      return usados.indexOf( valor ) === -1;
    } );
  }

}
