import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

// Components
import { PSConnectComponent } from './psconnect.component';

//import { ProductListComponent } from './product/list/product-list.component';
//import { ProductCardComponent } from './product/card/product-card.component';
//import { ProductDetailsComponent } from './product/details/product-details.component';

import { psconnectRouting } from './psconnect.routing';

// Services
import { PSConnectService } from './psconnect.service';


@NgModule({
    imports: [
        SharedModule,
        psconnectRouting
    ],
    declarations: [
        PSConnectComponent,
        //ProductListComponent, ProductCardComponent, ProductDetailsComponent
    ],
    providers: [
        PSConnectService
    ]
})
export class PSConnectModule {}
