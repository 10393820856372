// Core modules
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Xiroco modules
import { AbstractAppComponent } from '../../abstract/app/abstract-app.component';

// Services
import { PlatformService } from '../../platform.service';

// This app services
import { GroupService } from './group/group.service';
import { ItemService } from './item/item.service';
import { IssueService } from './issue/issue.service';
import { ServiceService } from './service/service.service';
import { ServiceIssuesService } from './service-issues/service-issues.service';
import { OrderService } from './order/order.service';
import { TemplateService } from './template/template.service';
import { SynonymService } from './synonym/synonym.service';
import { ChatService } from './chat/chat.service';

// Here we import and provide abstract services that runs as xiroco,
// other apps must have its own service
import { ChatAndBotService } from './chatandbot.service';


@Component({
    selector: 'chatandbot-root',
    templateUrl: 'chatandbot.component.html'
})
export class ChatAndBotComponent extends AbstractAppComponent {

    // Here must be informed the app id
    public appId: string = 'chatandbot';

    constructor(
        public router: Router,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public groupService: GroupService,
        public itemService: ItemService,
        public issueService: IssueService,
        public serviceService: ServiceService,
        public serviceIssuesService: ServiceIssuesService,
        public orderService: OrderService,
        public templateService: TemplateService,
        public synonymService: SynonymService,
        public chatService: ChatService
    ) {
        super( router, platformService, appService );
        
        // Array of features services for looping purposes
        this.featureServices = [
            groupService,
            itemService,
            issueService,
            serviceService,
            serviceIssuesService,
            orderService,
            templateService,
            synonymService,
            chatService
        ];
        
    }

}
