// Core
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { PlatformService } from '../../../../platform.service';
import { XirocoService } from '../../xiroco.service';
import { AppService } from '../app.service';
import { LogService } from '../../../../shared/log.service';

// Components and directives
import { AbstractListComponent } from '../../../../abstract/app/feature/list/abstract-list.component';

@Component({
    selector: 'xiroco-app-list',
    templateUrl: 'app-list.component.html'
})
export class AppListComponent extends AbstractListComponent {
    
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public platformService: PlatformService,
        public appService: XirocoService,
        public service: AppService,
        public logService: LogService
    ) {
        super( route, router, location, platformService, appService, service, logService );
    }
}
