import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';

import { User } from '../user.model';
import { UserService } from '../user.service';

@Component({
    selector: 'xiroco-user-card',
    templateUrl: 'user-card.component.html'
})
export class UserCardComponent extends AbstractCardComponent {

    @Input() feature: string;
    @Input() _id: string;
    public doc: User = new User();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: UserService,
        public location: Location,
        public platformService: PlatformService, public logService: LogService ) {
        super( route, router, service, location, platformService, logService );
        this.setFeature( 'user' );
    }
}
