import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '../../platform.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'signup',
    templateUrl: 'signup.component.html'
})
export class SignupComponent {

    public signupMessage: string = '';
    public name: string = "";
    public email: string = "";
    public password: string = "";
    public repeatPassword: string = "";

    constructor(
        public router: Router,
        public authService: AuthService,
        private platformService: PlatformService 
    ) {
        this.setMessage();
    }

    ngOnInit() {
        // Show home button
        this.platformService.showHomeButton = true;
    }

    setMessage() {
        this.signupMessage = (!this.authService.isSignedIn() ? 'Please sign in...' : 'Your are already signed in!');
    }

    signUp(event: any) {
        const self = this;
        self.platformService.loaderVisible = true;
        event.preventDefault();
        self.authService.signUp(self.name, self.email, self.password).subscribe(
            response => {
                self.platformService.loaderVisible = false;
                self.authService.saveSessionData( response );
                self.router.navigate( [ '/' ] );
            },
            error => {
                self.platformService.loaderVisible = false;
                console.log('signup error', error);
                self.signupMessage = error._body;
            }
        );
    }

    activeServer(): string {
        return this.platformService.activeServer;
    }

    activeServerMessage(): string {
        return this.activeServer() === 'localhost' ? '(local server)' : '';
    }

    toggleServer(): void {
        this.platformService.setActiveServer(this.activeServer() === 'localhost' ? 'remoteServer' : 'localhost');
    }

    cancel(event: any) {
        event.preventDefault();
        this.router.navigate(['']);
    }

    disabledRepeatPassword(): boolean {
        return this.password == '';
    }

    showRepeatPassword(): boolean {
        return true;
    }

    canSignUp(): boolean {
        return this.name
            && this.email
            && this.password
            && this.repeatPassword
            && this.password == this.repeatPassword;
    }

    signedIn(): boolean {
        return this.authService.isSignedIn();
    }
}
