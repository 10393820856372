import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

// Components
import { CardsComponent } from './cards.component';

// Feature components
import { CardsListComponent } from './cards/list/cards-list.component';
import { CardsCardComponent } from './cards/card/cards-card.component';
import { CardsDetailsComponent } from './cards/details/cards-details.component';

import { TriggerListComponent } from './trigger/list/trigger-list.component';
import { TriggerCardComponent } from './trigger/card/trigger-card.component';
import { TriggerDetailsComponent } from './trigger/details/trigger-details.component';

import { TemplateListComponent } from './template/list/template-list.component';
import { TemplateCardComponent } from './template/card/template-card.component';
import { TemplateDetailsComponent } from './template/details/template-details.component';

// Routing
import { cardsRouting } from './cards.routing';

// Services
import { CardsAppService } from './cards-app.service';

// Feature services
import { CardsService } from './cards/cards.service';
import { TriggerService } from './trigger/trigger.service';
import { TemplateService } from './template/template.service';

@NgModule({
    imports: [
        SharedModule,
        cardsRouting
    ],
    declarations: [
        // Feature declarations
		CardsListComponent, CardsCardComponent, CardsDetailsComponent,
		TriggerListComponent, TriggerCardComponent, TriggerDetailsComponent,
		TemplateListComponent, TemplateCardComponent, TemplateDetailsComponent,
        CardsComponent
    ],
    providers: [
        // Feature providers
		CardsAppService,
		TriggerService,
		TemplateService,
        CardsService
    ]
})
export class CardsModule {}
