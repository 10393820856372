import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';

import { Group } from '../group.model';
import { GroupService } from '../group.service';

@Component({
    selector: 'group-card',
    templateUrl: './group-card.component.html'
})
export class GroupCardComponent extends AbstractCardComponent {

    @Input() feature: string;
    @Input() _id: string;
    public doc: Group = new Group();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: GroupService,
        public location: Location,
        public platformService: PlatformService, public logService: LogService ) {
        super( route, router, service, location, platformService, logService );
        this.setFeature( 'group' );
    }
}
