// Core
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { ItemService } from '../item.service';
import { LogService } from '../../../../shared/log.service';

// Components and directives
import { AbstractListComponent } from '../../../../abstract/app/feature/list/abstract-list.component';

@Component({
    selector: 'chatandbot-item-list',
    templateUrl: 'item-list.component.html'
})
export class ItemListComponent extends AbstractListComponent {
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public service: ItemService,
        public logService: LogService
    ) {
        super( route, router, location, platformService, appService, service, logService );
    }

    downloadFromPrestashop() {
        var self = this;
        
        self.appService.getItems()
            .subscribe(
                res  => self.platformService.addMessage( 'Obtaining items has begun, you will be notified by email when the process is complete' ),
                error =>  self.platformService.addMessage( <any>error )
            );
              
    }
}
