/**
 * Model
 */
import { ITrigger,  } from './trigger.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Trigger extends AbstractModel {
    constructor( obj: ITrigger = <ITrigger>{} ) {

        super( obj );

        // Fixed info
        this.type = 'trigger';
        this.app = 'cards';
        this.params.collection = 'trigger';
        this.params.type = 'trigger';

    }
}