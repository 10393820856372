import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Directive, Input, ViewChild } from '@angular/core';

import { AppStorageStatsComponent } from '../../../abstract/app/stats/abstract-app-storage-stats.component';

import { PlatformService } from '../../../platform.service';

import { D3Service } from 'd3-ng2-service';

import { CardsAppService } from '../cards-app.service';

@Component({
  selector: 'app-cards-storage-stats',
  templateUrl: '../../../abstract/app/d3/treemap/treemap.component.html',
  styleUrls: [ '../../../abstract/app/d3/treemap/treemap.component.css' ],
})
export class CardsStorageStatsComponent extends AppStorageStatsComponent implements OnInit, OnDestroy {

  constructor(
    public platformService: PlatformService,
    element: ElementRef,
    public ngZone: NgZone,
    d3Service: D3Service,
    appService: CardsAppService ) {
    super( platformService, element, ngZone, d3Service, appService );
  }

}

