/**
 * Model
 */
import { IUser,  } from './user.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class User extends AbstractModel {
    constructor( obj: IUser = <IUser>{} ) {

        super( obj );

        // Default values
        //if ( typeof( this.data. ) === 'undefined' ) this.data. = ;

        // Fixed info
        this.type = 'user';
        this.app = 'xiroco';
        this.params.collection = 'user';
        this.params.type = 'user';

    }
}