import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';

import { AbstractDetailsComponent } from '../../../../abstract/app/feature/details/abstract-details.component';

import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { SynonymService } from '../synonym.service';

import { Synonym } from '../synonym.model';

import { LogService } from '../../../../shared/log.service';
import { AuthService } from '../../../../auth/auth.service';
import { Helper } from '../../../../shared/helper.service';

@Component({
    selector: 'chatandbot-synonym-details',
    templateUrl: 'synonym-details.component.html'
})
export class SynonymDetailsComponent extends AbstractDetailsComponent {

    public doc: Synonym;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public service: SynonymService,
        public location: Location,
        public logService: LogService,
        public helper: Helper,
        public authService: AuthService,
        public formBuilder: FormBuilder
    ) {
        super( route, router, platformService, appService, service, location, logService, helper, authService, formBuilder );
    }

}
