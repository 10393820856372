// Core modules
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Modules
import { AbstractAppComponent } from '../../abstract/app/abstract-app.component';

// Services
import { PlatformService } from '../../platform.service';

// Here we import and provide abstract services that runs as xiroco,
// other apps must have its own service
import { TapappsService } from './tapapps.service';

@Component({
    selector: 'tapapps-root',
    templateUrl: 'tapapps.component.html'
})
export class TapappsComponent extends AbstractAppComponent {

    constructor(
        public router: Router,
        public platformService: PlatformService,
        public appService: TapappsService
    ) {
        super( router, platformService, appService );
    }
}
