import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LogService } from './shared/log.service';
import { PlatformService } from './platform.service';
import { ViewEncapsulation } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: 'platform.component.html',
    styleUrls: [ 'platform.component.sass' ],
    encapsulation: ViewEncapsulation.None
})
export class PlatformComponent {

  public navbarTitle: string = '';

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private logService: LogService,
    private platformService: PlatformService,
    private cdRef: ChangeDetectorRef
  ) {
    this.logService.log( 'PlatformComponent', 'Constructor ok' );

  }

  ngOnInit() {}


  getGPSDisabled(): boolean {
    return this.platformService.user.data.disableGPS;
  }

  getRouteHistory(): any[] {
    return this.platformService.routeHistory;
  }

  goToRoute( route: string ) {
    this.router.navigate( [ route ] );
  }

}

