// Core modules
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Xiroco modules
import { AbstractAppComponent } from '../../abstract/app/abstract-app.component';

// Services
import { PlatformService } from '../../platform.service';

import { TapappsPOSService } from './tapapps-pos.service';

@Component({
    selector: 'tapappspos-root',
    templateUrl: 'tapapps-pos.component.html'
})
export class TapappsPOSComponent extends AbstractAppComponent {

    constructor(
        public router: Router,
        public platformService: PlatformService,
        public appService: TapappsPOSService
    ) {
        super( router, platformService, appService );
    }

}
