import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { tokenNotExpired } from 'angular2-jwt';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor( private router: Router, private authService: AuthService ) {}

    canActivate() {

        if ( this.authService.isSignedIn() ) {
            return true;
        }

        this.router.navigate( [ '/signin' ] );
        return false;
    }
}