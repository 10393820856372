import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractModel } from '../abstract.model';
import { AbstractCardComponent } from '../card/abstract-card.component';
import { YamlJsonComponent } from '../../../../layout/forms/yaml-json/yaml-json.component';
import { LogService } from '../../../../shared/log.service';

import { PlatformService } from '../../../../platform.service';
import { AbstractAppService } from '../../abstract-app.service';
import { AbstractService } from '../abstract.service';
import { IQueryParams, IQueryOptions } from '../query-params.interface';
import { IListSettings } from './list-settings.interface';

import * as underscore from 'underscore';
import * as _ from 'lodash';
import { stripGeneratedFileSuffix } from '@angular/compiler/src/aot/util';


class ConfigurationParameter {

  private _value: any;

  constructor(
    private service: AbstractService,
    public path: string,
    public defaultValue: any,
    public onlyForUser: boolean = false,
    public isObject: boolean = false
  ) {

    this._value = this.getSettingsOption();
    console.log(
      'Created configuration parameter', this.path, 'with default value', this.defaultValue,
      'got from settings value', this._value
    );
  }

  /**
   * Converts a string to object or not if already it is an object
   * @param val String or Object
   */
  stringOrObjetctToObject( value: string | Object ): Object {
    if ( typeof value === 'undefined' ) { return {}; }
    if ( value instanceof Object ) { return value; }
    try {
      return JSON.parse( value );
    } catch ( e ) {
      console.error( 'Error when parse JSON value' );
    }
  }

  getSettingsOption(): any {
    return this.isObject
      ? JSON.stringify( this.service.getSettingsElement( this.path, this.defaultValue, this.onlyForUser ), null, 2 )
      : this.service.getSettingsElement( this.path, this.defaultValue, this.onlyForUser );
  }

  setSettingsOption( path: string, value: any ): void {
    try {
      this.service.setSettingsElement( path,
        this.isObject ? this.stringOrObjetctToObject( value ) : value );
    } catch ( error ) {
      console.error( 'Error when parse Projection Options JSON value', error );
    }
  }

  get value(): any {
    return this._value;

  }
  set value( value: any ) {
    this._value = value;
    this.save();
  }

  // Adds a new element (if it is an array)
  addElement( element: any ): void {
    this._value.push( element );
    this.save();
  }

  removeElement( key: string, value: string ): void {
    _.remove( this._value, obj => {
      return obj[ key ] === value;
    });
    this.save();
  }

  save(): void {
    this.setSettingsOption( this.path, this._value );
  }
}
@Component( {
  selector: 'xiroco-abstract-list',
  templateUrl: 'abstract-list.component.html'
} )
export class AbstractListComponent implements OnInit, OnDestroy {

  public navbarTitle: string = '';
  public singularName: string = '';
  public pluralName: string = '';

  // In this var store current URL
  public url: string;
  public searchString: string = '';

  public JSONDocsURL: string = '';
  public CSVDocsURL: string = '';

  public title = 'Title';

  // Pagination options
  public paginationVisible = false;
  public paginationPages = [
    {
      "id": "previous",
      "text": "«",
      "href": "/prev",
      "ariaHidden": true,
      "ariaLabel": "Previous"
    },
    {
      "id": "previous",
      "text": "»",
      "href": "/next",
      "ariaHidden": true,
      "ariaLabel": "Next"
    }
  ];

  // Custom filters
  public customFilterName = '';

  public urlName: string = '';
  public _userSettingsUpdated: boolean = false;
  private _settings: IListSettings = {
    "query": {
      "filter": {},
      "projection": {},
      "options": {}
    },
    "highlightNumberOfDocuments": false
  };
  public settingsHighlightCustomFilters;

  private highlightFiltering;
  private highlightSorting;
  private highlightCustomFilters;
  private highlightNumberOfDocuments;

  private filteredBy;
  private filterOptions;

  private sortedBy;
  private sortOptions;

  private showDocs;
  private showDocsOptions;

  private customFilter;
  private customFilters;

  private offset;
  private filter;
  private options;
  private projection;
  private getDocsWhenQueryChanges;
  private getDocsOnInit;
  private useIndexText;

  public texto: string;


  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    public platformService: PlatformService,
    public appService: AbstractAppService,
    public service: AbstractService,
    public logService: LogService
  ) {
    this.logService.log( 'AbstractListComponent', 'Constructor ok' );
    this.highlightFiltering = new ConfigurationParameter( service, 'list.highlight.filtering', true );
    this.highlightSorting = new ConfigurationParameter( service, 'list.highlight.sorting', true );
    this.highlightCustomFilters = new ConfigurationParameter( service, 'list.highlight.customFilters', true );
    this.highlightNumberOfDocuments = new ConfigurationParameter( service, 'list.highlight.numberOfDocuments', true );

    
    this.filterOptions = new ConfigurationParameter( service, 'list.filterOptions', [] );
    this.filteredBy = new ConfigurationParameter( service, 'list.filteredBy', this.filterOptions[ 1 ] );

    this.sortOptions = new ConfigurationParameter( service, 'list.sortOptions', [] );
    this.sortedBy = new ConfigurationParameter( service, 'list.sortedBy', this.sortOptions[ 1 ]  );

    
    this.showDocsOptions = new ConfigurationParameter( service, 'list.showDocsOptions', [] );
    this.showDocs = new ConfigurationParameter( service, 'list.showDocs', this.showDocsOptions[ 1 ] );

    this.customFilter = new ConfigurationParameter( service, 'list.customFilter', {} );
    this.customFilters = new ConfigurationParameter( service, 'list.customFilters', [] );

    this.offset = new ConfigurationParameter( service, 'list.offset', 0 );

    this.filter = new ConfigurationParameter( service, 'list.filter', {} );
    this.options = new ConfigurationParameter( service, 'list.options', {} );
    this.projection = new ConfigurationParameter( service, 'list.projection', {} );

    this.getDocsWhenQueryChanges = new ConfigurationParameter( service, 'list.getDocsWhenQueryChanges', false );
    this.getDocsOnInit = new ConfigurationParameter( service, 'list.getDocsOnInit', false );
    this.useIndexText = new ConfigurationParameter( service, 'list.useIndexText', false );

  }

  ngOnInit() {

    const self = this;

    // Increment the number of times this feature has been used
    self.service.incrementUsage();

    self.logService.log( 'AbstractListComponent', 'ngOnInit' );

    // Translate fixed texts
    self.translateTexts();



    this.logService.log( 'AbstractListComponent', 'ngOnInit for appSettings: '
      + this.service.getApp() + ' and feature: ' + this.service.feature );

    if ( this.getDocsOnInit.value ) {
      self.getDocs();
    }

    // Store current URL for later (onDestroy) save it to history
    this.url = this.router.url;
    this.urlName = this.service.getPluralName();

    this.pluralName = this.service.getPluralName();
    this.singularName = this.service.getSingularName();
    this.navbarTitle = this.pluralName;

  }


  ngOnDestroy() {
    // Save current URL to navigation history
    this.platformService.addRouteToHistory( this.urlName, this.url );
    this.platformService.saveSettingsLocally();
  }

  public i18n( id: string ): string {
    return this.platformService.i18n( id );
  }

  get userSettingsUpdated(): boolean {
    return this._userSettingsUpdated;
  }

  set userSettingsUpdated( value: boolean ) {
    console.log( this.userSettingsUpdated );
    this._userSettingsUpdated = value;
  }

  /**
   * This method translate literals to selected language
   */
  translateTexts(): void {

  }

  /**
   * Get feature id from the service
   */
  getFeature(): string {
    return this.service.feature;
  }

  /**
   * Get all docs from http server
   */
  getDocs() {
    const self = this;
    self.platformService.loaderVisible = true;

    // Each time we get docs save settings locally
    // in order to store query configuration
    self.platformService.saveSettingsLocally();

    self.applySearchFilter();
    
    self.service.getDocs(
      self.getLimit(), self.getOffset(), {
        "filter": this.filter.value,
        "options": this.options.value,
        "projection": this.projection.value
      } ).subscribe(
        () => {
          self.platformService.loaderVisible = false;
        },
        errorResponse => {
          self.platformService.loaderVisible = false;
          self.logService.log( 'AbstractListComponent', 'Error in getDocs', true, errorResponse.error );
          self.platformService.HTTPErrorHandle( errorResponse );
        }
      );
  }

  getLimit(): number {
    return this.showDocs.value.value;

  }

  getOffset(): number {
    return this.offset.value;
  }
  getJSONDataLink(): void {
    const self = this;
    this.JSONDocsURL = this.service.getDocsURL(
      self.getLimit(), self.getOffset(),
      {
        filter: this.filter,
        options: this.options,
        projection: this.projection
      }
    );
  }

  getCSVDataLink(): void {
    const self = this;
    this.CSVDocsURL = this.service.getDocsURL(
      self.getLimit(), self.getOffset(),
      {
        filter: this.filter,
        options: this.options,
        projection: this.projection,
        extraParams: "&csv=true"
      }
    );
  }

  get docs(): AbstractModel[] {
    return this.service.getCache();
  }

  /**
   * This method update Query Options object adding filtering or sorting selected option
   */
  updateQueryOptions(): void {

    // Extend with filter option
    underscore.extend( this.options.value, {
      "__specialFilter": this.filteredBy.value.value
    } );

    // Extend with sort options
    if ( !this.options.value.sort ) {
      this.options.value.sort = this.sortedBy.value.value;
    } else {
      underscore.extend( this.options.value.sort, this.sortedBy.value.value );
    }
  }

  /**
   * Set filtered param and get docs again
   * @param event Event passed
   * @param value Value to be set
   */
  setFilteredBy( event: any, index: number ) {
    if ( event ) { event.preventDefault(); }
    this.filteredBy.value = this.filterOptions.value[index];
    this.updateQueryOptions();
    if ( this.getDocsWhenQueryChanges.value ) { this.getDocs(); }
  }

  /**
   * Set sorter by param and get docs again
   * @param event Event passed
   * @param value Value to be set
   */
  setSortedBy( event: any, index: number ) {
    if ( event ) { event.preventDefault(); }
    this.sortedBy.value = this.sortOptions.value[index];
    this.updateQueryOptions();
    if ( this.getDocsWhenQueryChanges.value ) { this.getDocs(); }
  }

  /**
   * Determines how mamny documents retrieve from the http server
   * @param event Event passed
   * @param value Value to be set
   */
  setShowDocsOption( event: any, index: number ) {
    if ( event ) {
      event.preventDefault();
    }
    this.showDocs.value = this.showDocsOptions.value[index];
    if ( this.getDocsWhenQueryChanges.value ) {
      this.getDocs();
    }
  }

  setFilter( value: any ) {
    if ( event ) { event.preventDefault(); }
    this.filter.value = value;
    this.updateQueryOptions();
    if ( this.getDocsWhenQueryChanges.value ) { this.getDocs(); }
  }

  setOptions( value: any ) {
    if ( event ) { event.preventDefault(); }
    this.options.value = value;
    this.updateQueryOptions();
    if ( this.getDocsWhenQueryChanges.value ) { this.getDocs(); }
  }

  setProjection( value: any ) {
    if ( event ) { event.preventDefault(); }
    this.projection.value = value;
    this.updateQueryOptions();
    if ( this.getDocsWhenQueryChanges.value ) { this.getDocs(); }
  }

  /**
   * Set a pre-saved filter and sorting options
   * @param event Event passed
   * @param value Value to be set
   */
  setCustomFilter( event: any, index: number ) {
    if ( event ) { event.preventDefault(); }
    const customFilterValue = this.customFilters.value[ index ];
    this.customFilter.value = customFilterValue;
    this.customFilterName = customFilterValue.name;
    this.filteredBy.value = customFilterValue.filteredBy;
    this.sortedBy.value = customFilterValue.sortedBy;
    this.options.value = customFilterValue.options;
    this.projection.value = customFilterValue.projection;
    this.filter.value = JSON.parse( customFilterValue.filter );
    this.offset.value = customFilterValue.offset;
    this.showDocs.value = customFilterValue.showDocs;
    if ( this.getDocsWhenQueryChanges.value) { this.getDocs(); }
  }

  /**
   * Save a new custom filter
   * @param event Event passed
   */
  saveCustomFilter( event: any ) {
    const self = this;
    if ( event ) { event.preventDefault(); }

    // Prepare the document to save

    // Here, some nodes must be store as strings due to that $ or dots can not be
    // store en MongoDB
    let filterStringify: string = '';
    try {
      filterStringify = JSON.stringify( self.filter.value );
    } catch ( error ) {
      self.platformService.addMessage( 'error_in_filter_json' );
    }
    const docToSave: any = {
      "name": self.customFilterName,
      "showDocs": self.showDocs.value,
      "filteredBy": self.filteredBy.value,
      "sortedBy": self.sortedBy.value,
      "filter": filterStringify,
      "options": self.options.value,
      "projection": self.projection.value,
      "offset": self.offset.value
    };
  

    // If exists update it
    if ( self.existsCustomFilter() ) {
      let position: number = -1;
      let counter: number = 0;
      self.customFilters.value.forEach( ( filter: any ) => {
        if ( filter.name === self.customFilterName ) {
          position = counter;
        }
        counter++;
      } );

      if ( position !== -1 ) {
        self.customFilters.value[ position ] = docToSave;
      }

      // Force save
      self.customFilters.save();

      self.platformService.addMessage( self.i18n( 'custom_filter_updated' ), self.platformService.INFO_MESSAGE );



    } else {
      // If not exists, add it
      this.customFilters.addElement( docToSave );
      self.platformService.addMessage( self.i18n( 'custom_filter_saved' ), self.platformService.INFO_MESSAGE );

    }
    // And set this as new selected filter
    self.customFilter.value = docToSave;
    // And finally save settings locally
    self.platformService.saveSettingsLocally();


  }


  existsCustomFilter(): boolean {
    const self = this;
    const existFilter = self.customFilters.value.filter( ( filter: any ) => {
      return filter.name === self.customFilterName;
    } );
    return existFilter.length ? true : false;
  }

  /**
   * Remove a custom filter
   */
  removeCustomFilter(event: any ) {
    const self = this;
    if ( event ) { event.preventDefault(); }

    // If exists update it
    if ( self.existsCustomFilter() ) {

      // Delete filter from array

      self.customFilters.removeElement( 'name', self.customFilterName );

      // And finally save settings locally
      self.platformService.saveSettingsLocally();
      self.platformService.addMessage( self.i18n( 'custom_filter_removed' ), self.platformService.INFO_MESSAGE );

    } else {
      self.platformService.addMessage( self.i18n( 'filter_do_not_exists' ) );
    }
    
    
  }
  
  applySearchFilter(): void {
    const self = this;
    // TODO: Hay dos sistemas

    // With text index
    if ( self.useIndexText.value ) {
      if ( self.searchString ) {
        self.filter.value['$text'] = {
          "$search": self.searchString
        };
      } else if ( self.filter.value['$text'] ) {
        delete self.filter.value['$text'];
      }
    } else {
      // Without text index
      if ( self.searchString ) {
        self.filter.value['$or'] = [
          { "data.id": self.searchString },
          { "data.name": self.searchString },
          { "data.description": self.searchString },
          { "data.summary": self.searchString },
          { "data.Actividad": self.searchString },
          { "data.Dimension1": self.searchString },
          { "data.Dimension2": self.searchString }
        ];
      } else if ( self.filter.value['$or'] ) {
        delete self.filter.value['$or'];
      }
    }
  }


  applyTagsFilter(): void {

    if ( this.getTagstagsClicked().length ) {
      this.filter.value[ "data.tags" ] = {
        "$all": this.getTagstagsClicked() 
      };
    } else {
      delete this.filter.value[ "data.tags" ];
    }

  }


  /**
   * Converts a string to object or not if already it is an object
   * @param val String or Object
   */
  _StringOrObjetctToObject( value: string | Object ): Object {
    if ( !value ) { return {}; }
    if ( value instanceof Object ) { return value; }
    try {
      return JSON.parse( value );
    } catch ( e ) {
      console.error( 'Error when parse JSON value' );
    }
  }

  goBack() {
    this.location.back();
  }

  /**
   * This method check if there are documents waiting to be imported in xxxxx2import collection
   */
  theAreDocumentsToImport(): boolean {
    return this.service.theAreDocumentsToImport();
  }

  /**
   * Returns the database collection name
   */
  getDBCollectionName(): string {
    return this.service.getDBCollectionName();
  }

  /**
   * Returns an info text about number of documents waiting to import
   */
  getDocumentsToImportText(): string {
    const docsCount: any = this.service.getStats() ? this.service.getStats().importDocumentsPending : 0;
    return this.theAreDocumentsToImport()
      ? docsCount + ' ' + this.platformService.i18n( 'documents_to_import' )
      : this.platformService.i18n( 'no_docs_to_import' );
  }


  importDocumentsButtonDisabled(): boolean {
    return !this.theAreDocumentsToImport() || this.service.importProcessActive;
  }

  /**
   * This method call the import process in the backoffice
   */
  importDocuments(): void {
    const self = this;
    self.platformService.loaderVisible = true;
    self.service.importDocuments().subscribe(
      ( httpResponse: any ) => {
        self.platformService.loaderVisible = false;
        self.platformService.addMessage( self.platformService.i18n( httpResponse.message || 'undefined_error' ) );

      },
      errorResponse => {
        self.platformService.loaderVisible = false;
        self.logService.log( 'AbstractListComponent', 'Error in import documents', true, errorResponse.error );
        self.platformService.addMessage( self.platformService.i18n( errorResponse.error.message || 'undefined_error' ) );
        // Not authorized, redirect to sign in
        if ( errorResponse.status === 401 ) {
          self.router.navigate(
            [
              'signin'
            ]
          );
        }
      }
    );
  }

  getTagstagsClicked(): string[] {
    return this.service.tagsClicked;
  }

  removeTag( event: any, tagToRemove: string ): void {
    _.remove( this.service.tagsClicked, tag => tag === tagToRemove );
    console.log( this.getTagstagsClicked() );
  }


  clearTagsFilter( event: any ): void {
    this.service.tagsClicked = [];
  }

}
