/**
 * This component is an adaptation of the "Brush & Zoom II" Example provided by
 * Mike Bostock at https://bl.ocks.org/mbostock/f48fcdb929a620ed97877e4678ab15e6
 */

import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Directive, Input, ViewChild } from '@angular/core';

import { D3Component } from '../d3.component';

import { PlatformService } from '../../../../platform.service';

import {
  D3Service,
  D3,
  Axis,
  BrushBehavior,
  BrushSelection,
  D3BrushEvent,
  ScaleLinear,
  ScaleOrdinal,
  Selection,
  Transition
} from 'd3-ng2-service';


// scatter plor

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css'],
})
export class LineChartComponent extends D3Component implements OnInit, OnDestroy {

  public divId = 'line-chart-container';

  public demoData: any[] = [
    {
      name: "USA",
      values: [
        { date: "2000", price: "100" },
        { date: "2001", price: "110" },
        { date: "2002", price: "145" },
        { date: "2003", price: "241" },
        { date: "2004", price: "101" },
        { date: "2005", price: "90" },
        { date: "2006", price: "10" },
        { date: "2007", price: "35" },
        { date: "2008", price: "21" },
        { date: "2009", price: "201" }
      ]
    },
    {
      name: "Canada",
      values: [
        { date: "2000", price: "200" },
        { date: "2001", price: "120" },
        { date: "2002", price: "33" },
        { date: "2003", price: "21" },
        { date: "2004", price: "51" },
        { date: "2005", price: "190" },
        { date: "2006", price: "120" },
        { date: "2007", price: "85" },
        { date: "2008", price: "221" },
        { date: "2009", price: "101" }
      ]
    },
    {
      name: "Maxico",
      values: [
        { date: "2000", price: "50" },
        { date: "2001", price: "10" },
        { date: "2002", price: "5" },
        { date: "2003", price: "71" },
        { date: "2004", price: "20" },
        { date: "2005", price: "9" },
        { date: "2006", price: "220" },
        { date: "2007", price: "235" },
        { date: "2008", price: "61" },
        { date: "2009", price: "10" }
      ]
    }
  ];

  // In this var saved is data is already parsed in order to not parse it multiple times
  private dataParsed = false;

  constructor( public platformService: PlatformService, element: ElementRef, public ngZone: NgZone, d3Service: D3Service ) {
    super( platformService, element, ngZone, d3Service );
  }

  drawGraph(): void {

    if ( !this.hasData() ) {
      console.warn( 'No data' );
      return;
    }

    super.drawGraph();


    const self = this;
    const d3 = this.d3;

    const width = self.getClientWidth() - 20;
    const height = 280;
    const margin = 20;
    const duration = 250;

    const lineOpacity = "0.25";
    const lineOpacityHover = "0.85";
    var otherLinesOpacityHover = "0.1";
    var lineStroke = "1.5px";
    var lineStrokeHover = "2.5px";

    var circleOpacity = '0.85';
    var circleOpacityOnLineHover = "0.25"
    var circleRadius = 3;
    var circleRadiusHover = 6;


    /* Format Data */
    if ( !self.dataParsed ) {
      const parseDate = d3.timeParse( '%Y' );
      self.data.forEach(function (d: any ) {
        d.values.forEach(function (d: any ) {
          d.date = parseDate( d.date );
          d.price = +d.price;
        });
      });
      self.dataParsed = true;
    }

    /* Scale */
    const xScale = d3.scaleTime()
      .domain( <any>d3.extent( self.data[ 0 ].values, d => d[ 'date' ] ) )
      .range([0, width - margin]);

    const yScale = d3.scaleLinear()
      .domain( <any>[ 0, d3.max( self.data[ 0 ].values, d => d[ 'price'] ) ] )
      .range([height - margin, 0]);

      const color = d3.scaleOrdinal(d3.schemeCategory10);

    /* Add SVG */
    d3.select( '#line-chart-container' ).selectAll( 'svg' ).remove();
    const svg = d3.select( '#line-chart-container' ).append( 'svg' );

    svg
      .attr( 'width', ( width + margin ) + 'px' )
      .attr( 'height', ( height + margin ) + 'px' )
      .append( 'g' )
      .attr( 'transform', 'translate(${margin}, ${margin})' );

    /* Add line into SVG */
    var line = d3.line()
      .x( d => xScale( d[ 'date' ] ) )
      .y( d => yScale( d[ 'price' ] ) );

    let lines = svg.append('g')
      .attr('class', 'lines');

    lines.selectAll('.line-group')
      .data( self.data ).enter()
      .append('g')
      .attr('class', 'line-group')
      .on("mouseover", function ( d: any, i: any ) {
        svg.append("text")
          .attr("class", "title-text")
          .style("fill", color(i))
          .text( d.name )
          .attr("text-anchor", "middle")
          .attr("x", (width - margin) / 2)
          .attr("y", 15 );
      })
      .on("mouseout", function (d) {
        svg.select(".title-text").remove();
      })
      .append('path')
      .attr('class', 'line')
      .attr('d', d => line( d[ 'values' ] ) )
      .style('stroke', (d, i) => color( <any>i ) )
      .style('opacity', lineOpacity)
      .on("mouseover", function (d) {
        d3.selectAll('.line')
          .style('opacity', otherLinesOpacityHover);
        d3.selectAll('.circle')
          .style('opacity', circleOpacityOnLineHover);
        d3.select(this)
          .style('opacity', lineOpacityHover)
          .style("stroke-width", lineStrokeHover)
          .style("cursor", "pointer");
      })
      .on("mouseout", function (d) {
        d3.selectAll(".line")
          .style('opacity', lineOpacity);
        d3.selectAll('.circle')
          .style('opacity', circleOpacity);
        d3.select(this)
          .style("stroke-width", lineStroke)
          .style("cursor", "none");
      });


    /* Add circles in the line */
    lines.selectAll("circle-group")
      .data( self.data ).enter()
      .append("g")
      .style("fill", (d, i) => color( <any>i ) )
      .selectAll("circle")
      .data(d => d[ 'values' ] ).enter()
      .append("g")
      .attr("class", "circle")
      .on("mouseover", function( d: any ) {
        d3.select(this)
          .style("cursor", "pointer")
          .append("text")
          .attr("class", "text")
          .text(`${d.price}`)
          .attr("x", d => xScale( d[ 'date' ] ) + 5)
          .attr("y", d => yScale( d[ 'price' ] ) - 10);
      })
      .on("mouseout", function( d: any ) {
        d3.select(this)
          .style("cursor", "none")
          .transition()
          .duration(duration)
          .selectAll(".text").remove();
      })
      .append("circle")
      .attr("cx", d => xScale( d[ 'date' ] ) )
      .attr("cy", d => yScale( d[ 'price' ] ) )
      .attr("r", circleRadius)
      .style('opacity', circleOpacity)
      .on("mouseover", function( d: any ) {
        d3.select(this)
          .transition()
          .duration(duration)
          .attr("r", circleRadiusHover);
      })
      .on("mouseout", function( d: any ) {
        d3.select(this)
          .transition()
          .duration(duration)
          .attr("r", circleRadius);
      });


    /* Add Axis into SVG */
    const xAxis = d3.axisBottom( xScale ).ticks( 5 );
    const yAxis = d3.axisLeft( yScale ).ticks( 5 );

    svg.append( 'g' )
      .attr( 'class', 'x axis' )
      .attr( 'transform' , `translate( 0, ${height - margin})` )
      .call( xAxis );

    svg.append( 'g' )
      .attr( 'class', 'y axis')
      .call( yAxis )
      .append( 'text' )
      .attr( 'y', 20 )
      .attr( 'transform', 'rotate(-90)' )
      .attr( 'fill', '#000' )
      .text( 'Total values' );

  }


}
