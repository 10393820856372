import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PlatformService } from '../../platform.service';
import { AbstractAppService } from '../../abstract/app/abstract-app.service';
import { Observable } from "rxjs";

@Injectable()
export class TapappsPOSService extends AbstractAppService {
    
    constructor(
        public platformService: PlatformService,
        public http: HttpClient
    ) {
        super( platformService, 'tapapps-pos', http );
    }

}