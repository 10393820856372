import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';

import { Chat } from '../chat.model';
import { ChatService } from '../chat.service';

@Component({
    selector: 'chat-card',
    templateUrl: 'chat-card.component.html'
})
export class ChatCardComponent extends AbstractCardComponent {

    @Input() feature: string;
    @Input() _id: string;
    public doc: Chat = new Chat();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: ChatService,
        public location: Location,
        public platformService: PlatformService, public logService: LogService ) {
        super( route, router, service, location, platformService, logService );
        this.setFeature( 'chat' );
    }
}
