import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { PlatformService } from '../../platform.service';
import { iUser } from '../../auth/user.model';

@Component( {
  selector: 'platform-nav',
  templateUrl: 'nav.component.html'
} )


export class NavComponent implements OnInit {

  public navBarTitle: string = '';

  constructor( public authService: AuthService,
               public platformService: PlatformService,
               public router: Router ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  /**
   * Return if user is singed in
   */
  signedIn() {
    return this.authService.isSignedIn();
  }

  /**
   * Return if we want to show Sign In or User Account button in top right nav bar
   * False when we are in user account page
   */
  showSignIn(): boolean {
    return this.platformService.showSignIn;
  }

  /**
   * Show home button in nav bar
   */
  showHomeButton(): boolean {
    return this.platformService.showHomeButton;
  }

  /**
   * Show sign out dialog
   * @param event DOM Event
   */
  signOut( event: any ) {
    this.authService.signOut();
    this.authService.setSignedIn( false );
    this.router.navigate( [ 'signin' ] );

}

  showNav() {
    return this.platformService.showNav;
  }

  

  isDefined(): boolean {
    return true;
  }

  getUserName(): string {
    const user: any = this.platformService.getUser();
    return user && user.data && user.data.name || '';
  }

}
