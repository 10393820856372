import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';

import { Item } from '../item.model';
import { ItemService } from '../item.service';

@Component({
    selector: 'item-card',
    templateUrl: 'item-card.component.html'
})
export class ItemCardComponent extends AbstractCardComponent {

    @Input( 'feature' ) feature: string;
    @Input( '_id' ) _id: string;
    public doc: Item = new Item();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: ItemService,
        public location: Location,
        public platformService: PlatformService, public logService: LogService ) {
        super( route, router, service, location, platformService, logService );
        this.setFeature( 'item' );
    }
}
