/**
 * Abstract model, extended in other models
 */

import { iAbstractModel, iAbstractModelParams } from './abstract.interface';

export class AbstractModel implements iAbstractModel {

    public _id: string;
    public deleted: boolean = false;
    public updatedAt: Date;
    public type: string;
    public app: string;
    public UID: string;
    public parentId: string;
    public cloudUpdated: boolean;
    public data: any;
    public params: iAbstractModelParams;
    public identity: any;
    public history: any[];
    public draft: boolean;
    public geospatial: {
        longitude: number,
        latitude: number
    };

    constructor(
        obj: iAbstractModel = <iAbstractModel>{}
    ) {

        // Default values
        this._id = typeof( obj._id ) === 'undefined' ? '' : obj._id;
        this.deleted = typeof( obj.deleted ) === 'undefined' ? false : obj.deleted;
        this.updatedAt = typeof( obj.updatedAt ) === 'undefined' ? new Date() : obj.updatedAt;
        this.type = typeof( obj.type ) === 'undefined' ? 'no-type' : obj.type;
        this.app = typeof( obj.app ) === 'undefined' ? 'no-app' : obj.app;
        this.UID = typeof( obj.UID ) === 'undefined' ? '' : obj.UID;
        this.parentId = typeof( obj.parentId ) === 'undefined' ? '' : obj.parentId;
        this.cloudUpdated = typeof( obj.cloudUpdated ) === 'undefined' ? false : obj.cloudUpdated;

        this.data = typeof( obj.data ) === 'undefined' ? <any>{} : obj.data;

        obj.params = typeof( obj.params ) === 'undefined' ? <iAbstractModelParams>{} : obj.params;
        this.params = obj.params;

        this.params.collection = typeof( obj.params.collection ) === 'undefined' ? 'no-collection' : obj.params.collection;
        this.params.type = typeof( obj.params.type ) === 'undefined' ? 'no-type' : obj.params.type;
        this.params.level = typeof( obj.params.level ) === 'undefined' ? 0 : obj.params.level;

        this.identity = typeof( obj.identity ) === 'undefined' ? {} : obj.identity;
        this.history = typeof( obj.history ) === 'undefined' ? [] : obj.history;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition( position => {
                console.log( position );
            });
        } else {
            console.log( 'Geolocation is not supported by this browser.' );
        }

    }

    public createdAtSinceAgo(): string {
        if ( this._id ) {
            return this.timeSince( new Date( parseInt( this._id.substring( 0, 8 ), 16 ) * 1000 ) );
        }
        return 'no-created-at';
    }

    public updatedAtSinceAgo(): string {
        if ( this.updatedAt && this.updatedAt instanceof Date ) {
            return this.timeSince( this.updatedAt );
        }
        return 'No last updated date';
    }

    private timeSince( date: Date ): string {
        
        let minutes: number;
        let hours: number;
        let days: number;
        let seconds: number;
        seconds = Math.floor( ( +new Date() - +date ) / 1000 );
        var months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (seconds < 5){
            return "just now";
        }else if (seconds < 60){
            return seconds + " seconds ago";
        }
        else if ( seconds < 3600 ) {
            minutes = Math.floor( seconds / 60 );
            if( minutes > 1 )
                return minutes + " minutes ago";
            else
                return "1 minute ago";
        }
        else if (seconds < 86400) {
            hours = Math.floor( seconds / 3600 );
            if(hours > 1)
                return hours + " hours ago";
            else
                return "1 hour ago";
        }
        // 2 days and no more
        else if (seconds < 172800) {
            days = Math.floor( seconds / 86400 );
            if(days > 1)
                return days + " days ago";
            else
                return "1 day ago";
        } else {

            //return new Date( time ).toLocaleDateString();
            return date.getDate().toString() + " " + months[ date.getMonth() ] + ", " + date.getFullYear();
        }
    }

    public getModelName(): string {
      return this.params.collection;
    }


}
