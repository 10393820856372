/**
 * Model
 */
import { IService,  } from './service.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Service extends AbstractModel {
    constructor( obj: IService = <IService>{} ) {

        super( obj );

        // Default values
        if ( typeof( this.data.id ) === 'undefined' ) this.data.id = 'no-id';
        if ( typeof( this.data.name ) === 'undefined' ) this.data.name = '';
        if ( typeof( this.data.keywords ) === 'undefined' ) this.data.keywords = '';
        if ( typeof( this.data.services ) === 'undefined' ) this.data.services = '';
        if ( typeof( this.data.link_rewrite ) === 'undefined' ) this.data.link_rewrite = '';
        if ( typeof( this.data.price ) === 'undefined' ) this.data.price = 0;
        if ( typeof( this.data.weight ) === 'undefined' ) this.data.weight = 0;
        if ( typeof( this.data.img ) === 'undefined' ) this.data.img = '';


        // Fixed info
        this.type = 'service';
        this.app = 'chatandbot';
        this.params.collection = 'service';
        this.params.type = 'service';

    }
}
