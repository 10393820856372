/**
 * Model
 */
import { IBeacons,  } from './beacons.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Beacons extends AbstractModel {
    constructor( obj: IBeacons = <IBeacons>{} ) {

        super( obj );

        // Default values
        //if ( typeof( this.data. ) === 'undefined' ) this.data. = ;

        // Fixed info
        this.type = 'beacons';
        this.app = 'bitacora';
        this.params.collection = 'beacons';
        this.params.type = 'beacons';

    }
}