import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CacheService } from '../cache.service';

@NgModule({
    imports:      [ CommonModule ],
    declarations: [  ],
    exports:      [  ],
    providers:    [ CacheService ]
})
export class CoreModule {
}