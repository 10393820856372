import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';

import { AbstractDetailsComponent } from '../../../../abstract/app/feature/details/abstract-details.component';

import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { ItemService } from '../item.service';

import { Item } from '../item.model';
import { IGroup } from '../../group/group.interface';

import { LogService } from '../../../../shared/log.service';
import { AuthService } from '../../../../auth/auth.service';
import { Helper } from '../../../../shared/helper.service';

import { ISummaryDoc } from '../../../../abstract/app/feature/abstract.interface';
import { GroupService } from '../../group/group.service';

import { DropdownModule } from 'primeng/components/dropdown/dropdown';

@Component({
    selector: 'chatandbot-item-details',
    templateUrl: 'item-details.component.html'
})
export class ItemDetailsComponent extends AbstractDetailsComponent {

    public doc: Item;

    public groups: any[];
    public issuesAvailable: ISummaryDoc[];

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public service: ItemService,
        public location: Location,
        public logService: LogService,
        public helper: Helper,
        public authService: AuthService,
        public formBuilder: FormBuilder,
        public groupService: GroupService

    ) {
        super( route, router, platformService, appService, service, location, logService, helper, authService, formBuilder );
    }

    /**
     * Override ngOninit to get related docs before
     * render the form get related docs: Groups and Issues
     */
    ngOnInit() {
        let self = this;
        // Get simplified version of groups for select componets
        self.platformService.loaderVisible = true;

        // Get groups for dropdown form control
        self.groups = self.groupService.getCache();
        super.ngOnInit();
/*
        this.groupService.getSummaryDocs().then( ( groups: ISummaryDoc[] ) => {
            self.groups = groups;
            super.ngOnInit();
        }).catch( err => {
            console.error( err );
            self.platformService.loaderVisible = false; 
        });
*/
    }
    
    /**
     * Override initDoc to get group select object based on groupId
     * This method get simplified group object due to groupId value and assign to data
     */
    initDoc(): Promise<any> {
        let self = this;
        return new Promise( ( resolve, reject ) => {
            // Init doc normally
            super.initDoc().then( () => {
                // Then look for group simplified object by groupId value
                self.doc.group = self.groupService.getCache().filter( ( group: IGroup ) => group.groupId == self.doc.groupId );
                resolve();
                
            }).catch( reject );
        })
    }

    /**
     * Returns the selected summary group
     */
    get selectedGroup(): IGroup {
        return this.doc.group;
    }
    
    /**
     * Set a group as selected
     */
    set selectedGroup( value: IGroup ) {
        this.doc.group = value;
        // Replicate group id in groupId doc property
        this.doc.groupId = value.groupId;
    }
    
    /**
     * Internal issues object list
     * Then dumpt to isses property that it is actually used by chat & bot
     */
    issues(): Object {
        let self = this;
        return {
          remove: ( position: number ) => {
            self.doc.issues.splice( position, 1 );
          }
        }
      }
    
    /**
     * Add a new Issue
     */
    addNewIssue(): void {
        this.doc.issues.push( 0 );
    }

    get keywords() {
        return this.doc.keywords ? this.doc.keywords.join() : '';
    }

    set keywords( value: string ) {
        try {
            this.doc.keywords = value.split( ',' ).map( value => { return value.trim(); } );
        } catch( e ) {
            console.error( 'Error when parse JSON value' );
        }
    }

    get imageURL(): string {
        return 'http://' + this.doc.img;
    }

}
