// Core
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

// Services and directives
import { AppService } from '../../../../../app/app.service';
import { PlatformService } from '../../../../../../../platform.service'

@Component({
    selector: 'xiroco-app-feature-form-group',
    templateUrl: 'app-feature-form-group.component.html'
})
export class AppFeatureFormGroupComponent implements OnInit {

    @Input( 'group' ) formGroup: FormGroup;

    public panelCollapsed: boolean;

    constructor(
        public service: AppService,
        public platformService: PlatformService
    ) {
        this.panelCollapsed = true;

    }

    ngOnInit() {}

    get rows(): FormArray {
        return this.formGroup.get( 'rows' ) as FormArray;
    }
    removeRow( i: number ): void {
        var self = this;
       
                self.rows.removeAt(i);
            
    }

}
