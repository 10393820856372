/**
 * Model
 */
import { IChat,  } from './chat.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Chat extends AbstractModel {
    constructor( obj: IChat = <IChat>{} ) {

        super( obj );

        // Default values
        if ( typeof( this.data.userName ) === 'undefined' ) this.data.userName = 'no-user-name';
        if ( typeof( this.data.roomId ) === 'undefined' ) this.data.roomId = '';
        if ( typeof( this.data.conversation ) === 'undefined' ) this.data.conversation = [];

        // Fixed info
        this.type = 'chat';
        this.app = 'chatandbot';
        this.params.collection = 'chat';
        this.params.type = 'chat';

    }
}
