import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PlatformService } from '../../platform.service';
import { AbstractAppService } from '../../abstract/app/abstract-app.service';
import { Observable } from "rxjs";
@Injectable()
export class PSConnectService extends AbstractAppService {
    
    constructor(
        public platformService: PlatformService,
        public http: HttpClient
    ) {
        super( platformService, 'psconnect', http );
    }

    retrieveProducts(): Observable<any> {
        var self = this;
        let headers = self.getHeaders();
        let url = self.getURLWithToken( 'retrieve/products' );
        return self.http.post( url, { body: '', headers: headers } )
            .map( self.handleResponse )
            .catch( self.handleError );
    }


}