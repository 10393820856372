// Core modules
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Xiroco modules
import { AbstractAppComponent } from '../../abstract/app/abstract-app.component';

// Services
import { PlatformService } from '../../platform.service';
import { AppService } from '../xiroco/app/app.service';

// Here we import and provide abstract services that runs as xiroco,
// other apps must have its own service
import { XirocoService } from './xiroco.service';

@Component({
    selector: 'xiroco-root',
    templateUrl: 'xiroco.component.html'
})
export class XirocoComponent extends AbstractAppComponent {

    constructor(
        public router: Router,
        public platformService: PlatformService,
        public appService: XirocoService,
        public appFeatureService: AppService
    ) {
        super( router, platformService, appService );
        
    }
    
}
