import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PlatformService } from '../../../platform.service';
import { AbstractAppService } from '../../../abstract/app/abstract-app.service';

@Component({
  selector: 'app-gadget',
  templateUrl: 'app-gadget.component.html'
})
export class AppGadgetComponent implements OnInit {

  public mainFeatures: any[] = [];
  public secondaryFeatures: any[] = [];

  @Input( 'appService' ) appService: AbstractAppService;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    private platformService: PlatformService
  ) { }

  ngOnInit() { 
    if ( this.appService ) {
      console.log( this.appService.getApp() );
      this.mainFeatures = this.appService.getAppMainFeatures();
      this.secondaryFeatures = this.appService.getAppSecondaryFeatures();
    
    } else { 
      console.log( 'No appService in app-gadget.component' );
    }
  }

  public i18n( value: string ): string {
    return this.platformService.i18n( value );
  }

  getName(): string {
    return this.appService ? this.appService.getName() : 'no-name';
  }

  getCssStyles(): string {
    if ( !this.appService.getSettings() ) {
      console.warn( 'No app settings found in app gatdget component getCssStyles' );
      return '';
    } else {
      return this.appService ? this.appService.getSettings().css || '' : '';
    }
  }

  getPath(): string {
    return this.appService.getPath();
  }

  getImage(): string {
    return this.appService && this.appService.getSettings() ? this.appService.getSettings().image || '' : '';
  }

  showAppName(): boolean {
    if ( !this.appService.getSettings() ) {
      console.warn( 'No app settings found in app gatdget component showAppName' );
      return true;
    } else {
      return this.appService && this.appService.getSettings().hideAppNameInAppGadget 
        ? !this.appService.getSettings().hideAppNameInAppGadget
        : true;
    }
  }

  getSubtitle(): string {
    return this.appService && this.appService.getSettings() && this.appService.getSettings().subtitle 
      ? this.platformService.i18n(  this.appService.getSettings().subtitle )
      : '';
  }

  getDescription(): string {
    return this.appService && this.appService.getSettings() && this.appService.getSettings().description
      ? this.platformService.i18n( this.appService.getSettings().description ) 
      : '';
  }

  hideFeaturesInHome(): boolean {
    return false;
  }



  goToRoute( paths: string[] ): void {
    this.platformService.goToRoute( this.router, 
      ( paths 
        ? [ this.getPath() ].concat( paths ) 
        : [ this.getPath() ]
      )
     );
  }
}
