// Core
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

// Services and directives
import { AppService } from '../../../app/app.service';



@Component({
    selector: 'xiroco-app-feature',
    templateUrl: 'app-feature.component.html'
})
export class FeatureComponent implements OnInit {

    @Input( 'group' ) featureForm: FormGroup;

    constructor(
        public service: AppService
        
    ) {

    }

    ngOnInit() {}


    getFormGroup(): FormGroup {
        return this.featureForm.controls[ 'form' ] as FormGroup;
    }
}
