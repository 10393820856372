// Core
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { SynonymService } from '../synonym.service';
import { LogService } from '../../../../shared/log.service';

// Components and directives
import { AbstractListComponent } from '../../../../abstract/app/feature/list/abstract-list.component';

@Component({
    selector: 'chatandbot-synonym-list',
    templateUrl: 'synonym-list.component.html'
})
export class SynonymListComponent extends AbstractListComponent {

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public service: SynonymService,
        public logService: LogService
    ) {
        super( route, router, location, platformService, appService, service, logService );
    }
    
}
