/**
 * Model
 */
import { ISynonym,  } from './synonym.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Synonym extends AbstractModel {
    constructor( obj: ISynonym = <ISynonym>{} ) {

        super( obj );

        // Default values
        //if ( typeof( this.data. ) === 'undefined' ) this.data. = ;

        // Fixed info
        this.type = 'synonym';
        this.app = 'chatandbot';
        this.params.collection = 'synonym';
        this.params.type = 'synonym';

    }
}