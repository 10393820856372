/**
 * Service
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { map } from 'rxjs/operators';

import { AbstractService } from '../../../abstract/app/feature/abstract.service';

import { PlatformService } from '../../../platform.service';
import { ChatAndBotService } from '../chatandbot.service';
import { ServiceIssues } from './service-issues.model';

import { LogService } from '../../../shared/log.service';



@Injectable()
export class ServiceIssuesService extends AbstractService {

  // Get cache from local storage (if exists)
  public localStorage = true;

  constructor( public http: HttpClient,
               public platformService: PlatformService,
               public appService: ChatAndBotService,
               public logService: LogService

  ) {
    super( 'serviceIssues', http, platformService, appService, logService );
  }

  /**
   * This method returns an empty doc that it is necessary
   * to set up details component form
   * Here you can implement (i.e.) new id counter
   * @returns {ServiceIssues}
   */
  public getNewDoc(): any {
    return new ServiceIssues();
  }

  /**
   * Overrided due to camel case feature name, in URL path musn't be that way
   * @returns {string}
   */
  public getServerURL() {
    return 'chat-and-bot/service-issues';
  }

  loadBaseData() {
    var self = this;
    var data = [
      { "brandId": 74, "deviceId": 406, "serviceId": 1103, "issueId": 1 }, {
        "brandId": 74,
        "deviceId": 172,
        "serviceId": 1104,
        "issueId": 1
      }, { "brandId": 74, "deviceId": 172, "serviceId": 1105, "issueId": 1 }, {
        "brandId": 74,
        "deviceId": 171,
        "serviceId": 696,
        "issueId": 1
      }, { "brandId": 74, "deviceId": 171, "serviceId": 697, "issueId": 1 }, {
        "brandId": 74,
        "deviceId": 171,
        "serviceId": 700,
        "issueId": 2
      }, { "brandId": 74, "deviceId": 173, "serviceId": 966, "issueId": 1 }, {
        "brandId": 74,
        "deviceId": 173,
        "serviceId": 967,
        "issueId": 1
      }, { "brandId": 74, "deviceId": 170, "serviceId": 912, "issueId": 1 }, {
        "brandId": 74,
        "deviceId": 169,
        "serviceId": 1106,
        "issueId": 1
      }, { "brandId": 74, "deviceId": 174, "serviceId": 1107, "issueId": 1 }, {
        "brandId": 74,
        "deviceId": 174,
        "serviceId": 1108,
        "issueId": 1
      }, { "brandId": 74, "deviceId": 454, "serviceId": 1247, "issueId": 2 }, {
        "brandId": 327,
        "deviceId": 329,
        "serviceId": 1114,
        "issueId": 1
      }, { "brandId": 327, "deviceId": 418, "serviceId": 1129, "issueId": 1 }, {
        "brandId": 419,
        "deviceId": 421,
        "serviceId": 1131,
        "issueId": 1
      }, { "brandId": 419, "deviceId": 421, "serviceId": 1205, "issueId": 1 }, {
        "brandId": 85,
        "deviceId": 381,
        "serviceId": 1245,
        "issueId": 1
      }, { "brandId": 85, "deviceId": 381, "serviceId": 1246, "issueId": 2 }, {
        "brandId": 85,
        "deviceId": 381,
        "serviceId": 1675,
        "issueId": 1
      }, { "brandId": 85, "deviceId": 217, "serviceId": 1705, "issueId": 1 }, {
        "brandId": 85,
        "deviceId": 218,
        "serviceId": 862,
        "issueId": 1
      }, { "brandId": 85, "deviceId": 218, "serviceId": 1192, "issueId": 2 }, {
        "brandId": 85,
        "deviceId": 218,
        "serviceId": 1415,
        "issueId": 3
      }, { "brandId": 85, "deviceId": 219, "serviceId": 980, "issueId": 1 }, {
        "brandId": 85,
        "deviceId": 219,
        "serviceId": 1413,
        "issueId": 14
      }, { "brandId": 85, "deviceId": 219, "serviceId": 1414, "issueId": 3 }, {
        "brandId": 85,
        "deviceId": 219,
        "serviceId": 1416,
        "issueId": 2
      }, { "brandId": 85, "deviceId": 219, "serviceId": 1417, "issueId": 7 }, {
        "brandId": 85,
        "deviceId": 221,
        "serviceId": 1412,
        "issueId": 1
      }, { "brandId": 81, "deviceId": 373, "serviceId": 1043, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 373,
        "serviceId": 1645,
        "issueId": 3
      }, { "brandId": 81, "deviceId": 373, "serviceId": 1646, "issueId": 4 }, {
        "brandId": 81,
        "deviceId": 373,
        "serviceId": 1647,
        "issueId": 14
      }, { "brandId": 81, "deviceId": 373, "serviceId": 1648, "issueId": 6 }, {
        "brandId": 81,
        "deviceId": 373,
        "serviceId": 1649,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 373, "serviceId": 1650, "issueId": 2 }, {
        "brandId": 81,
        "deviceId": 371,
        "serviceId": 1035,
        "issueId": 1
      }, { "brandId": 81, "deviceId": 371, "serviceId": 1036, "issueId": 14 }, {
        "brandId": 81,
        "deviceId": 308,
        "serviceId": 778,
        "issueId": 14
      }, { "brandId": 81, "deviceId": 308, "serviceId": 854, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 308,
        "serviceId": 1651,
        "issueId": 3
      }, { "brandId": 81, "deviceId": 308, "serviceId": 1652, "issueId": 4 }, {
        "brandId": 81,
        "deviceId": 308,
        "serviceId": 1653,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 308, "serviceId": 1654, "issueId": 8 }, {
        "brandId": 81,
        "deviceId": 308,
        "serviceId": 1655,
        "issueId": 15
      }, { "brandId": 81, "deviceId": 310, "serviceId": 814, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 310,
        "serviceId": 1656,
        "issueId": 14
      }, { "brandId": 81, "deviceId": 310, "serviceId": 1657, "issueId": 8 }, {
        "brandId": 81,
        "deviceId": 310,
        "serviceId": 1658,
        "issueId": 7
      }, { "brandId": 81, "deviceId": 310, "serviceId": 1659, "issueId": 15 }, {
        "brandId": 81,
        "deviceId": 310,
        "serviceId": 1661,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 376, "serviceId": 1046, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 376,
        "serviceId": 1569,
        "issueId": 18
      }, { "brandId": 81, "deviceId": 376, "serviceId": 1571, "issueId": 5 }, {
        "brandId": 81,
        "deviceId": 376,
        "serviceId": 1572,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 376, "serviceId": 1660, "issueId": 3 }, {
        "brandId": 81,
        "deviceId": 376,
        "serviceId": 1667,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 376, "serviceId": 1680, "issueId": 4 }, {
        "brandId": 81,
        "deviceId": 382,
        "serviceId": 1544,
        "issueId": 1
      }, { "brandId": 81, "deviceId": 382, "serviceId": 1668, "issueId": 3 }, {
        "brandId": 81,
        "deviceId": 382,
        "serviceId": 1669,
        "issueId": 14
      }, { "brandId": 81, "deviceId": 382, "serviceId": 1670, "issueId": 11 }, {
        "brandId": 81,
        "deviceId": 382,
        "serviceId": 1671,
        "issueId": 4
      }, { "brandId": 81, "deviceId": 382, "serviceId": 1676, "issueId": 15 }, {
        "brandId": 81,
        "deviceId": 382,
        "serviceId": 1677,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 382, "serviceId": 1678, "issueId": 6 }, {
        "brandId": 81,
        "deviceId": 383,
        "serviceId": 1545,
        "issueId": 1
      }, { "brandId": 81, "deviceId": 383, "serviceId": 1662, "issueId": 6 }, {
        "brandId": 81,
        "deviceId": 383,
        "serviceId": 1663,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 383, "serviceId": 1664, "issueId": 15 }, {
        "brandId": 81,
        "deviceId": 383,
        "serviceId": 1679,
        "issueId": 3
      }, { "brandId": 81, "deviceId": 384, "serviceId": 1701, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 202,
        "serviceId": 462,
        "issueId": 5
      }, { "brandId": 81, "deviceId": 202, "serviceId": 466, "issueId": 10 }, {
        "brandId": 81,
        "deviceId": 202,
        "serviceId": 467,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 202, "serviceId": 476, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 205,
        "serviceId": 453,
        "issueId": 15
      }, { "brandId": 81, "deviceId": 205, "serviceId": 453, "issueId": 10 }, {
        "brandId": 81,
        "deviceId": 205,
        "serviceId": 456,
        "issueId": 5
      }, { "brandId": 81, "deviceId": 205, "serviceId": 463, "issueId": 6 }, {
        "brandId": 81,
        "deviceId": 205,
        "serviceId": 465,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 205, "serviceId": 474, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 205,
        "serviceId": 618,
        "issueId": 21
      }, { "brandId": 81, "deviceId": 205, "serviceId": 1402, "issueId": 2 }, {
        "brandId": 81,
        "deviceId": 201,
        "serviceId": 451,
        "issueId": 15
      }, { "brandId": 81, "deviceId": 201, "serviceId": 451, "issueId": 10 }, {
        "brandId": 81,
        "deviceId": 201,
        "serviceId": 477,
        "issueId": 1
      }, { "brandId": 81, "deviceId": 201, "serviceId": 478, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 201,
        "serviceId": 495,
        "issueId": 4
      }, { "brandId": 81, "deviceId": 201, "serviceId": 504, "issueId": 7 }, {
        "brandId": 81,
        "deviceId": 201,
        "serviceId": 506,
        "issueId": 4
      }, { "brandId": 81, "deviceId": 203, "serviceId": 446, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 203,
        "serviceId": 450,
        "issueId": 15
      }, { "brandId": 81, "deviceId": 203, "serviceId": 450, "issueId": 10 }, {
        "brandId": 81,
        "deviceId": 204,
        "serviceId": 926,
        "issueId": 1
      }, { "brandId": 81, "deviceId": 200, "serviceId": 444, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 200,
        "serviceId": 496,
        "issueId": 2
      }, { "brandId": 81, "deviceId": 547, "serviceId": 1546, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 249,
        "serviceId": 499,
        "issueId": 1
      }, { "brandId": 81, "deviceId": 249, "serviceId": 502, "issueId": 18 }, {
        "brandId": 81,
        "deviceId": 250,
        "serviceId": 497,
        "issueId": 2
      }, { "brandId": 81, "deviceId": 251, "serviceId": 498, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 251,
        "serviceId": 505,
        "issueId": 6
      }, { "brandId": 81, "deviceId": 252, "serviceId": 500, "issueId": 1 }, {
        "brandId": 81,
        "deviceId": 253,
        "serviceId": 503,
        "issueId": 1
      }, { "brandId": 369, "deviceId": 417, "serviceId": 1126, "issueId": 1 }, {
        "brandId": 369,
        "deviceId": 392,
        "serviceId": 1084,
        "issueId": 1
      }, { "brandId": 369, "deviceId": 392, "serviceId": 1085, "issueId": 1 }, {
        "brandId": 369,
        "deviceId": 414,
        "serviceId": 1124,
        "issueId": 1
      }, { "brandId": 369, "deviceId": 370, "serviceId": 1034, "issueId": 1 }, {
        "brandId": 369,
        "deviceId": 370,
        "serviceId": 1071,
        "issueId": 6
      }, { "brandId": 369, "deviceId": 388, "serviceId": 1072, "issueId": 3 }, {
        "brandId": 369,
        "deviceId": 388,
        "serviceId": 1073,
        "issueId": 4
      }, { "brandId": 369, "deviceId": 388, "serviceId": 1074, "issueId": 1 }, {
        "brandId": 369,
        "deviceId": 388,
        "serviceId": 1075,
        "issueId": 1
      }, { "brandId": 369, "deviceId": 390, "serviceId": 1079, "issueId": 1 }, {
        "brandId": 369,
        "deviceId": 390,
        "serviceId": 1080,
        "issueId": 1
      }, { "brandId": 369, "deviceId": 391, "serviceId": 1081, "issueId": 1 }, {
        "brandId": 369,
        "deviceId": 391,
        "serviceId": 1083,
        "issueId": 1
      }, { "brandId": 369, "deviceId": 393, "serviceId": 1089, "issueId": 1 }, {
        "brandId": 369,
        "deviceId": 415,
        "serviceId": 1125,
        "issueId": 1
      }, { "brandId": 420, "deviceId": 422, "serviceId": 1130, "issueId": 1 }, {
        "brandId": 420,
        "deviceId": 422,
        "serviceId": 1503,
        "issueId": 2
      }, { "brandId": 504, "deviceId": 506, "serviceId": 1403, "issueId": 1 }, {
        "brandId": 504,
        "deviceId": 506,
        "serviceId": 1404,
        "issueId": 2
      }, { "brandId": 504, "deviceId": 509, "serviceId": 1410, "issueId": 1 }, {
        "brandId": 504,
        "deviceId": 509,
        "serviceId": 1487,
        "issueId": 2
      }, { "brandId": 504, "deviceId": 510, "serviceId": 1411, "issueId": 1 }, {
        "brandId": 504,
        "deviceId": 510,
        "serviceId": 1488,
        "issueId": 2
      }, { "brandId": 504, "deviceId": 507, "serviceId": 1405, "issueId": 1 }, {
        "brandId": 504,
        "deviceId": 507,
        "serviceId": 1406,
        "issueId": 10
      }, { "brandId": 504, "deviceId": 507, "serviceId": 1407, "issueId": 15 }, {
        "brandId": 504,
        "deviceId": 507,
        "serviceId": 1489,
        "issueId": 2
      }, { "brandId": 504, "deviceId": 505, "serviceId": 1399, "issueId": 1 }, {
        "brandId": 504,
        "deviceId": 505,
        "serviceId": 1400,
        "issueId": 8
      }, { "brandId": 504, "deviceId": 505, "serviceId": 1401, "issueId": 2 }, {
        "brandId": 504,
        "deviceId": 508,
        "serviceId": 1408,
        "issueId": 1
      }, { "brandId": 504, "deviceId": 508, "serviceId": 1409, "issueId": 2 }, {
        "brandId": 75,
        "deviceId": 564,
        "serviceId": 1727,
        "issueId": 2
      }, { "brandId": 75, "deviceId": 378, "serviceId": 1248, "issueId": 2 }, {
        "brandId": 75,
        "deviceId": 378,
        "serviceId": 1249,
        "issueId": 3
      }, { "brandId": 75, "deviceId": 378, "serviceId": 1250, "issueId": 10 }, {
        "brandId": 75,
        "deviceId": 378,
        "serviceId": 1251,
        "issueId": 4
      }, { "brandId": 75, "deviceId": 378, "serviceId": 1665, "issueId": 14 }, {
        "brandId": 75,
        "deviceId": 378,
        "serviceId": 1666,
        "issueId": 6
      }, { "brandId": 75, "deviceId": 378, "serviceId": 1672, "issueId": 6 }, {
        "brandId": 75,
        "deviceId": 378,
        "serviceId": 1673,
        "issueId": 8
      }, { "brandId": 75, "deviceId": 378, "serviceId": 1674, "issueId": 3 }, {
        "brandId": 75,
        "deviceId": 175,
        "serviceId": 780,
        "issueId": 4
      }, { "brandId": 75, "deviceId": 175, "serviceId": 781, "issueId": 14 }, {
        "brandId": 75,
        "deviceId": 175,
        "serviceId": 782,
        "issueId": 16
      }, { "brandId": 75, "deviceId": 175, "serviceId": 783, "issueId": 6 }, {
        "brandId": 75,
        "deviceId": 175,
        "serviceId": 784,
        "issueId": 6
      }, { "brandId": 75, "deviceId": 175, "serviceId": 785, "issueId": 21 }, {
        "brandId": 75,
        "deviceId": 175,
        "serviceId": 787,
        "issueId": 2
      }, { "brandId": 75, "deviceId": 175, "serviceId": 789, "issueId": 12 }, {
        "brandId": 75,
        "deviceId": 175,
        "serviceId": 790,
        "issueId": 1
      }, { "brandId": 75, "deviceId": 175, "serviceId": 791, "issueId": 15 }, {
        "brandId": 75,
        "deviceId": 176,
        "serviceId": 968,
        "issueId": 3
      }, { "brandId": 75, "deviceId": 176, "serviceId": 969, "issueId": 14 }, {
        "brandId": 75,
        "deviceId": 176,
        "serviceId": 970,
        "issueId": 6
      }, { "brandId": 75, "deviceId": 176, "serviceId": 971, "issueId": 6 }, {
        "brandId": 75,
        "deviceId": 176,
        "serviceId": 972,
        "issueId": 2
      }, { "brandId": 75, "deviceId": 176, "serviceId": 974, "issueId": 12 }, {
        "brandId": 75,
        "deviceId": 176,
        "serviceId": 975,
        "issueId": 1
      }, { "brandId": 75, "deviceId": 176, "serviceId": 976, "issueId": 1 }, {
        "brandId": 75,
        "deviceId": 179,
        "serviceId": 1506,
        "issueId": 1
      }, { "brandId": 75, "deviceId": 179, "serviceId": 1608, "issueId": 3 }, {
        "brandId": 75,
        "deviceId": 179,
        "serviceId": 1607,
        "issueId": 4
      }, { "brandId": 75, "deviceId": 179, "serviceId": 1606, "issueId": 18 }, {
        "brandId": 86,
        "deviceId": 512,
        "serviceId": 1420,
        "issueId": 8
      }, { "brandId": 86, "deviceId": 512, "serviceId": 1421, "issueId": 7 }, {
        "brandId": 86,
        "deviceId": 512,
        "serviceId": 1424,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 512, "serviceId": 1425, "issueId": 15 }, {
        "brandId": 86,
        "deviceId": 512,
        "serviceId": 1426,
        "issueId": 12
      }, { "brandId": 86, "deviceId": 512, "serviceId": 1427, "issueId": 5 }, {
        "brandId": 86,
        "deviceId": 512,
        "serviceId": 1468,
        "issueId": 14
      }, { "brandId": 86, "deviceId": 343, "serviceId": 927, "issueId": 1 }, {
        "brandId": 86,
        "deviceId": 343,
        "serviceId": 1142,
        "issueId": 7
      }, { "brandId": 86, "deviceId": 343, "serviceId": 1142, "issueId": 8 }, {
        "brandId": 86,
        "deviceId": 343,
        "serviceId": 1143,
        "issueId": 12
      }, { "brandId": 86, "deviceId": 343, "serviceId": 1143, "issueId": 15 }, {
        "brandId": 86,
        "deviceId": 343,
        "serviceId": 1144,
        "issueId": 14
      }, { "brandId": 86, "deviceId": 343, "serviceId": 1145, "issueId": 18 }, {
        "brandId": 86,
        "deviceId": 343,
        "serviceId": 1146,
        "issueId": 4
      }, { "brandId": 86, "deviceId": 343, "serviceId": 1423, "issueId": 6 }, {
        "brandId": 86,
        "deviceId": 222,
        "serviceId": 694,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 222, "serviceId": 695, "issueId": 12 }, {
        "brandId": 86,
        "deviceId": 222,
        "serviceId": 1150,
        "issueId": 14
      }, { "brandId": 86, "deviceId": 222, "serviceId": 1151, "issueId": 2 }, {
        "brandId": 86,
        "deviceId": 222,
        "serviceId": 1605,
        "issueId": 6
      }, { "brandId": 86, "deviceId": 222, "serviceId": 1616, "issueId": 6 }, {
        "brandId": 86,
        "deviceId": 222,
        "serviceId": 1619,
        "issueId": 18
      }, { "brandId": 86, "deviceId": 514, "serviceId": 1428, "issueId": 8 }, {
        "brandId": 86,
        "deviceId": 514,
        "serviceId": 1429,
        "issueId": 7
      }, { "brandId": 86, "deviceId": 514, "serviceId": 1624, "issueId": 14 }, {
        "brandId": 86,
        "deviceId": 514,
        "serviceId": 1625,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 344, "serviceId": 928, "issueId": 1 }, {
        "brandId": 86,
        "deviceId": 344,
        "serviceId": 1147,
        "issueId": 12
      }, { "brandId": 86, "deviceId": 344, "serviceId": 1148, "issueId": 15 }, {
        "brandId": 86,
        "deviceId": 344,
        "serviceId": 1149,
        "issueId": 7
      }, { "brandId": 86, "deviceId": 344, "serviceId": 1617, "issueId": 3 }, {
        "brandId": 86,
        "deviceId": 344,
        "serviceId": 1618,
        "issueId": 4
      }, { "brandId": 86, "deviceId": 344, "serviceId": 1621, "issueId": 8 }, {
        "brandId": 86,
        "deviceId": 344,
        "serviceId": 1622,
        "issueId": 7
      }, { "brandId": 86, "deviceId": 344, "serviceId": 1627, "issueId": 14 }, {
        "brandId": 86,
        "deviceId": 513,
        "serviceId": 1422,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 345, "serviceId": 929, "issueId": 1 }, {
        "brandId": 86,
        "deviceId": 345,
        "serviceId": 930,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 345, "serviceId": 1626, "issueId": 18 }, {
        "brandId": 86,
        "deviceId": 346,
        "serviceId": 931,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 346, "serviceId": 1609, "issueId": 4 }, {
        "brandId": 86,
        "deviceId": 346,
        "serviceId": 1610,
        "issueId": 3
      }, { "brandId": 86, "deviceId": 346, "serviceId": 1611, "issueId": 6 }, {
        "brandId": 86,
        "deviceId": 346,
        "serviceId": 1612,
        "issueId": 6
      }, { "brandId": 86, "deviceId": 346, "serviceId": 1620, "issueId": 18 }, {
        "brandId": 86,
        "deviceId": 223,
        "serviceId": 1152,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 223, "serviceId": 1153, "issueId": 1 }, {
        "brandId": 86,
        "deviceId": 223,
        "serviceId": 1154,
        "issueId": 6
      }, { "brandId": 86, "deviceId": 223, "serviceId": 1155, "issueId": 6 }, {
        "brandId": 86,
        "deviceId": 223,
        "serviceId": 1161,
        "issueId": 7
      }, { "brandId": 86, "deviceId": 223, "serviceId": 1162, "issueId": 8 }, {
        "brandId": 86,
        "deviceId": 223,
        "serviceId": 1163,
        "issueId": 18
      }, { "brandId": 86, "deviceId": 223, "serviceId": 1164, "issueId": 10 }, {
        "brandId": 86,
        "deviceId": 511,
        "serviceId": 1418,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 511, "serviceId": 1419, "issueId": 1 }, {
        "brandId": 86,
        "deviceId": 549,
        "serviceId": 1559,
        "issueId": 1
      }, { "brandId": 86, "deviceId": 549, "serviceId": 1602, "issueId": 14 }, {
        "brandId": 425,
        "deviceId": 426,
        "serviceId": 1136,
        "issueId": 1
      }, { "brandId": 425, "deviceId": 426, "serviceId": 1497, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 562,
        "serviceId": 1721,
        "issueId": 1
      }, { "brandId": 69, "deviceId": 541, "serviceId": 1513, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 541,
        "serviceId": 1514,
        "issueId": 10
      }, { "brandId": 69, "deviceId": 541, "serviceId": 1594, "issueId": 14 }, {
        "brandId": 69,
        "deviceId": 541,
        "serviceId": 1592,
        "issueId": 5
      }, { "brandId": 69, "deviceId": 541, "serviceId": 1591, "issueId": 13 }, {
        "brandId": 69,
        "deviceId": 541,
        "serviceId": 1598,
        "issueId": 3
      }, { "brandId": 69, "deviceId": 541, "serviceId": 1596, "issueId": 4 }, {
        "brandId": 69,
        "deviceId": 541,
        "serviceId": 1631,
        "issueId": 8
      }, { "brandId": 69, "deviceId": 541, "serviceId": 1632, "issueId": 8 }, {
        "brandId": 69,
        "deviceId": 541,
        "serviceId": 1633,
        "issueId": 6
      }, { "brandId": 69, "deviceId": 541, "serviceId": 1690, "issueId": 17 }, {
        "brandId": 69,
        "deviceId": 540,
        "serviceId": 1511,
        "issueId": 2
      }, { "brandId": 69, "deviceId": 540, "serviceId": 1512, "issueId": 10 }, {
        "brandId": 69,
        "deviceId": 540,
        "serviceId": 1593,
        "issueId": 3
      }, { "brandId": 69, "deviceId": 540, "serviceId": 1595, "issueId": 4 }, {
        "brandId": 69,
        "deviceId": 540,
        "serviceId": 1597,
        "issueId": 14
      }, { "brandId": 69, "deviceId": 540, "serviceId": 1690, "issueId": 17 }, {
        "brandId": 69,
        "deviceId": 338,
        "serviceId": 890,
        "issueId": 1
      }, { "brandId": 69, "deviceId": 338, "serviceId": 891, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 338,
        "serviceId": 893,
        "issueId": 15
      }, { "brandId": 69, "deviceId": 338, "serviceId": 894, "issueId": 6 }, {
        "brandId": 69,
        "deviceId": 338,
        "serviceId": 895,
        "issueId": 8
      }, { "brandId": 69, "deviceId": 338, "serviceId": 896, "issueId": 7 }, {
        "brandId": 69,
        "deviceId": 338,
        "serviceId": 897,
        "issueId": 5
      }, { "brandId": 69, "deviceId": 338, "serviceId": 897, "issueId": 6 }, {
        "brandId": 69,
        "deviceId": 338,
        "serviceId": 1543,
        "issueId": 8
      }, { "brandId": 69, "deviceId": 338, "serviceId": 1690, "issueId": 17 }, {
        "brandId": 69,
        "deviceId": 233,
        "serviceId": 312,
        "issueId": 1
      }, { "brandId": 69, "deviceId": 233, "serviceId": 880, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 233,
        "serviceId": 881,
        "issueId": 3
      }, { "brandId": 69, "deviceId": 233, "serviceId": 882, "issueId": 4 }, {
        "brandId": 69,
        "deviceId": 233,
        "serviceId": 888,
        "issueId": 8
      }, { "brandId": 69, "deviceId": 233, "serviceId": 1538, "issueId": 14 }, {
        "brandId": 69,
        "deviceId": 233,
        "serviceId": 1539,
        "issueId": 3
      }, { "brandId": 69, "deviceId": 233, "serviceId": 1690, "issueId": 17 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 108,
        "issueId": 1
      }, { "brandId": 69, "deviceId": 92, "serviceId": 286, "issueId": 14 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 290,
        "issueId": 7
      }, { "brandId": 69, "deviceId": 92, "serviceId": 290, "issueId": 5 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 294,
        "issueId": 13
      }, { "brandId": 69, "deviceId": 92, "serviceId": 298, "issueId": 6 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 304,
        "issueId": 6
      }, { "brandId": 69, "deviceId": 92, "serviceId": 308, "issueId": 15 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 320,
        "issueId": 2
      }, { "brandId": 69, "deviceId": 92, "serviceId": 418, "issueId": 3 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 419,
        "issueId": 2
      }, { "brandId": 69, "deviceId": 92, "serviceId": 420, "issueId": 12 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 421,
        "issueId": 10
      }, { "brandId": 69, "deviceId": 92, "serviceId": 422, "issueId": 11 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 423,
        "issueId": 4
      }, { "brandId": 69, "deviceId": 92, "serviceId": 424, "issueId": 18 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 425,
        "issueId": 21
      }, { "brandId": 69, "deviceId": 92, "serviceId": 430, "issueId": 19 }, {
        "brandId": 69,
        "deviceId": 92,
        "serviceId": 922,
        "issueId": 9
      }, { "brandId": 69, "deviceId": 92, "serviceId": 1690, "issueId": 17 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 620,
        "issueId": 15
      }, { "brandId": 69, "deviceId": 93, "serviceId": 621, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 622,
        "issueId": 2
      }, { "brandId": 69, "deviceId": 93, "serviceId": 623, "issueId": 10 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 624,
        "issueId": 18
      }, { "brandId": 69, "deviceId": 93, "serviceId": 625, "issueId": 12 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 626,
        "issueId": 19
      }, { "brandId": 69, "deviceId": 93, "serviceId": 627, "issueId": 6 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 628,
        "issueId": 6
      }, { "brandId": 69, "deviceId": 93, "serviceId": 629, "issueId": 13 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 630,
        "issueId": 7
      }, { "brandId": 69, "deviceId": 93, "serviceId": 630, "issueId": 5 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 631,
        "issueId": 3
      }, { "brandId": 69, "deviceId": 93, "serviceId": 632, "issueId": 11 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 633,
        "issueId": 4
      }, { "brandId": 69, "deviceId": 93, "serviceId": 634, "issueId": 1 }, {
        "brandId": 69,
        "deviceId": 93,
        "serviceId": 635,
        "issueId": 14
      }, { "brandId": 69, "deviceId": 91, "serviceId": 111, "issueId": 1 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 285,
        "issueId": 14
      }, { "brandId": 69, "deviceId": 91, "serviceId": 289, "issueId": 7 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 289,
        "issueId": 5
      }, { "brandId": 69, "deviceId": 91, "serviceId": 293, "issueId": 13 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 297,
        "issueId": 6
      }, { "brandId": 69, "deviceId": 91, "serviceId": 303, "issueId": 6 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 307,
        "issueId": 15
      }, { "brandId": 69, "deviceId": 91, "serviceId": 319, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 321,
        "issueId": 18
      }, { "brandId": 69, "deviceId": 91, "serviceId": 326, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 408,
        "issueId": 3
      }, { "brandId": 69, "deviceId": 91, "serviceId": 413, "issueId": 11 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 414,
        "issueId": 2
      }, { "brandId": 69, "deviceId": 91, "serviceId": 416, "issueId": 10 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 417,
        "issueId": 12
      }, { "brandId": 69, "deviceId": 91, "serviceId": 440, "issueId": 19 }, {
        "brandId": 69,
        "deviceId": 91,
        "serviceId": 921,
        "issueId": 9
      }, { "brandId": 69, "deviceId": 90, "serviceId": 110, "issueId": 1 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 284,
        "issueId": 14
      }, { "brandId": 69, "deviceId": 90, "serviceId": 288, "issueId": 7 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 288,
        "issueId": 5
      }, { "brandId": 69, "deviceId": 90, "serviceId": 292, "issueId": 13 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 296,
        "issueId": 6
      }, { "brandId": 69, "deviceId": 90, "serviceId": 302, "issueId": 6 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 306,
        "issueId": 15
      }, { "brandId": 69, "deviceId": 90, "serviceId": 318, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 325,
        "issueId": 11
      }, { "brandId": 69, "deviceId": 90, "serviceId": 393, "issueId": 3 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 395,
        "issueId": 21
      }, { "brandId": 69, "deviceId": 90, "serviceId": 397, "issueId": 21 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 403,
        "issueId": 2
      }, { "brandId": 69, "deviceId": 90, "serviceId": 407, "issueId": 12 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 448,
        "issueId": 19
      }, { "brandId": 69, "deviceId": 90, "serviceId": 323, "issueId": 18 }, {
        "brandId": 69,
        "deviceId": 90,
        "serviceId": 920,
        "issueId": 9
      }, { "brandId": 69, "deviceId": 88, "serviceId": 109, "issueId": 1 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 283,
        "issueId": 14
      }, { "brandId": 69, "deviceId": 88, "serviceId": 287, "issueId": 7 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 287,
        "issueId": 5
      }, { "brandId": 69, "deviceId": 88, "serviceId": 291, "issueId": 13 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 295,
        "issueId": 6
      }, { "brandId": 69, "deviceId": 88, "serviceId": 301, "issueId": 6 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 305,
        "issueId": 15
      }, { "brandId": 69, "deviceId": 88, "serviceId": 317, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 322,
        "issueId": 18
      }, { "brandId": 69, "deviceId": 88, "serviceId": 324, "issueId": 4 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 390,
        "issueId": 2
      }, { "brandId": 69, "deviceId": 88, "serviceId": 391, "issueId": 11 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 392,
        "issueId": 3
      }, { "brandId": 69, "deviceId": 88, "serviceId": 394, "issueId": 21 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 396,
        "issueId": 21
      }, { "brandId": 69, "deviceId": 88, "serviceId": 398, "issueId": 12 }, {
        "brandId": 69,
        "deviceId": 88,
        "serviceId": 449,
        "issueId": 19
      }, { "brandId": 69, "deviceId": 230, "serviceId": 116, "issueId": 1 }, {
        "brandId": 69,
        "deviceId": 230,
        "serviceId": 117,
        "issueId": 1
      }, { "brandId": 69, "deviceId": 230, "serviceId": 637, "issueId": 21 }, {
        "brandId": 69,
        "deviceId": 230,
        "serviceId": 638,
        "issueId": 2
      }, { "brandId": 69, "deviceId": 230, "serviceId": 639, "issueId": 7 }, {
        "brandId": 69,
        "deviceId": 230,
        "serviceId": 640,
        "issueId": 8
      }, { "brandId": 69, "deviceId": 230, "serviceId": 641, "issueId": 12 }, {
        "brandId": 69,
        "deviceId": 230,
        "serviceId": 644,
        "issueId": 14
      }, { "brandId": 69, "deviceId": 95, "serviceId": 1586, "issueId": 1 }, {
        "brandId": 69,
        "deviceId": 95,
        "serviceId": 1587,
        "issueId": 1
      }, { "brandId": 69, "deviceId": 98, "serviceId": 118, "issueId": 1 }, {
        "brandId": 69,
        "deviceId": 98,
        "serviceId": 122,
        "issueId": 1
      }, { "brandId": 69, "deviceId": 98, "serviceId": 645, "issueId": 2 }, {
        "brandId": 69,
        "deviceId": 98,
        "serviceId": 646,
        "issueId": 7
      }, { "brandId": 69, "deviceId": 98, "serviceId": 647, "issueId": 14 }, {
        "brandId": 69,
        "deviceId": 98,
        "serviceId": 648,
        "issueId": 9
      }, { "brandId": 84, "deviceId": 413, "serviceId": 1122, "issueId": 1 }, {
        "brandId": 84,
        "deviceId": 413,
        "serviceId": 1475,
        "issueId": 2
      }, { "brandId": 84, "deviceId": 533, "serviceId": 1472, "issueId": 2 }, {
        "brandId": 84,
        "deviceId": 533,
        "serviceId": 1473,
        "issueId": 10
      }, { "brandId": 84, "deviceId": 533, "serviceId": 1474, "issueId": 15 }, {
        "brandId": 84,
        "deviceId": 412,
        "serviceId": 1121,
        "issueId": 1
      }, { "brandId": 84, "deviceId": 412, "serviceId": 1469, "issueId": 2 }, {
        "brandId": 84,
        "deviceId": 412,
        "serviceId": 1470,
        "issueId": 15
      }, { "brandId": 84, "deviceId": 412, "serviceId": 1471, "issueId": 10 }, {
        "brandId": 84,
        "deviceId": 411,
        "serviceId": 1115,
        "issueId": 1
      }, { "brandId": 84, "deviceId": 411, "serviceId": 1116, "issueId": 8 }, {
        "brandId": 84,
        "deviceId": 411,
        "serviceId": 1117,
        "issueId": 7
      }, { "brandId": 84, "deviceId": 411, "serviceId": 1119, "issueId": 4 }, {
        "brandId": 84,
        "deviceId": 411,
        "serviceId": 1120,
        "issueId": 2
      }, { "brandId": 84, "deviceId": 534, "serviceId": 1476, "issueId": 2 }, {
        "brandId": 84,
        "deviceId": 534,
        "serviceId": 1477,
        "issueId": 10
      }, { "brandId": 84, "deviceId": 534, "serviceId": 1478, "issueId": 15 }, {
        "brandId": 494,
        "deviceId": 498,
        "serviceId": 1388,
        "issueId": 1
      }, { "brandId": 494, "deviceId": 498, "serviceId": 1485, "issueId": 2 }, {
        "brandId": 494,
        "deviceId": 497,
        "serviceId": 1387,
        "issueId": 1
      }, { "brandId": 494, "deviceId": 497, "serviceId": 1490, "issueId": 2 }, {
        "brandId": 494,
        "deviceId": 496,
        "serviceId": 1395,
        "issueId": 1
      }, { "brandId": 494, "deviceId": 496, "serviceId": 1491, "issueId": 2 }, {
        "brandId": 494,
        "deviceId": 495,
        "serviceId": 1394,
        "issueId": 1
      }, { "brandId": 494, "deviceId": 495, "serviceId": 1492, "issueId": 2 }, {
        "brandId": 494,
        "deviceId": 495,
        "serviceId": 1599,
        "issueId": 1
      }, { "brandId": 494, "deviceId": 499, "serviceId": 1389, "issueId": 1 }, {
        "brandId": 494,
        "deviceId": 499,
        "serviceId": 1493,
        "issueId": 2
      }, { "brandId": 494, "deviceId": 500, "serviceId": 1390, "issueId": 1 }, {
        "brandId": 494,
        "deviceId": 500,
        "serviceId": 1576,
        "issueId": 6
      }, { "brandId": 494, "deviceId": 500, "serviceId": 1588, "issueId": 2 }, {
        "brandId": 494,
        "deviceId": 500,
        "serviceId": 1600,
        "issueId": 6
      }, { "brandId": 494, "deviceId": 500, "serviceId": 1601, "issueId": 5 }, {
        "brandId": 494,
        "deviceId": 500,
        "serviceId": 1577,
        "issueId": 15
      }, { "brandId": 77, "deviceId": 561, "serviceId": 1703, "issueId": 1 }, {
        "brandId": 77,
        "deviceId": 380,
        "serviceId": 1483,
        "issueId": 6
      }, { "brandId": 77, "deviceId": 380, "serviceId": 1484, "issueId": 2 }, {
        "brandId": 77,
        "deviceId": 380,
        "serviceId": 1578,
        "issueId": 7
      }, { "brandId": 77, "deviceId": 380, "serviceId": 1578, "issueId": 8 }, {
        "brandId": 77,
        "deviceId": 380,
        "serviceId": 1575,
        "issueId": 4
      }, { "brandId": 77, "deviceId": 380, "serviceId": 1574, "issueId": 3 }, {
        "brandId": 77,
        "deviceId": 380,
        "serviceId": 1573,
        "issueId": 6
      }, { "brandId": 77, "deviceId": 535, "serviceId": 1479, "issueId": 1 }, {
        "brandId": 77,
        "deviceId": 535,
        "serviceId": 1480,
        "issueId": 6
      }, { "brandId": 77, "deviceId": 535, "serviceId": 1481, "issueId": 2 }, {
        "brandId": 77,
        "deviceId": 232,
        "serviceId": 309,
        "issueId": 1
      }, { "brandId": 77, "deviceId": 232, "serviceId": 786, "issueId": 16 }, {
        "brandId": 77,
        "deviceId": 232,
        "serviceId": 977,
        "issueId": 6
      }, { "brandId": 77, "deviceId": 232, "serviceId": 978, "issueId": 6 }, {
        "brandId": 77,
        "deviceId": 232,
        "serviceId": 979,
        "issueId": 8
      }, { "brandId": 77, "deviceId": 232, "serviceId": 979, "issueId": 7 }, {
        "brandId": 77,
        "deviceId": 232,
        "serviceId": 1540,
        "issueId": 2
      }, { "brandId": 77, "deviceId": 232, "serviceId": 1541, "issueId": 4 }, {
        "brandId": 77,
        "deviceId": 554,
        "serviceId": 1681,
        "issueId": 1
      }, { "brandId": 77, "deviceId": 554, "serviceId": 1728, "issueId": 3 }, {
        "brandId": 77,
        "deviceId": 554,
        "serviceId": 1729,
        "issueId": 4
      }, { "brandId": 77, "deviceId": 554, "serviceId": 1731, "issueId": 14 }, {
        "brandId": 77,
        "deviceId": 461,
        "serviceId": 1266,
        "issueId": 6
      }, { "brandId": 77, "deviceId": 461, "serviceId": 1267, "issueId": 3 }, {
        "brandId": 77,
        "deviceId": 462,
        "serviceId": 1268,
        "issueId": 4
      }, { "brandId": 77, "deviceId": 462, "serviceId": 1269, "issueId": 6 }, {
        "brandId": 77,
        "deviceId": 462,
        "serviceId": 1270,
        "issueId": 2
      }, { "brandId": 77, "deviceId": 462, "serviceId": 1271, "issueId": 18 }, {
        "brandId": 77,
        "deviceId": 462,
        "serviceId": 1272,
        "issueId": 1
      }, { "brandId": 77, "deviceId": 185, "serviceId": 1176, "issueId": 1 }, {
        "brandId": 77,
        "deviceId": 186,
        "serviceId": 889,
        "issueId": 1
      }, { "brandId": 77, "deviceId": 189, "serviceId": 1547, "issueId": 1 }, {
        "brandId": 77,
        "deviceId": 189,
        "serviceId": 1548,
        "issueId": 1
      }, { "brandId": 77, "deviceId": 333, "serviceId": 872, "issueId": 1 }, {
        "brandId": 77,
        "deviceId": 333,
        "serviceId": 873,
        "issueId": 1
      }, { "brandId": 77, "deviceId": 536, "serviceId": 1482, "issueId": 6 }, {
        "brandId": 77,
        "deviceId": 536,
        "serviceId": 1549,
        "issueId": 1
      }, { "brandId": 468, "deviceId": 471, "serviceId": 1293, "issueId": 3 }, {
        "brandId": 468,
        "deviceId": 471,
        "serviceId": 1306,
        "issueId": 14
      }, { "brandId": 468, "deviceId": 471, "serviceId": 1338, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 471,
        "serviceId": 1362,
        "issueId": 12
      }, { "brandId": 468, "deviceId": 482, "serviceId": 1307, "issueId": 14 }, {
        "brandId": 468,
        "deviceId": 483,
        "serviceId": 1310,
        "issueId": 14
      }, { "brandId": 468, "deviceId": 483, "serviceId": 1318, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 478,
        "serviceId": 1301,
        "issueId": 20
      }, { "brandId": 468, "deviceId": 478, "serviceId": 1309, "issueId": 14 }, {
        "brandId": 468,
        "deviceId": 469,
        "serviceId": 1291,
        "issueId": 3
      }, { "brandId": 468, "deviceId": 473, "serviceId": 1295, "issueId": 3 }, {
        "brandId": 468,
        "deviceId": 473,
        "serviceId": 1313,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 473, "serviceId": 1336, "issueId": 14 }, {
        "brandId": 468,
        "deviceId": 473,
        "serviceId": 1343,
        "issueId": 5
      }, { "brandId": 468, "deviceId": 473, "serviceId": 1348, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 480,
        "serviceId": 1303,
        "issueId": 14
      }, { "brandId": 468, "deviceId": 480, "serviceId": 1361, "issueId": 12 }, {
        "brandId": 468,
        "deviceId": 502,
        "serviceId": 1397,
        "issueId": 21
      }, { "brandId": 468, "deviceId": 475, "serviceId": 1297, "issueId": 4 }, {
        "brandId": 468,
        "deviceId": 475,
        "serviceId": 1341,
        "issueId": 7
      }, { "brandId": 468, "deviceId": 475, "serviceId": 1341, "issueId": 8 }, {
        "brandId": 468,
        "deviceId": 475,
        "serviceId": 1351,
        "issueId": 1
      }, { "brandId": 468, "deviceId": 475, "serviceId": 1360, "issueId": 12 }, {
        "brandId": 468,
        "deviceId": 476,
        "serviceId": 1299,
        "issueId": 4
      }, { "brandId": 468, "deviceId": 476, "serviceId": 1308, "issueId": 14 }, {
        "brandId": 468,
        "deviceId": 476,
        "serviceId": 1312,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 476, "serviceId": 1342, "issueId": 5 }, {
        "brandId": 468,
        "deviceId": 476,
        "serviceId": 1359,
        "issueId": 12
      }, { "brandId": 468, "deviceId": 487, "serviceId": 1316, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 487,
        "serviceId": 1644,
        "issueId": 1
      }, { "brandId": 468, "deviceId": 486, "serviceId": 1315, "issueId": 21 }, {
        "brandId": 468,
        "deviceId": 477,
        "serviceId": 1300,
        "issueId": 3
      }, { "brandId": 468, "deviceId": 477, "serviceId": 1305, "issueId": 14 }, {
        "brandId": 468,
        "deviceId": 477,
        "serviceId": 1347,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 489, "serviceId": 1319, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 489,
        "serviceId": 1335,
        "issueId": 4
      }, { "brandId": 468, "deviceId": 489, "serviceId": 1350, "issueId": 1 }, {
        "brandId": 468,
        "deviceId": 474,
        "serviceId": 1296,
        "issueId": 4
      }, { "brandId": 468, "deviceId": 474, "serviceId": 1317, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 474,
        "serviceId": 1352,
        "issueId": 8
      }, { "brandId": 468, "deviceId": 474, "serviceId": 1353, "issueId": 7 }, {
        "brandId": 468,
        "deviceId": 474,
        "serviceId": 1354,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 474, "serviceId": 1355, "issueId": 12 }, {
        "brandId": 468,
        "deviceId": 474,
        "serviceId": 1356,
        "issueId": 5
      }, { "brandId": 468, "deviceId": 550, "serviceId": 1560, "issueId": 1 }, {
        "brandId": 468,
        "deviceId": 470,
        "serviceId": 1292,
        "issueId": 3
      }, { "brandId": 468, "deviceId": 470, "serviceId": 1346, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 481,
        "serviceId": 1304,
        "issueId": 14
      }, { "brandId": 468, "deviceId": 481, "serviceId": 1334, "issueId": 4 }, {
        "brandId": 468,
        "deviceId": 481,
        "serviceId": 1345,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 481, "serviceId": 1358, "issueId": 12 }, {
        "brandId": 468,
        "deviceId": 481,
        "serviceId": 1365,
        "issueId": 1
      }, { "brandId": 468, "deviceId": 481, "serviceId": 1391, "issueId": 21 }, {
        "brandId": 468,
        "deviceId": 472,
        "serviceId": 1294,
        "issueId": 3
      }, { "brandId": 468, "deviceId": 472, "serviceId": 1298, "issueId": 4 }, {
        "brandId": 468,
        "deviceId": 472,
        "serviceId": 1314,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 472, "serviceId": 1320, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 472,
        "serviceId": 1349,
        "issueId": 1
      }, { "brandId": 468, "deviceId": 488, "serviceId": 1321, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 488,
        "serviceId": 1357,
        "issueId": 12
      }, { "brandId": 468, "deviceId": 488, "serviceId": 1367, "issueId": 1 }, {
        "brandId": 468,
        "deviceId": 485,
        "serviceId": 1311,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 493, "serviceId": 1344, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 491,
        "serviceId": 1340,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 491, "serviceId": 1364, "issueId": 1 }, {
        "brandId": 468,
        "deviceId": 490,
        "serviceId": 1322,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 490, "serviceId": 1363, "issueId": 12 }, {
        "brandId": 468,
        "deviceId": 490,
        "serviceId": 1366,
        "issueId": 1
      }, { "brandId": 468, "deviceId": 479, "serviceId": 1302, "issueId": 11 }, {
        "brandId": 468,
        "deviceId": 484,
        "serviceId": 1333,
        "issueId": 4
      }, { "brandId": 468, "deviceId": 484, "serviceId": 1337, "issueId": 6 }, {
        "brandId": 468,
        "deviceId": 484,
        "serviceId": 1339,
        "issueId": 6
      }, { "brandId": 468, "deviceId": 503, "serviceId": 1396, "issueId": 12 }, {
        "brandId": 423,
        "deviceId": 519,
        "serviceId": 1442,
        "issueId": 1
      }, { "brandId": 423, "deviceId": 519, "serviceId": 1498, "issueId": 2 }, {
        "brandId": 423,
        "deviceId": 537,
        "serviceId": 1499,
        "issueId": 2
      }, { "brandId": 423, "deviceId": 537, "serviceId": 1579, "issueId": 1 }, {
        "brandId": 423,
        "deviceId": 537,
        "serviceId": 1585,
        "issueId": 8
      }, { "brandId": 423, "deviceId": 424, "serviceId": 1132, "issueId": 5 }, {
        "brandId": 423,
        "deviceId": 424,
        "serviceId": 1133,
        "issueId": 7
      }, { "brandId": 423, "deviceId": 424, "serviceId": 1135, "issueId": 2 }, {
        "brandId": 423,
        "deviceId": 517,
        "serviceId": 1431,
        "issueId": 1
      }, { "brandId": 423, "deviceId": 517, "serviceId": 1500, "issueId": 2 }, {
        "brandId": 452,
        "deviceId": 453,
        "serviceId": 1244,
        "issueId": 2
      }, { "brandId": 452, "deviceId": 453, "serviceId": 1243, "issueId": 1 }, {
        "brandId": 452,
        "deviceId": 501,
        "serviceId": 1398,
        "issueId": 1
      }, { "brandId": 452, "deviceId": 501, "serviceId": 1496, "issueId": 2 }, {
        "brandId": 331,
        "deviceId": 332,
        "serviceId": 860,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 556, "serviceId": 1695, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 555,
        "serviceId": 1696,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 375, "serviceId": 1382, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 375,
        "serviceId": 1045,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 375, "serviceId": 1383, "issueId": 14 }, {
        "brandId": 71,
        "deviceId": 375,
        "serviceId": 1385,
        "issueId": 8
      }, { "brandId": 71, "deviceId": 375, "serviceId": 1386, "issueId": 7 }, {
        "brandId": 71,
        "deviceId": 375,
        "serviceId": 1384,
        "issueId": 13
      }, { "brandId": 71, "deviceId": 354, "serviceId": 937, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 354,
        "serviceId": 1375,
        "issueId": 3
      }, { "brandId": 71, "deviceId": 354, "serviceId": 1376, "issueId": 21 }, {
        "brandId": 71,
        "deviceId": 354,
        "serviceId": 1377,
        "issueId": 14
      }, { "brandId": 71, "deviceId": 354, "serviceId": 1378, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 354,
        "serviceId": 1379,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 354, "serviceId": 1380, "issueId": 13 }, {
        "brandId": 71,
        "deviceId": 354,
        "serviceId": 1381,
        "issueId": 2
      }, { "brandId": 71, "deviceId": 354, "serviceId": 1392, "issueId": 4 }, {
        "brandId": 71,
        "deviceId": 354,
        "serviceId": 1393,
        "issueId": 15
      }, { "brandId": 71, "deviceId": 103, "serviceId": 649, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 538,
        "serviceId": 1502,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 363, "serviceId": 1000, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 363,
        "serviceId": 1001,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 363, "serviceId": 1002, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 363,
        "serviceId": 1003,
        "issueId": 18
      }, { "brandId": 71, "deviceId": 363, "serviceId": 1004, "issueId": 16 }, {
        "brandId": 71,
        "deviceId": 363,
        "serviceId": 1005,
        "issueId": 8
      }, { "brandId": 71, "deviceId": 563, "serviceId": 1722, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 356,
        "serviceId": 985,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 361, "serviceId": 1056, "issueId": 14 }, {
        "brandId": 71,
        "deviceId": 361,
        "serviceId": 996,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 361, "serviceId": 1058, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 361,
        "serviceId": 1057,
        "issueId": 8
      }, { "brandId": 71, "deviceId": 362, "serviceId": 997, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 362,
        "serviceId": 1715,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 362, "serviceId": 1716, "issueId": 2 }, {
        "brandId": 71,
        "deviceId": 362,
        "serviceId": 1717,
        "issueId": 16
      }, { "brandId": 71, "deviceId": 362, "serviceId": 1718, "issueId": 18 }, {
        "brandId": 71,
        "deviceId": 362,
        "serviceId": 1719,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 362, "serviceId": 1720, "issueId": 14 }, {
        "brandId": 71,
        "deviceId": 362,
        "serviceId": 1723,
        "issueId": 3
      }, { "brandId": 71, "deviceId": 264, "serviceId": 1017, "issueId": 18 }, {
        "brandId": 71,
        "deviceId": 264,
        "serviceId": 1018,
        "issueId": 15
      }, { "brandId": 71, "deviceId": 264, "serviceId": 1016, "issueId": 8 }, {
        "brandId": 71,
        "deviceId": 264,
        "serviceId": 1013,
        "issueId": 11
      }, { "brandId": 71, "deviceId": 264, "serviceId": 1014, "issueId": 10 }, {
        "brandId": 71,
        "deviceId": 264,
        "serviceId": 1021,
        "issueId": 7
      }, { "brandId": 71, "deviceId": 264, "serviceId": 596, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 264,
        "serviceId": 599,
        "issueId": 2
      }, { "brandId": 71, "deviceId": 264, "serviceId": 600, "issueId": 3 }, {
        "brandId": 71,
        "deviceId": 544,
        "serviceId": 1531,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 108, "serviceId": 114, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 102,
        "serviceId": 112,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 102, "serviceId": 676, "issueId": 3 }, {
        "brandId": 71,
        "deviceId": 102,
        "serviceId": 685,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 102, "serviceId": 698, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 102,
        "serviceId": 699,
        "issueId": 21
      }, { "brandId": 71, "deviceId": 102, "serviceId": 701, "issueId": 2 }, {
        "brandId": 71,
        "deviceId": 102,
        "serviceId": 703,
        "issueId": 10
      }, { "brandId": 71, "deviceId": 102, "serviceId": 705, "issueId": 7 }, {
        "brandId": 71,
        "deviceId": 102,
        "serviceId": 706,
        "issueId": 16
      }, { "brandId": 71, "deviceId": 102, "serviceId": 707, "issueId": 12 }, {
        "brandId": 71,
        "deviceId": 102,
        "serviceId": 708,
        "issueId": 15
      }, { "brandId": 71, "deviceId": 102, "serviceId": 709, "issueId": 18 }, {
        "brandId": 71,
        "deviceId": 102,
        "serviceId": 1561,
        "issueId": 4
      }, { "brandId": 71, "deviceId": 101, "serviceId": 113, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 101,
        "serviceId": 711,
        "issueId": 3
      }, { "brandId": 71, "deviceId": 101, "serviceId": 712, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 101,
        "serviceId": 714,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 101, "serviceId": 716, "issueId": 21 }, {
        "brandId": 71,
        "deviceId": 101,
        "serviceId": 717,
        "issueId": 2
      }, { "brandId": 71, "deviceId": 101, "serviceId": 718, "issueId": 10 }, {
        "brandId": 71,
        "deviceId": 101,
        "serviceId": 719,
        "issueId": 7
      }, { "brandId": 71, "deviceId": 101, "serviceId": 720, "issueId": 18 }, {
        "brandId": 71,
        "deviceId": 101,
        "serviceId": 720,
        "issueId": 16
      }, { "brandId": 71, "deviceId": 101, "serviceId": 721, "issueId": 12 }, {
        "brandId": 71,
        "deviceId": 101,
        "serviceId": 722,
        "issueId": 15
      }, { "brandId": 71, "deviceId": 113, "serviceId": 124, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 113,
        "serviceId": 727,
        "issueId": 3
      }, { "brandId": 71, "deviceId": 113, "serviceId": 728, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 113,
        "serviceId": 729,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 113, "serviceId": 730, "issueId": 21 }, {
        "brandId": 71,
        "deviceId": 113,
        "serviceId": 731,
        "issueId": 7
      }, { "brandId": 71, "deviceId": 113, "serviceId": 732, "issueId": 16 }, {
        "brandId": 71,
        "deviceId": 113,
        "serviceId": 733,
        "issueId": 12
      }, { "brandId": 71, "deviceId": 113, "serviceId": 734, "issueId": 15 }, {
        "brandId": 71,
        "deviceId": 455,
        "serviceId": 1253,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 463, "serviceId": 1273, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 460,
        "serviceId": 1260,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 460, "serviceId": 1261, "issueId": 5 }, {
        "brandId": 71,
        "deviceId": 460,
        "serviceId": 1262,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 460, "serviceId": 1263, "issueId": 18 }, {
        "brandId": 71,
        "deviceId": 460,
        "serviceId": 1264,
        "issueId": 2
      }, { "brandId": 71, "deviceId": 460, "serviceId": 1265, "issueId": 15 }, {
        "brandId": 71,
        "deviceId": 106,
        "serviceId": 735,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 106, "serviceId": 736, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 106,
        "serviceId": 737,
        "issueId": 3
      }, { "brandId": 71, "deviceId": 106, "serviceId": 738, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 106,
        "serviceId": 739,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 106, "serviceId": 740, "issueId": 2 }, {
        "brandId": 71,
        "deviceId": 106,
        "serviceId": 741,
        "issueId": 7
      }, { "brandId": 71, "deviceId": 106, "serviceId": 742, "issueId": 16 }, {
        "brandId": 71,
        "deviceId": 106,
        "serviceId": 742,
        "issueId": 18
      }, { "brandId": 71, "deviceId": 106, "serviceId": 743, "issueId": 12 }, {
        "brandId": 71,
        "deviceId": 106,
        "serviceId": 744,
        "issueId": 15
      }, { "brandId": 71, "deviceId": 105, "serviceId": 281, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 105,
        "serviceId": 282,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 105, "serviceId": 745, "issueId": 3 }, {
        "brandId": 71,
        "deviceId": 105,
        "serviceId": 746,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 105, "serviceId": 747, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 105,
        "serviceId": 748,
        "issueId": 2
      }, { "brandId": 71, "deviceId": 105, "serviceId": 749, "issueId": 7 }, {
        "brandId": 71,
        "deviceId": 105,
        "serviceId": 750,
        "issueId": 16
      }, { "brandId": 71, "deviceId": 105, "serviceId": 750, "issueId": 18 }, {
        "brandId": 71,
        "deviceId": 105,
        "serviceId": 751,
        "issueId": 12
      }, { "brandId": 71, "deviceId": 105, "serviceId": 752, "issueId": 15 }, {
        "brandId": 71,
        "deviceId": 110,
        "serviceId": 753,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 110, "serviceId": 754, "issueId": 3 }, {
        "brandId": 71,
        "deviceId": 110,
        "serviceId": 755,
        "issueId": 21
      }, { "brandId": 71, "deviceId": 110, "serviceId": 756, "issueId": 2 }, {
        "brandId": 71,
        "deviceId": 110,
        "serviceId": 757,
        "issueId": 10
      }, { "brandId": 71, "deviceId": 110, "serviceId": 758, "issueId": 7 }, {
        "brandId": 71,
        "deviceId": 110,
        "serviceId": 759,
        "issueId": 16
      }, { "brandId": 71, "deviceId": 110, "serviceId": 759, "issueId": 18 }, {
        "brandId": 71,
        "deviceId": 110,
        "serviceId": 761,
        "issueId": 12
      }, { "brandId": 71, "deviceId": 110, "serviceId": 762, "issueId": 15 }, {
        "brandId": 71,
        "deviceId": 104,
        "serviceId": 115,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 109, "serviceId": 507, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 109,
        "serviceId": 508,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 109, "serviceId": 509, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 109,
        "serviceId": 510,
        "issueId": 2
      }, { "brandId": 71, "deviceId": 109, "serviceId": 511, "issueId": 6 }, {
        "brandId": 71,
        "deviceId": 109,
        "serviceId": 512,
        "issueId": 6
      }, { "brandId": 71, "deviceId": 109, "serviceId": 513, "issueId": 18 }, {
        "brandId": 71,
        "deviceId": 109,
        "serviceId": 514,
        "issueId": 4
      }, { "brandId": 71, "deviceId": 122, "serviceId": 128, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 122,
        "serviceId": 129,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 231, "serviceId": 123, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 265,
        "serviceId": 602,
        "issueId": 1
      }, { "brandId": 71, "deviceId": 265, "serviceId": 603, "issueId": 1 }, {
        "brandId": 71,
        "deviceId": 265,
        "serviceId": 604,
        "issueId": 2
      }, { "brandId": 70, "deviceId": 542, "serviceId": 1517, "issueId": 3 }, {
        "brandId": 70,
        "deviceId": 542,
        "serviceId": 1518,
        "issueId": 4
      }, { "brandId": 70, "deviceId": 542, "serviceId": 1589, "issueId": 14 }, {
        "brandId": 70,
        "deviceId": 542,
        "serviceId": 1590,
        "issueId": 6
      }, { "brandId": 70, "deviceId": 542, "serviceId": 1623, "issueId": 2 }, {
        "brandId": 70,
        "deviceId": 542,
        "serviceId": 1628,
        "issueId": 8
      }, { "brandId": 70, "deviceId": 542, "serviceId": 1635, "issueId": 12 }, {
        "brandId": 70,
        "deviceId": 542,
        "serviceId": 1636,
        "issueId": 10
      }, { "brandId": 70, "deviceId": 542, "serviceId": 1712, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 542,
        "serviceId": 1713,
        "issueId": 21
      }, { "brandId": 70, "deviceId": 542, "serviceId": 1714, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 543,
        "serviceId": 1525,
        "issueId": 3
      }, { "brandId": 70, "deviceId": 543, "serviceId": 1526, "issueId": 4 }, {
        "brandId": 70,
        "deviceId": 543,
        "serviceId": 1629,
        "issueId": 6
      }, { "brandId": 70, "deviceId": 543, "serviceId": 1630, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 543,
        "serviceId": 1634,
        "issueId": 8
      }, { "brandId": 70, "deviceId": 543, "serviceId": 1637, "issueId": 14 }, {
        "brandId": 70,
        "deviceId": 543,
        "serviceId": 1638,
        "issueId": 10
      }, { "brandId": 70, "deviceId": 543, "serviceId": 1640, "issueId": 12 }, {
        "brandId": 70,
        "deviceId": 543,
        "serviceId": 1641,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 543, "serviceId": 1709, "issueId": 21 }, {
        "brandId": 70,
        "deviceId": 543,
        "serviceId": 1711,
        "issueId": 16
      }, { "brandId": 70, "deviceId": 543, "serviceId": 1711, "issueId": 18 }, {
        "brandId": 70,
        "deviceId": 559,
        "serviceId": 1698,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 558, "serviceId": 1697, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 557,
        "serviceId": 1699,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 353, "serviceId": 935, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 353,
        "serviceId": 936,
        "issueId": 14
      }, { "brandId": 70, "deviceId": 353, "serviceId": 1519, "issueId": 12 }, {
        "brandId": 70,
        "deviceId": 353,
        "serviceId": 1522,
        "issueId": 6
      }, { "brandId": 70, "deviceId": 353, "serviceId": 1523, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 353,
        "serviceId": 1524,
        "issueId": 11
      }, { "brandId": 70, "deviceId": 353, "serviceId": 1527, "issueId": 9 }, {
        "brandId": 70,
        "deviceId": 353,
        "serviceId": 1528,
        "issueId": 7
      }, { "brandId": 70, "deviceId": 353, "serviceId": 1529, "issueId": 8 }, {
        "brandId": 70,
        "deviceId": 353,
        "serviceId": 1530,
        "issueId": 21
      }, { "brandId": 70, "deviceId": 306, "serviceId": 899, "issueId": 14 }, {
        "brandId": 70,
        "deviceId": 306,
        "serviceId": 900,
        "issueId": 21
      }, { "brandId": 70, "deviceId": 306, "serviceId": 901, "issueId": 18 }, {
        "brandId": 70,
        "deviceId": 306,
        "serviceId": 902,
        "issueId": 16
      }, { "brandId": 70, "deviceId": 306, "serviceId": 903, "issueId": 12 }, {
        "brandId": 70,
        "deviceId": 306,
        "serviceId": 903,
        "issueId": 5
      }, { "brandId": 70, "deviceId": 306, "serviceId": 904, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 306,
        "serviceId": 1642,
        "issueId": 4
      }, { "brandId": 70, "deviceId": 306, "serviceId": 1643, "issueId": 3 }, {
        "brandId": 70,
        "deviceId": 306,
        "serviceId": 1707,
        "issueId": 6
      }, { "brandId": 70, "deviceId": 306, "serviceId": 1708, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 307,
        "serviceId": 905,
        "issueId": 14
      }, { "brandId": 70, "deviceId": 307, "serviceId": 906, "issueId": 21 }, {
        "brandId": 70,
        "deviceId": 307,
        "serviceId": 907,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 160, "serviceId": 710, "issueId": 21 }, {
        "brandId": 70,
        "deviceId": 160,
        "serviceId": 909,
        "issueId": 3
      }, { "brandId": 70, "deviceId": 160, "serviceId": 911, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 160,
        "serviceId": 913,
        "issueId": 12
      }, { "brandId": 70, "deviceId": 160, "serviceId": 913, "issueId": 5 }, {
        "brandId": 70,
        "deviceId": 160,
        "serviceId": 914,
        "issueId": 6
      }, { "brandId": 70, "deviceId": 160, "serviceId": 915, "issueId": 16 }, {
        "brandId": 70,
        "deviceId": 160,
        "serviceId": 311,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 159, "serviceId": 165, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 159,
        "serviceId": 590,
        "issueId": 21
      }, { "brandId": 70, "deviceId": 366, "serviceId": 1025, "issueId": 14 }, {
        "brandId": 70,
        "deviceId": 366,
        "serviceId": 1026,
        "issueId": 18
      }, { "brandId": 70, "deviceId": 366, "serviceId": 1027, "issueId": 16 }, {
        "brandId": 70,
        "deviceId": 366,
        "serviceId": 1032,
        "issueId": 2
      }, { "brandId": 70, "deviceId": 366, "serviceId": 1033, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 157,
        "serviceId": 164,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 157, "serviceId": 589, "issueId": 21 }, {
        "brandId": 70,
        "deviceId": 379,
        "serviceId": 1562,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 548, "serviceId": 1552, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 548,
        "serviceId": 1553,
        "issueId": 2
      }, { "brandId": 70, "deviceId": 548, "serviceId": 1557, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 548,
        "serviceId": 1555,
        "issueId": 4
      }, { "brandId": 70, "deviceId": 548, "serviceId": 1556, "issueId": 3 }, {
        "brandId": 70,
        "deviceId": 548,
        "serviceId": 1558,
        "issueId": 6
      }, { "brandId": 70, "deviceId": 548, "serviceId": 1581, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 357,
        "serviceId": 986,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 357, "serviceId": 987, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 357,
        "serviceId": 988,
        "issueId": 21
      }, { "brandId": 70, "deviceId": 357, "serviceId": 989, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 465,
        "serviceId": 1329,
        "issueId": 6
      }, { "brandId": 70, "deviceId": 465, "serviceId": 1330, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 465,
        "serviceId": 1331,
        "issueId": 2
      }, { "brandId": 70, "deviceId": 464, "serviceId": 1274, "issueId": 3 }, {
        "brandId": 70,
        "deviceId": 464,
        "serviceId": 1289,
        "issueId": 14
      }, { "brandId": 70, "deviceId": 464, "serviceId": 1290, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 464,
        "serviceId": 1328,
        "issueId": 6
      }, { "brandId": 70, "deviceId": 372, "serviceId": 1037, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 372,
        "serviceId": 1038,
        "issueId": 14
      }, { "brandId": 70, "deviceId": 372, "serviceId": 1039, "issueId": 6 }, {
        "brandId": 70,
        "deviceId": 372,
        "serviceId": 1040,
        "issueId": 18
      }, { "brandId": 70, "deviceId": 372, "serviceId": 1041, "issueId": 16 }, {
        "brandId": 70,
        "deviceId": 162,
        "serviceId": 469,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 162, "serviceId": 470, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 154,
        "serviceId": 156,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 151, "serviceId": 151, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 151,
        "serviceId": 152,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 152, "serviceId": 153, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 152,
        "serviceId": 154,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 153, "serviceId": 155, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 155,
        "serviceId": 157,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 156, "serviceId": 162, "issueId": 1 }, {
        "brandId": 70,
        "deviceId": 156,
        "serviceId": 163,
        "issueId": 1
      }, { "brandId": 70, "deviceId": 167, "serviceId": 1047, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 135,
        "serviceId": 481,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 385, "serviceId": 1068, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 136,
        "serviceId": 592,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 137, "serviceId": 484, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 138,
        "serviceId": 483,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 355, "serviceId": 964, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 139,
        "serviceId": 923,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 140, "serviceId": 485, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 140,
        "serviceId": 486,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 140, "serviceId": 487, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 141,
        "serviceId": 690,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 141, "serviceId": 859, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 295,
        "serviceId": 773,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 295, "serviceId": 965, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 142,
        "serviceId": 488,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 143, "serviceId": 760, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 145,
        "serviceId": 574,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 146, "serviceId": 572, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 146,
        "serviceId": 573,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 147, "serviceId": 616, "issueId": 10 }, {
        "brandId": 83,
        "deviceId": 147,
        "serviceId": 617,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 147, "serviceId": 665, "issueId": 2 }, {
        "brandId": 83,
        "deviceId": 147,
        "serviceId": 666,
        "issueId": 3
      }, { "brandId": 83, "deviceId": 147, "serviceId": 667, "issueId": 4 }, {
        "brandId": 83,
        "deviceId": 150,
        "serviceId": 1197,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 358, "serviceId": 990, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 358,
        "serviceId": 991,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 386, "serviceId": 1069, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 387,
        "serviceId": 1070,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 525, "serviceId": 1451, "issueId": 2 }, {
        "brandId": 83,
        "deviceId": 525,
        "serviceId": 1452,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 526, "serviceId": 1453, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 527,
        "serviceId": 1454,
        "issueId": 2
      }, { "brandId": 83, "deviceId": 527, "serviceId": 1455, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 527,
        "serviceId": 1456,
        "issueId": 1
      }, { "brandId": 83, "deviceId": 551, "serviceId": 1564, "issueId": 1 }, {
        "brandId": 83,
        "deviceId": 552,
        "serviceId": 1565,
        "issueId": 1
      }, { "brandId": 262, "deviceId": 263, "serviceId": 591, "issueId": 1 }, {
        "brandId": 262,
        "deviceId": 359,
        "serviceId": 994,
        "issueId": 1
      }, { "brandId": 262, "deviceId": 360, "serviceId": 995, "issueId": 1 }, {
        "brandId": 328,
        "deviceId": 560,
        "serviceId": 1700,
        "issueId": 1
      }, { "brandId": 328, "deviceId": 545, "serviceId": 1537, "issueId": 1 }, {
        "brandId": 328,
        "deviceId": 342,
        "serviceId": 924,
        "issueId": 1
      }, { "brandId": 328, "deviceId": 342, "serviceId": 1194, "issueId": 2 }, {
        "brandId": 328,
        "deviceId": 342,
        "serviceId": 1438,
        "issueId": 6
      }, { "brandId": 328, "deviceId": 342, "serviceId": 1439, "issueId": 6 }, {
        "brandId": 328,
        "deviceId": 342,
        "serviceId": 1440,
        "issueId": 8
      }, { "brandId": 328, "deviceId": 342, "serviceId": 1441, "issueId": 7 }, {
        "brandId": 328,
        "deviceId": 342,
        "serviceId": 1710,
        "issueId": 17
      }, { "brandId": 328, "deviceId": 330, "serviceId": 857, "issueId": 1 }, {
        "brandId": 328,
        "deviceId": 330,
        "serviceId": 1193,
        "issueId": 2
      }, { "brandId": 328, "deviceId": 330, "serviceId": 1437, "issueId": 4 }, {
        "brandId": 328,
        "deviceId": 330,
        "serviceId": 1494,
        "issueId": 8
      }, { "brandId": 328, "deviceId": 330, "serviceId": 1554, "issueId": 17 }, {
        "brandId": 328,
        "deviceId": 539,
        "serviceId": 1505,
        "issueId": 2
      }, { "brandId": 328, "deviceId": 539, "serviceId": 1507, "issueId": 3 }, {
        "brandId": 328,
        "deviceId": 539,
        "serviceId": 1508,
        "issueId": 8
      }, { "brandId": 328, "deviceId": 539, "serviceId": 1509, "issueId": 7 }, {
        "brandId": 328,
        "deviceId": 539,
        "serviceId": 1510,
        "issueId": 18
      }, { "brandId": 328, "deviceId": 539, "serviceId": 1536, "issueId": 1 }, {
        "brandId": 328,
        "deviceId": 515,
        "serviceId": 1430,
        "issueId": 1
      }, { "brandId": 328, "deviceId": 516, "serviceId": 1432, "issueId": 8 }, {
        "brandId": 328,
        "deviceId": 516,
        "serviceId": 1433,
        "issueId": 7
      }, { "brandId": 328, "deviceId": 516, "serviceId": 1434, "issueId": 2 }, {
        "brandId": 328,
        "deviceId": 516,
        "serviceId": 1435,
        "issueId": 10
      }, { "brandId": 328, "deviceId": 516, "serviceId": 1436, "issueId": 1 }, {
        "brandId": 82,
        "deviceId": 347,
        "serviceId": 932,
        "issueId": 1
      }, { "brandId": 82, "deviceId": 349, "serviceId": 933, "issueId": 1 }, {
        "brandId": 82,
        "deviceId": 349,
        "serviceId": 934,
        "issueId": 1
      }, { "brandId": 82, "deviceId": 206, "serviceId": 858, "issueId": 1 }, {
        "brandId": 82,
        "deviceId": 208,
        "serviceId": 1445,
        "issueId": 1
      }, { "brandId": 82, "deviceId": 520, "serviceId": 1446, "issueId": 1 }, {
        "brandId": 82,
        "deviceId": 521,
        "serviceId": 1447,
        "issueId": 1
      }, { "brandId": 82, "deviceId": 522, "serviceId": 1448, "issueId": 1 }, {
        "brandId": 82,
        "deviceId": 524,
        "serviceId": 1450,
        "issueId": 1
      } ];
    return new Promise( ( resolve, reject ) => {
      try {
        data.map( doc => {
          console.info( 'Saving...', doc );
          self.save( new ServiceIssues( { data: doc } ) ).subscribe(
            res => {
              console.info( 'saved, res: ', res );
            },
            error => {
              console.info( 'AbstractDetails.save error', error );
              reject( error );
            }
          );
        } );
        resolve();
      } catch( e ) {
        reject( e )
      }


    });
  }

}
