/**
 * Model
 */
import { ILogbook,  } from './logbook.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Logbook extends AbstractModel {
    constructor( obj: ILogbook = <ILogbook>{} ) {

        super( obj );

        // Default values
        //if ( typeof( this.data. ) === 'undefined' ) this.data. = ;

        // Fixed info
        this.type = 'logbook';
        this.app = 'bitacora';
        this.params.collection = 'logbook';
        this.params.type = 'logbook';

    }
}