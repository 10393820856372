import { Component, RendererStyleFlags2 } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '../../platform.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-signin',
    templateUrl: 'signin.component.html'
})
export class SigninComponent {

    public message: string;
    public signinMessage = '';

    constructor(
        public router: Router,
        public authService: AuthService,
        private platformService: PlatformService ) {
        this.setMessage();
    }

    ngOnInit() {
        // Show home button
        this.platformService.showHomeButton = true;
    }

    setMessage() {
        this.message = ( !this.authService.isSignedIn() ? 'Please sign in...' : 'Your are already signed in!' );
    }

    signIn( event: any, username: string, password: string ): void {
        const self = this;

        self.platformService.loaderVisible = true;

        event.preventDefault();

        self.authService.signIn( username, password ).subscribe(
            response => {
                self.platformService.loaderVisible = false;
                self.authService.saveSessionData( response );
                self.router.navigate( [ '/' ] );
            },
            errorResponse => {
                self.platformService.loaderVisible = false;
                if ( errorResponse.status === 0 ) {
                    self.platformService.addMessage( self.platformService.i18n( 'no_server_connection' ) );
                } else {
                    console.log( 'signin error', errorResponse );
                    self.signinMessage = self.platformService.i18n( errorResponse.error.message );
                }
            }
        );
    }

    activeServer(): string {
        return this.platformService.activeServer;
    }
    activeServerMessage(): string {
        return this.activeServer() === 'localhost' ? '(local server)' : '';
    }

    toggleServer(): void {
        this.platformService.setActiveServer( this.activeServer() === 'localhost' ? 'remoteServer' : 'localhost' );
    }

    cancel( event: any ) {
        event.preventDefault();
        this.router.navigate( [ '' ] );
    }

}
