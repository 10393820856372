/**
 * Model
 */
import { ITemplate,  } from './template.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Template extends AbstractModel {
    constructor( obj: ITemplate = <ITemplate>{} ) {

        super( obj );

        // Default values
        //if ( typeof( this.data. ) === 'undefined' ) this.data. = ;

        // Fixed info
        this.type = 'template';
        this.app = 'chatandbot';
        this.params.collection = 'template';
        this.params.type = 'template';

    }
}