import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';

import { AbstractDetailsComponent } from '../../../../abstract/app/feature/details/abstract-details.component';

import { PlatformService } from '../../../../platform.service';
import { BitacoraService } from '../../bitacora.service';
import { BeaconsService } from '../beacons.service';

import { Beacons } from '../beacons.model';

import { LogService } from '../../../../shared/log.service';
import { AuthService } from '../../../../auth/auth.service';
import { Helper } from '../../../../shared/helper.service';

@Component({
    selector: 'bitacora-beacons-details',
    templateUrl: 'beacons-details.component.html'
})
export class BeaconsDetailsComponent extends AbstractDetailsComponent {

    public doc: Beacons;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public platformService: PlatformService,
        public appService: BitacoraService,
        public service: BeaconsService,
        public location: Location,
        public logService: LogService,
        public helper: Helper,
        public authService: AuthService,
        public formBuilder: FormBuilder
    ) {
        super( route, router, platformService, appService, service, location, logService, helper, authService, formBuilder );
    }

}
