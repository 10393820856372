/**
 * Model
 */
import { IIssue,  } from './issue.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Issue extends AbstractModel {
    constructor( obj: IIssue = <IIssue>{} ) {

        super( obj );

        // Default values
        if ( typeof( this.data.id ) === 'undefined' ) this.data.id = 'no-id';
        if ( typeof( this.data.name ) === 'undefined' ) this.data.name = '';
        if ( typeof( this.data.rivescript ) === 'undefined' ) this.data.rivescript = {};
        if ( typeof( this.data.rivescript.triggers ) === 'undefined' ) this.data.rivescript.triggers = [];
        if ( typeof( this.data.rivescript.responses ) === 'undefined' ) this.data.rivescript.responses = [];
        if ( typeof( this.data.weight ) === 'undefined' ) this.data.weight = 0;
        if ( typeof( this.data.img ) === 'undefined' ) this.data.img = '';

        // Fixed info
        this.type = 'issue';
        this.app = 'chatandbot';
        this.params.collection = 'issue';
        this.params.type = 'issue';

    }
}
