import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';

import { Logbook } from '../logbook.model';
import { LogbookService } from '../logbook.service';

@Component({
    selector: 'bitacora-logbook-card',
    templateUrl: 'logbook-card.component.html'
})
export class LogbookCardComponent extends AbstractCardComponent {

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: LogbookService,
        public location: Location,
        public platformService: PlatformService, public logService: LogService ) {
        super( route, router, service, location, platformService, logService );
        this.setFeature( 'logbook' );
    }
}
