// Modules
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders }  from '@angular/core';

// Main component
import { ChatAndBotComponent } from './chatandbot.component';

// Feature components
import { GroupListComponent } from './group/list/group-list.component';
import { GroupDetailsComponent } from './group/details/group-details.component';

import { ItemListComponent } from './item/list/item-list.component';
import { ItemDetailsComponent } from './item/details/item-details.component';

import { IssueListComponent } from './issue/list/issue-list.component';
import { IssueDetailsComponent } from './issue/details/issue-details.component';

import { ServiceListComponent } from './service/list/service-list.component';
import { ServiceDetailsComponent } from './service/details/service-details.component';

import { ServiceIssuesListComponent } from './service-issues/list/service-issues-list.component';
import { ServiceIssuesDetailsComponent } from './service-issues/details/service-issues-details.component';

import { SynonymListComponent } from './synonym/list/synonym-list.component';
import { SynonymDetailsComponent } from './synonym/details/synonym-details.component';

import { TemplateListComponent } from './template/list/template-list.component';
import { TemplateDetailsComponent } from './template/details/template-details.component';

import { OrderListComponent } from './order/list/order-list.component';
import { OrderDetailsComponent } from './order/details/order-details.component';

import { ChatListComponent } from './chat/list/chat-list.component';
import { ChatDetailsComponent } from './chat/details/chat-details.component';

// Services
import { AuthGuardService } from '../../auth/auth-guard.service';

const chatandbotRoutes: Routes = [
    { path: 'chat-and-bot', component: ChatAndBotComponent, canActivate: [ AuthGuardService ] },
    { path: 'chat-and-bot', canActivate: [ AuthGuardService ],
        children: [
            // Feature routes
            { path: 'group', component: GroupListComponent, canActivate: [ AuthGuardService ] },
            { path: 'group/:_id', component: GroupDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'item', component: ItemListComponent, canActivate: [ AuthGuardService ] },
            { path: 'item/:_id', component: ItemDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'issue', component: IssueListComponent, canActivate: [ AuthGuardService ] },
            { path: 'issue/:_id', component: IssueDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'service', component: ServiceListComponent, canActivate: [ AuthGuardService ] },
            { path: 'service/:_id', component: ServiceDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'service-issues', component: ServiceIssuesListComponent, canActivate: [ AuthGuardService ] },
            { path: 'service-issues/:_id', component: ServiceIssuesDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'order', component: OrderListComponent, canActivate: [ AuthGuardService ] },
            { path: 'order/:_id', component: OrderDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'template', component: TemplateListComponent, canActivate: [ AuthGuardService ] },
            { path: 'template/:_id', component: TemplateDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'synonym', component: SynonymListComponent, canActivate: [ AuthGuardService ] },
            { path: 'synonym/:_id', component: SynonymDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'chat', component: ChatListComponent, canActivate: [ AuthGuardService ] },
            { path: 'chat/:_id', component: ChatDetailsComponent, canActivate: [ AuthGuardService ] }

        ]
    }
];
export const chatandbotRouting: ModuleWithProviders = RouterModule.forChild( chatandbotRoutes );

