import { BrowserModule } from '@angular/platform-browser';
import { NgModule  } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

// Components
import { TapappsComponent } from './tapapps.component';

// Feature components

// Routing
import { tapappsRouting } from './tapapps.routing';

// Main service
import { TapappsService } from './tapapps.service';

// Feature services


@NgModule({
    imports: [
        BrowserModule,
        SharedModule,
        tapappsRouting
    ],
    declarations: [
        // Feature declarations
        TapappsComponent
    ],
    providers: [
        TapappsService,
        // Feature providers
    ]
})
export class TapappsModule {}
