// Core modules
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

// Services
import { PlatformService } from '../../platform.service';
import { AbstractAppService } from './abstract-app.service';

import { IAppFeature } from '../../apps/xiroco/app/app-feature.interface';

import { BarChartComponent } from './d3/bar-chart/bar-chart.component';

@Component( {
    selector: 'xiroco-abstract-app-root',
    templateUrl: 'abstract-app.component.html'
} )
export class AbstractAppComponent implements OnInit, OnDestroy {

    public baseRoute = '';
    public appSettings: any;
    public servers: any[];
    public maxHorizontalFeatures = 4;
    public mainFeatures: any[] = [];
    public secondaryFeatures: any[] = [];
    public navbarTitle: string = '';

    public debugPanelCollapsed = true;

    // In this var store current URL
    public url: string = '';
    public urlName: string = '';

    // Array of feature services (for looping purposes)
    public featureServices: any[] = [];

    constructor(
        public router: Router,
        public platformService: PlatformService,
        public appService: AbstractAppService
    ) { }

    ngOnInit() {

        this.appService.incrementUsage();

        this.appSettings = this.appService.getSettings();
        this.navbarTitle = this.appService.getAppName();
        this.mainFeatures = this.appService.getAppMainFeatures();
        this.secondaryFeatures = this.appService.getAppSecondaryFeatures();

        // Store current URL for later (onDestroy) save it to history
        this.url = this.router.url;
        this.urlName = this.appService.getAppName();

        // Show bottom bar in this page
        this.platformService.showBottomNavBar = true;

        console.log( '**** inicializando app' );

    }

    ngOnDestroy() {
        // Save current URL to navigation history
        this.platformService.addRouteToHistory( this.urlName, this.url );
    }

    public i18n( value: string ): string {
        return this.platformService.i18n( value );
    }

    public getSettings(): any {
        return this.appService.getSettings();
    }

    public getColorClass(): string {
        return this.appService.getSettings().color;
    }

    public getFeatures(): any {
        return this.appService.getSettings().features;
    }

    goToRoute( route: String ) {
        this.router.navigate(
            [
                this.baseRoute || this.router.url,
                route
            ]
        );
    }

    getFeaturesCacheUsed(): any {
        return {
            "features": []
        };
    }

    getServers(): Object[] {
        return this.getSettings().servers;
    }

    getServer(): any {
        return this.getSettings().server;
    }

    getServerName(): string {
        return this.getServer().name;
    }

    setServer( event, i ): void {
        this.getSettings().server = this.getServers()[i];
    }

    downloadStats(): void {
        const self = this;
        self.platformService.loaderVisible = true;
        self.appService.downloadStats().subscribe(
            () => {
                self.platformService.loaderVisible = false;
            },
            errorResponse => {
                self.platformService.loaderVisible = false;

                if ( errorResponse.status === 0 ) {
                    self.platformService.addMessage( self.platformService.i18n( 'no_server_connection' ) );
                } else if ( errorResponse.status === 401 ) {
                    self.router.navigate(
                        [
                            'signin'
                        ]
                    );
                } else {
                    self.platformService.addMessage( errorResponse.message || 'Undefined error in AbstractListComponent.getDocs' );
                }
            }
        );
    }

    getStats(): string {
        return JSON.stringify( this.appService.getStats() );
    }

    /**
     * Shows or hide app settings button
     */
    showAppSettings(): boolean {
        return true;
    }

    getAppServer(): string {
        return this.appService.getServerURL();
    }

    getOpenApiDocsRoute(): string {
        return this.appService.getServerURL() + '/open-api/docs';
    }


    /**
 * Show home button in nav bar
 */
    showHomeButton(): boolean {
        return this.appService.showHomeButton;
    }

    /**
     * Show stats button
     */
    showStats(): boolean {
        return true;
    }

    showNav() {
        return this.appService.showNav;
    }

    isDefined(): boolean {
        return true;
    }

    getPath(): string {
        return this.appService.getPath();
    }

}
