import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Directive, Input, ViewChild } from '@angular/core';
import { D3Component } from '../d3/d3.component';
import { TreemapComponent } from '../d3/treemap/treemap.component';
import { AbstractAppService } from '../abstract-app.service';
import { PlatformService } from '../../../platform.service';

import {
  D3Service,
  D3,
  Axis,
  BrushBehavior,
  BrushSelection,
  D3BrushEvent,
  ScaleLinear,
  ScaleOrdinal,
  Selection,
  Transition
} from 'd3-ng2-service';

@Component({
  selector: 'app-storage-stats',
  templateUrl: '../d3/treemap/treemap.component.html',
  styleUrls: [ '../d3/treemap/treemap.component.css' ],
})
export class AppStorageStatsComponent extends TreemapComponent implements OnInit, OnDestroy {

  public dataSources: any[] = [
    {
      'text': 'app_storage_data'
    },
    {
      'text': 'demo_data'
    }
  ];

  constructor(
    public platformService: PlatformService,
    element: ElementRef,
    public ngZone: NgZone,
    d3Service: D3Service,
    public appService: AbstractAppService ) {
    super( platformService, element, ngZone, d3Service );
    this.dataSourceSelected = this.dataSources[ 0 ];
    this.translateLiterals();
  }


  setData( i: number ): Promise<any> {
    const self = this;

    const handleFeatures = features => {
      return features.rows.map( feature => {
        const handleCollectionName = ( name, collection ) => {
          return self.platformService.i18n( name ) + ' (' + collection.storageSize + ' Mb, ' + collection.count + ' docs)';
        };

        return {
          "name":
            self.platformService.i18n( feature.collection + '_in_plural' )
            || self.platformService.i18n( feature.collection )
            || feature.collection,
          "children": [
            {
              "name": handleCollectionName( 'data', feature.collStats.collection ),
              "value": feature.collStats.collection.count
            },
            {
              "name": handleCollectionName( 'data2import', feature.collStats.collection2import ),
              "value": feature.collStats.collection2import.storageSize
            }
          ]
        };
      });
    };

    return new Promise( ( resolve, reject ) => {
      try {
        switch ( i ) {
          case 0:
            const stats = self.appService.getStats();
            self.data = {
              "name": "Storage app stats",
              "children": handleFeatures( stats.features )
            };
            console.log( self.appService.getStats() );
            console.log( self.demoData );
            console.log( self.data );

            resolve();
            break;
          case 1:
            self.data = self.demoData;
            resolve();
            break;
        }
      } catch ( error ) {
        reject( error );
      }
    });
  }

  appStats2GraphData( appStats: Object ): Object {
    const res = {};
    return res;
  }


}

