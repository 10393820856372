// Core modules
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Xiroco modules
import { AbstractAppComponent } from '../../abstract/app/abstract-app.component';

// Services
import { PlatformService } from '../../platform.service';

import { PSConnectService } from './psconnect.service';

@Component({
    selector: 'psconnect-root',
    templateUrl: 'psconnect.component.html'
})
export class PSConnectComponent extends AbstractAppComponent {

    constructor(
        public router: Router,
        public platformService: PlatformService,
        public appService: PSConnectService
    ) {
        super( router, platformService, appService );
    }

    retrieveProducts() {
        var self = this;
       
                self.appService.retrieveProducts()
                    .subscribe(
                        res  => self.platformService.addMessage( 'Obtaining products has begun, you will be notified by email when the process is complete' ),
                        error =>  self.platformService.addMessage( <any>error )
                    );
            
    }


}
