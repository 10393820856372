// Core
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { OrderService } from '../order.service';
import { LogService } from '../../../../shared/log.service';

// Components and directives
import { AbstractListComponent } from '../../../../abstract/app/feature/list/abstract-list.component';

@Component({
    selector: 'chatandbot-order-list',
    templateUrl: 'order-list.component.html'
})
export class OrderListComponent extends AbstractListComponent {
    
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public service: OrderService,
        public logService: LogService
    ) {
        super( route, router, location, platformService, appService, service, logService );
    }


    downloadFromPrestashop() {
        var self = this;
       
            self.appService.getOrders()
              .subscribe(
                res  => self.platformService.addMessage( 'Obtaining orders has begun, you will be notified by email when the process is complete' ),
                error =>  self.platformService.addMessage( <any>error )
              );
         
      }
      
}
