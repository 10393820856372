/**
 * Model
 */

import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';
import { IApp, IAppData } from './app.interface';

export class App extends AbstractModel {

    constructor( obj: IApp = <IApp>{} ) {

        super( obj );

        // Default values
        if ( typeof( this.data.id ) === 'undefined' ) this.data.id = 'no-app-id';
        if ( typeof( this.data.name ) === 'undefined' ) this.data.name = 'no-app-name';
        if ( typeof( this.data.status ) === 'undefined' ) this.data.status = 'enabled';
        if ( typeof( this.data.cssPrefix ) === 'undefined' ) this.data.cssPrefix = '';
        if ( typeof( this.data.color ) === 'undefined' ) this.data.color = 'color1';
        if ( typeof( this.data.order ) === 'undefined' ) this.data.order = 1;
        if ( typeof( this.data.path ) === 'undefined' ) this.data.path = '';
        if ( typeof( this.data.serverURL ) === 'undefined' ) this.data.serverURL = '';
        if ( typeof( this.data.features ) === 'undefined' ) this.data.features = [];

        // Fixed info
        this.type = 'app';
        this.app = 'xiroco';
        this.params.collection = 'app';
        this.params.type = 'app';
    }
}