import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';

import { Order } from '../order.model';
import { OrderService } from '../order.service';

@Component({
    selector: 'order-card',
    templateUrl: 'order-card.component.html'
})
export class OrderCardComponent extends AbstractCardComponent {

    @Input() feature: string;
    @Input() _id: string;
    public doc: Order = new Order();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: OrderService,
        public location: Location,
        public platformService: PlatformService, public logService: LogService
    ) {
        super( route, router, service, location, platformService, logService );
        this.setFeature( 'order' );
    }
}
