import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlatformService } from '../../platform.service';
import { AbstractAppService } from '../../abstract/app/abstract-app.service';
import { Observable } from "rxjs";
@Injectable()
export class ChatAndBotService extends AbstractAppService {

    constructor(
        public platformService: PlatformService,
        public http: HttpClient
    ) {
        super( platformService, 'chatandbot', http );
    }

    getAbstract( path: string ): Observable<any> {
        let headers = this.getHeaders();
        let url = this.getURLWithToken( 'get/' + path );
        return this.http.post( url, { body: '', headers: headers } )
            .map( this.handleResponse )
            .catch( this.handleError );
      }

    getGroups(): Observable<any> {
        return this.getAbstract( 'groups' );
    }

    getItems(): Observable<any> {
        return this.getAbstract( 'items' );
    }

    getServices(): Observable<any> {
        return this.getAbstract( 'services' );
    }

    getOrders(): Observable<any> {
        return this.getAbstract( 'orders' );
    }

    

}
