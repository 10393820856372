import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service'; 
import { LogService } from '../../../../shared/log.service';

import { Template } from '../template.model';
import { TemplateService } from '../template.service';

@Component( {
  selector: 'template-card',
  templateUrl: 'template-card.component.html'
} )
export class TemplateCardComponent extends AbstractCardComponent {

  @Input( 'feature' ) feature: string;
  @Input( '_id' ) _id: string;
  public doc: Template = new Template();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: TemplateService,
    public location: Location,
    public platformService: PlatformService, 
    public logService: LogService ) {
    super( route, router, service, location, platformService, logService );
    this.setFeature( 'template' );
  }
}
