import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { map } from 'rxjs/operators';

import { AbstractService } from '../../../abstract/app/feature/abstract.service';

import { PlatformService } from '../../../platform.service';
import { XirocoService } from '../xiroco.service';
import { Company } from '../company/company.model';
import { LogService } from '../../../shared/log.service';



@Injectable()
export class CompanyService extends AbstractService {

    constructor(
        public http: HttpClient,
        public platformService: PlatformService,
        public appService: XirocoService,
        public logService: LogService
        
    ) {
        super( 'company', http, platformService, appService, logService );
    }

    public getNewDoc(): any {
        return new Company();
    }

}
