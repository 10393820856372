// Core modules
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Xiroco modules
import { AbstractAppComponent } from '../../abstract/app/abstract-app.component';

// Services
import { PlatformService } from '../../platform.service';

import { CardsAppService } from './cards-app.service';
import { CardsService } from './cards/cards.service';
import { TemplateService } from './template/template.service';
import { TriggerService } from './trigger/trigger.service';


@Component({
    selector: 'app-cards-root',
    templateUrl: 'cards.component.html'
})
export class CardsComponent extends AbstractAppComponent {

    constructor(
        public router: Router,
        public platformService: PlatformService,
        public appService: CardsAppService, 
        public cardsService: CardsService,
        public templateService: TemplateService,
        public triggerService: TriggerService
    ) {
        super( router, platformService, appService );
    }
}
