// Modules
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders }  from '@angular/core';

// Main component
import { XirocoComponent } from './xiroco.component';

// Feature components
import { UserListComponent } from './user/list/user-list.component';
import { UserDetailsComponent } from './user/details/user-details.component';

import { AppListComponent } from './app/list/app-list.component';
import { AppDetailsComponent } from './app/details/app-details.component';
import { CompanyListComponent } from './company/list/company-list.component';
import { CompanyDetailsComponent } from './company/details/company-details.component';

// Services
import { AuthGuardService } from '../../auth/auth-guard.service';

//TODO: Hay que revisar esto, si me traigo aquí las rutas de xiroco (que están en platform.routing) me da error de duplicación del módulo ????

const xirocoRoutes: Routes = [
    { path: 'xiroco', component: XirocoComponent, canActivate: [ AuthGuardService ] },
    { path: 'xiroco', canActivate: [ AuthGuardService ],
        children: [
            // Feature routes
			{ path: 'user', component: UserListComponent, canActivate: [ AuthGuardService ] },
			{ path: 'user/:_id', component: UserDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'app', component: AppListComponent, canActivate: [ AuthGuardService ] },
            { path: 'app/:_id', component: AppDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'company', component: CompanyListComponent, canActivate: [ AuthGuardService ] },
            { path: 'company/:_id', component: CompanyDetailsComponent, canActivate: [ AuthGuardService ] }
        ]
    }
];

//export const xirocoRouting = RouterModule.forChild( xirocoRoutes );
export const xirocoRouting: ModuleWithProviders = RouterModule.forChild( xirocoRoutes );