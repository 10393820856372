import { Injectable } from '@angular/core';
import { Response, } from '@angular/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { AbstractModel } from './abstract.model';
import { LogService } from '../../../shared/log.service';

import { PlatformService } from '../../../platform.service';
import { AbstractAppService } from '../abstract-app.service';
import { IAppFeature } from '../../../apps/xiroco/app/app-feature.interface';
import { ISummaryDoc } from './abstract.interface';
import { IQueryParams } from './query-params.interface';
import * as moment from 'moment';
import * as _ from 'lodash';

// Get Query Options params passed to getDocs method

@Injectable()
export class AbstractService {

    public featureForm: any = {};
    public cache: AbstractModel[] = [];

    // Override this in child clases in order to store cache data
    // locally and have it accesible offline
    public localStorage = true;

    // This method is set to true when a import process is running in the backoffice
    public importProcessActive = false;

    // Number of docs to get from server when list
    public DEFAULT_GET_DOCS_LIMIT = 20;

    // When get simplified group objects, for select components this is the limit
    public SUMMARY_DOCS_LIMIT = 200;

    // Tags selected by click in cards buttons are collected here
    public tagsClicked: string[] = [];

    constructor(
        public feature: string = 'no-feature-name',
        public http: HttpClient,
        public platformService: PlatformService,
        public appService: AbstractAppService,
        public logService: LogService

    ) {
        this.logService.log( ':::::: AbstractService.' + this.feature, 'Constructor ok' );

        // Restore data from local storage, if it is saved
        this.loadCacheFromDisk();

        // Register this service in parent app service
        this.appService.registerFeatureService( this );

    }


    /**
     * Set the feature value (second route in url path)
     * @param value Value to set
     */
    public setFeature( value: string ): AbstractService {
        this.feature = value;
        return this;
    }

    /**
     * Get feature name
     */
    public getFeature(): string {
        return this.feature;
    }

    /**
     * Return headers configuration from de app service
     */
    public getHeaders() {
        return this.appService.getHeaders();
    }

    public getId(): string {
        return this.feature;
    }

    /**
     * Store documents in memory cache
     * @param docs Documents
     */
    public setCache( docs: any ) {
        this.cache = docs;
        this.saveCacheToDisk();
        return docs;
    }

    /**
     * Update or create documents in memory cache
     * @param docs Documents
     */
    public updateCache( doc: any ) {
        const self = this;
        let docInCache = this.getCache( doc._id );

        if ( docInCache ) {
            docInCache = doc;
        } else {
            self.cache.push( doc );
        }

        this.saveCacheToDisk();
        return doc;
    }

    public getSingularName(): string {
        return this.platformService.i18n( this.getSettings().singularName || this.getSettings().id );
    }

    public getPluralName(): string {
        return this.platformService.i18n( this.getSettings().pluralName || this.getSettings().singularName || this.getSettings().id );
    }

    /**
     * Get all documents of one documents by its database id from local cache
     * @param _id Database id
     */
    public getCache( _id?: string ): any {
        return !_id ? this.cache : this.cache.find( obj => obj._id === _id );
    }

    /**
     * Remove document by its database id from local cache
     * @param _id Database id
     */
    public removeCache( _id?: string ): any {
        let found = false;
        for ( let i = this.cache.length - 1; i >= 0; i--) {
            if ( this.cache[ i ]._id === _id ) {
                this.cache.splice( i, 1 );
                found = true;
            }
        }
        return found;
    }

    /**
     * Get the app settings from app service
     */
    public getApp(): string {
        return this.appService.getApp();
    }

    /**
     * Returns an empty row to initilize in some components (i.e. abstract-details)
     */
    public initDoc(): AbstractModel {
        return new AbstractModel( {} );
    }
    /**
     * This method returns an empty doc, it is called each details component it is init
     * @param obj Object data
     */
    public getNewDoc( obj: any = {} ) {
        return new AbstractModel( obj );
    }

    /**
     * Returns the app token
     */
    public getToken(): string {
        return this.appService.getToken();
    }

    /**
     * Returns the App token param
     */
    public getTokenParam() {
        return this.appService.getTokenParam();
    }

    public getPath(): string {
        return this.getSettings().path || this.feature;
    }

    public getAppId(): string {
        return this.getApp();
    }
    public getConfigurationParametersPath(): string {
        return this.getAppId() + '.' + this.getId();
    }

    /**
     * Returns the URL of the App
     */
    public getServerURL() {
        return this.appService.getServerURL() + '/' + this.getPath();
    }

    /**
     * Returns the full URL with token param
     * @param feature Feature name
     * @param addPath Path to add
     */
    public getURLWithToken( addPath?: string ): string {
        let tmp = this.getServerURL();

        if ( addPath ) {
            tmp += '/' + addPath;
        }
        tmp += this.getTokenParam();
        return tmp;
    }

    /**
     * Manage local storage for cache syste
     * Read when load app and store when get docs
     * in order to have a data copy when offline
     */
    localStorageItemName(): string {
        return this.platformService.localStorageCachePrefix + this.getFeature();
    }

    // Save cache to local storage
    saveCacheToDisk(): void {
        if ( this.localStorage ) {
        localStorage.setItem( this.localStorageItemName(), JSON.stringify( this.cache ) );
        }
    }

    /**
     * Retrieve cache from local storage
     */
    loadCacheFromDisk(): void {
        console.log( 'Cargamos cache del disco' );
        const storedData = localStorage.getItem( this.localStorageItemName() );
        if ( storedData ) {
            try {
            this.cache = JSON.parse( storedData );
            } catch ( err ) {
                console.error( err );
            }
        }
    }

    /**
     * Re
     */
    getLocalStorageSize(): Object {
    const self = this;

    const KB = () => {
        if ( localStorage[ self.localStorageItemName() ] ) {
            return ( ( localStorage[ self.localStorageItemName() ].length + self.localStorageItemName().length ) * 2 );
        } else {
            return 0;
        }
    };

    const toString = () => {
        return ( KB() / 1024 ).toFixed( 1 ) + ' KB';
    };

    return {
        KB: KB,
        toString: toString
    };

}

    /**
     * Method that filter the doc before save it when it is retrieved from an http request,
     * convert a JSON object in an AbstractModel object
     * Must be override in inherited classes
     * @param obj Object
     */
    public handledDoc( obj: any ): AbstractModel {
        return obj;
    }

    /**
     * Este método devuelve la URL de acceso a los datos 
     */
    public getDocsURL( limit: number, offset: number, params: IQueryParams  ): string {
        const self = this;
        // Set default limit number of docs to retrieve, if it is not set
        let url = this.getURLWithToken( null );

        url += '&params=' + encodeURIComponent( JSON.stringify( params ) );
        if ( limit ) {
            url += '&limit=' + limit;
        }
        if ( offset ) {
            url += '&offset=' + offset;
        }
        if ( params.extraParams ) {
            url += params.extraParams;
        }

        return url;
    }

    /**
     * Get docs from a remote server
     * @feature URL path to remote server
     */
    public getDocs( limit, offset, params: IQueryParams ): Observable<any[]> {

        const self = this;

        const httpOptions = {
            headers: this.getHeaders()
        };

        return self.http.get( self.getDocsURL( limit, offset, params ), httpOptions ).map( ( data: any[] ) => {
            return self.setCache( data[ 'rows' ].map( ( obj: any ) => {
                return self.handledDoc( obj );
            } ) );
        } );
    }

    /**
     * Return a unique doc from remote server
     * @param _id Database unique id
     */
    public getDoc( _id: string ): Observable<any> {
        const self = this;

        const httpOptions = {
            headers: this.getHeaders()
        };

        return self.http.get( this.getURLWithToken( _id ), httpOptions ).map( ( data: any[] ) => {
            return self.updateCache( data[ 'rows' ].map( ( obj: any ) => {
                return self.handledDoc( obj );
            } ) );
        } );
    }

    /**
     * This method handles the error message to adapt it
     */
    public handleError( errorResponse: HttpErrorResponse ) {
        let error:any = {};
        if ( errorResponse.error instanceof ErrorEvent ) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', errorResponse.error.message );
          error = errorResponse.error;

        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          error.status = errorResponse.status;
          error.message = errorResponse.error;
          console.error( error) ;
        }
        // return an observable with a user-facing error message
        return throwError( error );
    }

    /**
     * Returns an array of id/name/_id objects from
     * groups in cache, useful for select components
     */
    public getSummaryDocs(): Promise<ISummaryDoc[]> {
        const self = this;
        return new Promise( ( resolve, reject ) => {

            const doResolve = () => {
                resolve( self.cache.map( group => {
                    return {
                        "id": group.data.id || 'no-group-id',
                        "name": group.data.name || 'no-name',
                        "_id": group._id
                    };
                }));
            };

            // If we have not cache, lets load it
            if ( !self.cache ) {
                self.getDocs( self.DEFAULT_GET_DOCS_LIMIT, 0,
                    {
                    filter: {},
                    options: { 
                        limit: self.SUMMARY_DOCS_LIMIT 
                    },
                    projection: {}
                } ).subscribe(
                        docs => {
                            doResolve();
                        },
                        error =>  {
                            self.logService.log( 'AbstractListComponent', 'Error in getSummaryDocs', true, error );
                            self.platformService.addMessage( error.message || 'Error in getSummaryDocs' );
                            reject( error );
                        });
            } else {
                // If we have cache, just return it
                doResolve();
            }
        });

    }

    /**
     * return a id/name/_id object from its id
     * or return an array if
     */
    public getSummaryDoc( id: number | number[] ): Promise<ISummaryDoc | ISummaryDoc[]> {
        const self = this;
        return new Promise( ( resolve, reject ) => {
            self.getSummaryDocs().then( ( docsSummary: ISummaryDoc[] ) => {
                const result = docsSummary.filter( docSummary => {

                    if ( Array.isArray( id ) ) {
                        return id.indexOf( docSummary.id ) > -1;
                    } else {
                        return docSummary.id === id;
                    }
                });

                // If ask for few ids... lets return an array
                // else, if only ask fo a doc, lets return an object
                resolve(
                    ( Array.isArray( id ) ? result : result[ 0 ] )
                );
            }).catch( ( err: any ) => reject( err ) );
        });
    }

    /**
     * Check if doc has database id so it is a new one or an existing one
     * @param doc Doc to analyze
     */
    public hasDatabaseId( doc: any ): boolean {
        return !doc._id || doc._id === '' || doc._id === null ? false : true;
    }

    /**
     * Performs an update or insert action depends on database id
     * @param doc Document to save
     */
    public save( doc: AbstractModel ): Observable<void | {}> {
        const self = this;
        if ( !self.hasDatabaseId( doc ) ) {

          // Delete the database id to prevent null or blank values
          delete doc._id;
          return self.insertDoc( doc ).map( res => {
                this.logService.log( 'AbstractService', 'updateDoc ok', false, res );
            } ).catch( this.handleError );

          // If we have a valid database id, then perform a document updating
        } else {
          return self.updateDoc( doc ).map( res => {
                this.logService.log( 'AbstractService', 'updateDoc ok', false, res );
            } ).catch( this.handleError );
        }


    }

    /**
     * Put a new doc to the RestAPI Server
     * @param data Data to update
     */
    public updateDoc( data: AbstractModel ): Observable<void | {}> {
        const self = this;

        return self.http.put(
            self.getURLWithToken( data._id ),
            data,
            {
                headers: self.getHeaders()
            }
        ).map( res => {
                self.logService.log( 'AbstractService', 'updateDoc ok', false, res );

                // Lets search in cache and update it or add it
                const indexInCache = self.cache.findIndex( doc => doc._id === data._id );
                if ( indexInCache !== -1 ) {
                    self.cache[ indexInCache ] = data;
                } else {
                    self.cache.push( data );
                }
            } )
            .catch( this.handleError );
    }

    /**
     * Post a new doc to the RestAPI Server
     * @param data Data to insert
     */
    public insertDoc( data: AbstractModel ): Observable<AbstractModel | {}> {
        const self = this;

        if ( typeof( data._id ) !== 'undefined' ) {
            console.error( 'Database id data object, must not exist');
            return null;
        }

        return self.http.post(
            self.getURLWithToken(),
            data,
            {
                headers: self.getHeaders()
            }
        ).map( res => {
                data._id = res[ '_id' ];
                self.logService.log( 'AbstractService', 'insertDoc ok', false, res );
                self.cache.push( res[ 'rows' ][ 0 ] );
                return data;
            }
        ).catch( self.handleError );
    }

    /**
     * Delete a doc in remote server
     * @param _id Database unique id
     */
    public deleteDoc( _id: string ): Promise<any> {
        const self = this;
        return new Promise( ( resolve, reject ) => {
            self.http.delete(
                this.getURLWithToken( _id ),
                {
                    headers: this.getHeaders()
                }
            ).subscribe(
                response => {
                    self.removeCache( _id );
                    resolve( response );
                },
                error => {
                  reject( error );
                }
              );
        });

    }

    /**
     * Return this feature settings
     */
    public getSettings() {
        return this.appService.settings.features[ this.feature ];
    }


    /**
     * Return all fields objects from the form structure
     */

    getFields(): any[] {
        const tmp: any[] = [];
        // TOOD: Create IGroup interface
        this.getSettings().form.groups.forEach( ( group: any ) => {
          // TODO: Create IRow interface
            group.rows.forEach( ( row: any ) => {
                row.fields.forEach( ( field: any ) => {
                    tmp.push( field );
                });
            });
        } );
        return tmp;
    }


    getStats(): any {
        return this.appService.getFeatureStats( this.getFeature() );
    }

    /**
     * This method returns documents2import collection stats
     */
    getDocumentsToImport(): boolean {
        return this.getStats() && this.getStats().documents2import ? this.getStats().documents2import : null;
    }

    /**
     * This method check if there are documents waiting to be imported in xxxxx2import collection
     */
    theAreDocumentsToImport(): boolean {
        return this.getDocumentsToImport() && this.getStats()[ 'importDocumentsPending' ] ? true : false;
    }

    /**
     * Returns the database collection name
     */
    getDBCollectionName(): string {
        return this.getFeature();
    }

    /**
     * This method call the import process in the backoffice
     */
    importDocuments(): Observable<AbstractModel | {}>  {
        const self = this;
        if ( !self.importProcessActive ) {
            return self.http.post(
                self.getURLWithToken( 'import-documents'),
                {},
                {
                    headers: self.getHeaders()
                }
            ).map( res => {
                    self.logService.log( 'AbstractService', 'Import document in queue', false, res );
                    return res;
                }
            ).catch( self.handleError );
        }
    }

    /**
     * This method get Settings of this feature, app settings or platform settings
     * @param path Path (dot notation) to settings element
     * @param defaultValue A default value to return if none exits
     * @param onlyForUser A param only for user level, if not exists create it
     */
    getSettingsElement( path: string, defaultValue?: any, onlyForUser: boolean = false ): any {
        
        if ( onlyForUser ) {
            const value = _.get( 
                this.platformService.getUserCustomSettings(), 
                this.getCustomSettingsPath() + '.' + path 
            );
            if ( value ) { 
                return value;
            } else {
                
                _.set( 
                    this.platformService.getUserCustomSettings(), 
                    this.getCustomSettingsPath() + '.' + path,
                    defaultValue 
                );

                return _.get( 
                    this.platformService.getUserCustomSettings(), 
                    this.getCustomSettingsPath() + '.' + path 
                );
            }
        } else {
             return _.get( 
                this.platformService.getUserCustomSettings(), 
                this.getCustomSettingsPath() + '.' + path,
                
                _.get( 
                    this.appService.getSettings(), 
                    'features.' + this.getId() + '.' + path,

                    _.get( 
                        this.appService.getSettings(), 
                        path,

                        _.get( 
                            this.platformService.getSettings(), 
                            'app.feature.' + path,
                            defaultValue )
                    )
                )
            );
        }
     
    }

    setSettingsElement( id: string, value: any ): any {
        _.set( this.platformService.getUserCustomSettings(), this.getCustomSettingsPath() + '.' + id, value );    
        this.platformService.saveSettingsLocally();
    }

    getUsage(): number {
        return this.getSettingsElement( 'usage' ) || 0;
    }

    setUsage( value: number ): void {
        this.setSettingsElement( 'usage', value );
    } 

    /**
     * This method increment the usage of this feature and this app
     */
    incrementUsage(): void {
        this.setUsage( this.getUsage() + 1 );
        this.appService.incrementUsage();
    }

    getCustomSettingsPath(): string {
        return this.getApp() + '.' + this.getId();
    }

    /**
     * This method is fired when a card tag button is selected
     * @param tag Tag name
     */
    tagClick( tag: string ): void {
      this.tagsClicked.push( tag );
      console.log( this.tagsClicked );
    }

}
