import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';

import { Cards } from '../cards.model';
import { CardsService } from '../cards.service';

@Component({
    selector: 'cards-cards-card',
    templateUrl: 'cards-card.component.html'
})
export class CardsCardComponent extends AbstractCardComponent {

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: CardsService,
        public location: Location,
        public platformService: PlatformService, public logService: LogService 
    ) {
        super( route, router, service, location, platformService, logService );
        this.setFeature( 'card' );
    }
}
