import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Http, Response } from '@angular/http';

import { IAppData } from './apps/xiroco/app/app.interface';
import { IUser } from './apps/xiroco/user/user.interface';

import { AppService } from './apps/xiroco/app/app.service';
import { CardsAppService } from './apps/cards/cards-app.service';
import { XirocoService } from './apps/xiroco/xiroco.service';
import { TapappsService } from './apps/tapapps/tapapps.service';
import { ChatAndBotService } from './apps/chatandbot/chatandbot.service';
import { TapappsPOSService } from './apps/tapapps-pos/tapapps-pos.service';
import { BitacoraService } from './apps/bitacora/bitacora.service';

import i18nData from './i18n/i18n.json';
import * as moment from 'moment';

@Injectable()
export class PlatformService {


  // Info messages level
  public INFO_MESSAGE: number = 1;

  // Local storage cache prefix
  public localStorageCachePrefix = 'cache_';
  public settings: any = {};
  public firstLoadPlatformSettings: any = {
    "apps": {
      "xiroco": {}
    }
  };

  public apps: any[] = [];

  public language = 'es';
  public i18nData = {};

  public user: IUser = {
    data: {
      name: '',
      appsAssigned: [],
      disableGPS: false,
      customSettings: {}
    }
  };

  // Show Sign in / user account or Sign Out top right nav bar button
  public showSignIn = true;

  // Show or hide home button in top lef navbar
  public showHomeButton = false;

  // Bottom nav bar default value
  public showBottomNavBar = false;

  // Navigation routes history
  public routeHistory: any[] = [];

  private DEFAULT_NUMBER_OF_PRIMARY_FEATURES: number = 4;

  // Default max cache size (local storate)
  private MAX_CACHE_SIZE = 1024;

  public xirocoServers: any = {
    "localhost": "http://127.0.0.1:3005/api/1/xiroco",
    "remoteServer": "https://xiroco-server.herokuapp.com/api/1/xiroco"
  };

  public activeServer: string;
  public url: string;

  public navBar: any = {
    "left": [],
    "right": []
  };

  // Show or hide home platform navbar
  public showNav: boolean = true;

  public loaderVisible: boolean = false;

  private messages: string[] = [];

  // Here it is stored geo position
  public position: any = {};
  public geoPositionId: number;

  constructor(
    private http: Http,
    private router: Router
  ) {

    moment.locale( this.language );

    // Retrieve local platform settings
    const platformSettings = localStorage.getItem( 'xiroco-platform-settings' );
    if ( platformSettings ) {
      this.settings = JSON.parse( platformSettings );
    } else {
      this.settings = this.firstLoadPlatformSettings;
      console.warn( 'Platform service constructor but no local settings' );
    }

    const userSettings = localStorage.getItem( 'xiroco-user-settings' );
    if ( userSettings ) {
      this.user = JSON.parse( userSettings );
    } else {
      console.warn( 'Platform service constructor but no local settings' );
    }

    this.setActiveServer( localStorage.getItem( 'xiroco-server' ) || 'remoteServer' );

    this.i18nData = i18nData;

  }

  i18n( key: string ): string {
    if ( key ) {
      return this.i18nData[key] ? this.i18nData[key][this.language] : 'No translation for <' + key + '>';
    } else {
      return null;
    }
  }

  addRouteToHistory( name: string, route: string ) {
    const elementToAdd = {
      "name": name,
      "route": route
    };

    const positionIfAlreadyExists = this.routeHistory.map( e => e.name ).indexOf( elementToAdd.name );
    if ( positionIfAlreadyExists > -1 ) {
      // Delete element if already exists and add new one
      this.routeHistory.splice( 1,
        positionIfAlreadyExists,
        elementToAdd
      );
    } else {
      this.routeHistory.push( elementToAdd );
    }


  }

  setActiveServer( serverName: string ): void {
    // Set indicated server, or 0 as default
    if ( !this.xirocoServers[serverName] ) {
      serverName = 'localhost';
    }
    this.url = this.xirocoServers[serverName];
    this.activeServer = serverName;
    localStorage.setItem( 'xiroco-server', serverName );
  }

	/**
	 * Shows a popup message
	 */
  addMessage( message: string, level: number = 9 ) {
    message = message || 'Undefined error';
    if ( this.messages[this.messages.length - 1] !== message ) {
      this.messages.push( message );
    }
  }

	/**
	 * This method show an internal error message
	 */
  showError( message: string ) {
    message = message || 'Undefined error';
    if ( this.messages[this.messages.length - 1] !== message ) {
      this.messages.push( message );
    }
  }

  getMessages() {
    return this.messages;
  }
  removeMessage( message: string ) {
    this.messages.splice( this.messages.indexOf( message ), 1 );
  }

	/**
	 * Get server URL saved in settings
	 * @returns string
	 */
  public getServerURL(): string {
    return this.url;
  }

	/**
	 * Set the server URL and save it to localstorage
	 * @param url
	 */
  public setServerURL( url: string ): void {
    this.getSettings().serverURL = url;
    this.savePlatformSettings();
  }

	/**
	 * Returns apps array (all apps)
	 * @returns {Array|any[]|any}
	 */
  getApps(): any[] {
    return this.getSettings().apps || [];
  }

	/**
	 * Returns relavant user data
	 * @returns {Array|any[]|any}
	 */
  getUserData() {
    return this.user && this.user.data ? this.user.data : {};
  }

	/**
	 * Get the whole settings object
	 * @returns {any}
	 */
  getSettings() {
    return this.settings;
  }

	/**
	 * Return settings of a passed app id
	 * @param id
	 * @returns {T}
	 */
  getAppSettings( id: string ) {
    return this.getSettings().apps[id];
  }

	/**
	 * Returns all apps services array
	 * @returns {AppService}
	 */
  getAppsServices() {
    return this.apps;
  }

	/**
	 * Returns an app service
	 * @returns {AppService}
	 */
  getAppService( appId: string ) {
    return this.getAppsServices().filter( ( app: AppService ) => app.getApp() === appId )[0];
  }

	/**
	 * Returns all app features sorted by usage
	 * @param appId 
	 */
  getAppAllFeaturesSortedByUsage( appId: string ) {
    const appService = this.getAppService( appId );
    if ( appService ) {
      return appService.getAppAllFeaturesSortedByUsage();
    } else {
      console.error( 'No appService in platformService > getAppAllFeaturesSortedByUsage' );
      return null;
    }

  }

	/**
	 * Return de amount of features highlihted
	 * @param appId 
	 * @param place 
	 */
  getNumberOfPrimaryFeatures( appId: string, place: string ): number {
    const appSettings = this.getAppSettings( appId );
    if ( !appSettings ) {
      console.error( 'No App Settings in platformService > getNumberOfPrimaryFeatures for', appId );
      return 0;
    } else {
      return appSettings.numberOfPrimaryFeatures ?
        ( this.getAppSettings( appId ).numberOfPrimaryFeatures[place] || this.DEFAULT_NUMBER_OF_PRIMARY_FEATURES )
        : this.DEFAULT_NUMBER_OF_PRIMARY_FEATURES;
    }
  }


	/**
	 * Returns an app more often used features
	 * @param appId 
	 */
  getAppMainFeatures( appId: string, place: string ) {
    if ( appId ) {
      const allFeatures = this.getAppAllFeaturesSortedByUsage( appId );
      const howMany = this.getNumberOfPrimaryFeatures( appId, place );
      const result = allFeatures.slice( 0, howMany );
      return result;
    } else {
      console.error( 'No appId in platformService > getAppMainFeatures' );
      return [];
    }
  }

	/**
	 * Returns an app less often used features
	 * @param appId 
	 */
  getAppSecondaryFeatures( appId: string, place: string ) {
    if ( appId ) {
      const allFeatures = this.getAppAllFeaturesSortedByUsage( appId );
      if ( allFeatures && allFeatures.length ) {
        const result = allFeatures.slice( this.getNumberOfPrimaryFeatures( appId, place ) );
        return result;
      } else {
        console.error( 'No allFeatures in platformService > getAppSecondaryFeatures' );
        return [];
      }
    } else {
      console.error( 'No appId in platformService > getAppSecondaryFeatures' );
      return [];
    }
  }

	/**
	 * Set apps and / or fields in settings object, nor other objects.
	 * @param value
	 */
  setPlatformSettings( value: any ) {

    this.settings = value;
    this.savePlatformSettings();
  }

	/**
	 * Set user specific settings
	 * @param value
	 */
  setUser( value: IUser ) {
    this.user = value;
    this.saveUserSettings();
  }

	/**
	 * Get the user settings object
	 * @returns {any}
	 */
  getUser(): IUser {
    return this.user;
  }



	/**
	 * Save user settings in local storage
	 */
  saveUserSettings(): void {
    localStorage.setItem( 'xiroco-user-settings', JSON.stringify( this.user ) );
  }

	/**
	 * Save settings in local storage
	 */
  savePlatformSettings(): void {
    localStorage.setItem( 'xiroco-platform-settings', JSON.stringify( this.settings ) );
  }

	/**
	 * Save all settings, user and platform settings locally
	 */
  saveSettingsLocally(): void {
    this.saveUserSettings();
    this.savePlatformSettings();
  }

  getUserCustomSettings(): object {
    return this.user.data.customSettings;
  }

  // TODO: Poner el menú en la derecha si es un usuario diestro.
  getRightHand(): boolean {
    return this.getSettings().rightHand;
  }

	/**
	 * Return the default all apps max cache size
	 */
  getMaxCacheSize(): number {
    return this.MAX_CACHE_SIZE;
  }


  // Geoposition 
  setGPSStatus( value: boolean ) {
    const self = this;
    if ( value != self.user.data.disableGPS ) {
      this.user.data.disableGPS = value || false;
      console.log( 'setGPSStatus stop watching...' );
      if ( this.user.data.disableGPS && self.geoPositionId ) {
        navigator.geolocation.clearWatch( self.geoPositionId );
        console.log( 'setGPSStatus geolocation watch disabled' );
      } else {
        console.log( 'setGPSStatus init...' );
        self.initGeoposition();
      }
    }

  }

	/**
	 * Initializa GEOPosition watching
	 */
  initGeoposition() {
    const self = this;
    console.log( 'Initializing Geoposition....' );
    if ( navigator.geolocation ) {
      self.geoPositionId = navigator.geolocation.watchPosition( position => {
        self.position = position;
        console.log( 'initGeoposition Ok' );
      } );
    } else {
      self.user.data.disableGPS = true;
      console.log( 'GPS Disabled' );
    }
  }

  /**
	 * Remove all local storega saved cache data
	 */
  removeLocalStorageData() {
    // Let's preserve main server
    const tmp = localStorage.getItem( 'xiroco-server' );
    localStorage.clear();
    localStorage.setItem( 'xiroco-server', tmp );
  }


  /**
	 * This method move to another router tracing it
	 * @param appRoute 
	 * @param featureRoute 
	 */
  goToRoute( router: Router, routes: string[] ) {

    if ( routes ) {
      this.routeHistory.push( routes );
      router.navigate( routes );
    }

  }

  /** 
	 * This method handle HTTP request errors
	 */
  HTTPErrorHandle( errorResponse ) {
    const self = this;
    switch ( errorResponse.status ) {
      case 0: // No connection
        self.addMessage( self.i18n( 'no_server_connection' ) );
        break;
      case 401: // Not authorized, redirect to sign in
        self.addMessage( self.i18n( 'please_sign_in' ) );
        setTimeout( () => {
          self.router.navigate(
            [
              'signin'
            ]
          );
        }, 1000 );
        break;
      default:
        self.addMessage( self.i18n( errorResponse.error.message || 'undefined_error' ) );

    }
  }

}
