/**
 * This component is an adaptation of the "Brush & Zoom II" Example provided by
 * Mike Bostock at https://bl.ocks.org/mbostock/f48fcdb929a620ed97877e4678ab15e6
 */

import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Directive, Input, ViewChild} from '@angular/core';

import { D3Component } from '../d3.component';

import { PlatformService } from '../../../../platform.service';

import {
  D3Service,
  D3,
  Axis,
  BrushBehavior,
  BrushSelection,
  D3BrushEvent,
  ScaleLinear,
  ScaleOrdinal,
  Selection,
  Transition
} from 'd3-ng2-service';

@Component({
  selector: 'app-calendar-chart',
  templateUrl: './calendar-chart.component.html',
  styleUrls: ['./calendar-chart.component.css'],
})
export class CalendarChartComponent extends D3Component implements OnInit, OnDestroy {

  public divId = 'calendar-chart-container';
  public demoData: any[] =  [
    { "date": "Wed Jan 01 2014 00:00:00 GMT+0100 (CET)", "value": 0.505003142170608 },
    { "date": "Sat Jan 11 2014 00:00:00 GMT+0100 (CET)", "value": 0.7082753519061953 },
    { "date": "Tue Jan 21 2014 00:00:00 GMT+0100 (CET)", "value": 0.14592946274206042 },
  ];


  constructor( public platformService: PlatformService, element: ElementRef, public ngZone: NgZone, d3Service: D3Service) {
    super( platformService, element, ngZone, d3Service );
  }

  drawGraph(): void {

    if ( !this.hasData() ) {
      console.warn( 'No data' );
      return;
    }

    super.drawGraph();

    let self = this;
    let d3 = this.d3;

    var data, x, y, xAxis, yAxis, 
    dim, chartWrapper, line, path, margin = { right: 0, top: 0, left: 0, bottom: 0 };
    const width = Math.round( this.getClientWidth() / 1 ); // 960
    const cellSize = Math.round( width / 57 ); // 17
    const height = Math.round( cellSize * 8 ); // 136

    var formatPercent = d3.format(".1%");

      var color = d3.scaleQuantize()
          .domain([-0.05, 0.05])
          .range( <any>[
            "#a50026", "#d73027", "#f46d43", "#fdae61", "#fee08b", "#ffffbf", "#d9ef8b", "#a6d96a", "#66bd63", "#1a9850", "#006837"
          ] );

      d3.select( '#' + this.divId ).selectAll( 'svg' ).remove();
      var svg = d3.select( '#' + this.divId )
        .selectAll("svg")
        .data(d3.range(2016, 2019))
        .enter().append("svg")
          .attr("width", width)
          .attr("height", height)
        .append("g")
          .attr("transform", "translate(" + ((width - cellSize * 53) / 2) + "," + (height - cellSize * 7 - 1) + ")");

      svg.append("text")
          .attr("transform", "translate(-6," + cellSize * 3.5 + ")rotate(-90)")
          .attr("font-family", "sans-serif")
          .attr("font-size", 10)
          .attr("text-anchor", "middle")
          .text(function(d) { return d; });

      var rect = svg.append("g")
          .attr("fill", "none")
          .attr("stroke", "#ccc")
        .selectAll("rect")
        .data(function(d) { return d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)); })
        .enter().append("rect")
          .attr("width", cellSize)
          .attr("height", cellSize)
          .attr("x", function(d) { return d3.timeWeek.count(d3.timeYear(d), d) * cellSize; })
          .attr("y", function(d) { return d.getDay() * cellSize; })
          .datum(d3.timeFormat("%Y-%m-%d"));

      svg.append("g")
          .attr("fill", "none")
          .attr("stroke", "#000")
        .selectAll("path")
        .data(function(d) { return d3.timeMonths(new Date(d, 0, 1), new Date(d + 1, 0, 1)); })
        .enter().append("path")
          .attr("d", pathMonth);

        let csv = [
          {
            "Date": "2017-10-01",
            "Open": 10789.72,
            "High": 10907.41,
            "Low": 10759.14,
            "Close": 10829.68,
            "Volume": 4298910000,
            "Adj Close": 10829.68
          },
          {
            "Date": "2017-09-30",
            "Open": 10835.96,
            "High": 10960.99,
            "Low": 10732.27,
            "Close": 10788.05,
            "Volume": 4284160000,
            "Adj Close": 10788.05
          },
          {
            "Date": "2018-09-29",
            "Open": 10857.98,
            "High": 10901.96,
            "Low": 10759.75,
            "Close": 10835.28,
            "Volume": 3990280000,
            "Adj Close": 10835.28
          }
        ];

        var data: any = d3.nest()
            .key(function( d: any) { return d.Date; })
            .rollup( <any>function(d: any) { return (d[0].Close - d[0].Open) / d[0].Open; })
          .object(csv);

        rect.filter(function(d) { return d in data; })
            .attr("fill", function(d) { return color(data[d]); })
          .append("title")
            .text(function(d) { return d + ": " + formatPercent(data[d]); });


      function pathMonth(t0) {
        var t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0),
            d0 = t0.getDay(), w0 = d3.timeWeek.count(d3.timeYear(t0), t0),
            d1 = t1.getDay(), w1 = d3.timeWeek.count(d3.timeYear(t1), t1);
        return "M" + (w0 + 1) * cellSize + "," + d0 * cellSize
            + "H" + w0 * cellSize + "V" + 7 * cellSize
            + "H" + w1 * cellSize + "V" + (d1 + 1) * cellSize
            + "H" + (w1 + 1) * cellSize + "V" + 0
            + "H" + (w0 + 1) * cellSize + "Z";
      }

  }

}
