// Core
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Services and directives
import { CompanyService } from '../../../company/company.service';

@Component({
    selector: 'address',
    templateUrl: 'address.component.html'
})
export class AddressComponent {
    @Input( 'group' )
    public addressForm: FormGroup;

    constructor(
        public service: CompanyService
        
    ) {
    }
}
