import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

// Components
import { XirocoComponent } from './xiroco.component';

// Feature components
import { UserListComponent } from './user/list/user-list.component';
import { UserCardComponent } from './user/card/user-card.component';
import { UserDetailsComponent } from './user/details/user-details.component';

import { AppListComponent } from './app/list/app-list.component';
import { AppCardComponent } from './app/card/app-card.component';
import { AppDetailsComponent } from './app/details/app-details.component';

import { FeatureComponent } from './app/details/feature/app-feature.component';
import { FeatureCardComponent } from './app/details/feature/card/app-feature-card.component';

import { AppFeatureFormComponent } from './app/details/feature/form/app-feature-form.component';
import { AppFeatureFormGroupComponent } from './app/details/feature/form/group/app-feature-form-group.component';
import { AppFeatureFormGroupRowComponent } from './app/details/feature/form/group/row/app-feature-form-group-row.component';

import { CompanyListComponent } from './company/list/company-list.component';
import { CompanyCardComponent } from './company/card/company-card.component';
import { CompanyDetailsComponent } from './company/details/company-details.component';

// Routing
import { xirocoRouting } from './xiroco.routing';

// Main service
import { XirocoService } from './xiroco.service';

// Feature services
import { UserService } from './user/user.service';
import { AppService } from './app/app.service';
import { CompanyService } from './company/company.service';


@NgModule({
    imports: [
        SharedModule,
        xirocoRouting
    ],
    declarations: [
        // Feature declarations
        UserListComponent, UserCardComponent, UserDetailsComponent,
        XirocoComponent,
        AppListComponent, AppCardComponent, AppDetailsComponent, FeatureComponent, FeatureCardComponent, 
        AppFeatureFormComponent, AppFeatureFormGroupComponent, AppFeatureFormGroupRowComponent,
        CompanyListComponent, CompanyCardComponent, CompanyDetailsComponent,
        CompanyCardComponent,
        AppCardComponent
    ],
    providers: [
        XirocoService,
        // Feature providers
        UserService,
        AppService,
        CompanyService
    ]
})
export class XirocoModule {}
