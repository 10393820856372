// Core
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

// Services and directives
import { AppService } from '../../../../app/app.service';
import { IAppFormGroup } from '../../../../app/app-form.interface';
import { PlatformService } from '../../../../../../platform.service';

@Component({
    selector: 'xiroco-app-feature-form',
    templateUrl: 'app-feature-form.component.html'
})
export class AppFeatureFormComponent implements OnInit {

    @Input( 'group' ) featureFormFormGroup: FormGroup;
    public formGroupPanelsStatus: any = {};

    public formPanelCollapsed: boolean;

    constructor(
        public service: AppService,
        public platformService: PlatformService
    ) {
        this.formPanelCollapsed = false;
    }

    ngOnInit() {}

    get groups(): FormArray {
        return this.featureFormFormGroup.get( 'groups' ) as FormArray;
    }


    // ********* FORM and FORM GROUPS ********* //

    /**
     * Get a new form group entity
     * @param group
     * @returns {FormGroup}
     */

    initFormGroup( group: IAppFormGroup ): FormGroup {
        var self = this;
        console.info( 'INIT FORM GROUP', group );
        console.groupCollapsed();
        let theFormGroup = new FormGroup( {} );
        console.groupEnd();
        console.info( 'Final formGroup', theFormGroup );
        return theFormGroup;
    }

    getEmptyFormGroup(): IAppFormGroup {
        return {
            id: "new",
            name: "New group",
            collapsed: false,
            rows: []
        }
    }

    // Add a brand new feature (click button)
    newFormGroup(): void {
        this.addFormGroup( this.getEmptyFormGroup() );
    }

    // Insert a new form group into form array
    // TODO: When add new feature increments the id with a number and test that
    // no 2 similars ids
    addFormGroup( group: IAppFormGroup ): void {
        const control = <FormArray>this.groups;
        control.push( this.initFormGroup( group ) );
    }

    // Removes a form group from the feature array
    removeFormGroup( i: number ): void {
        const self = this;
       
                const control = <FormArray>self.groups;
                control.removeAt(i);
            
    }


    // Panels properties (collapsed)
    getFormGroupPanelsObject( id: string ): any {
        if ( !this.formGroupPanelsStatus[ id ] ) {
            // By default, all groups not collapsed
            this.formGroupPanelsStatus[ id ] = {
                "collapsed": false
            };
        }
        return this.formGroupPanelsStatus[ id ];
    }
    setFeaturePanelsCollapsed( id: string, status: boolean ): void {
        this.getFormGroupPanelsObject( id ).collapsed = status;
    }
    getFeaturePanelsCollapsed( id: string ): boolean {
        return this.getFormGroupPanelsObject( id ).collapsed;
    }
    toggleFormGroupPanelsCollapsed( id: string ): void {
        this.setFeaturePanelsCollapsed( id, !this.getFeaturePanelsCollapsed( id ) );
    }


}
