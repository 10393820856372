/**
 * Service
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../../../abstract/app/feature/abstract.service';

import { PlatformService } from '../../../platform.service';
import { ChatAndBotService } from '../chatandbot.service';

import { IIssue } from './issue.interface';
import { Issue } from './issue.model';

import { LogService } from '../../../shared/log.service';



@Injectable()
export class IssueService extends AbstractService {

  // Get cache from local storage (if exists)
  public localStorage: boolean = true;
  
  constructor( public http: HttpClient,
               public platformService: PlatformService,
               public appService: ChatAndBotService,
               public logService: LogService
               
  ) {
    super( 'issue', http, platformService, appService, logService );
  }

  /**
   * This method returns an empty doc that it is necessary
   * to set up details component form
   * Here you can implement (i.e.) new id counter
   * @returns {Issue}
   */
  public getNewDoc( data: IIssue ) {
    return new Issue( data );
  }

  loadBaseData() {
    var self = this;
    var data = [
      {
      "id": 1,
      "name": "Pantalla rota",
      "rivescript": {
        "triggers": [ "[*]pantalla[*]rota[*]", "[*]rota[*]pantalla[*]", "[*]pantalla[*]varbrandname[*]vardevicename[*]rota[*]", "[*]varbrandname[*]vardevicename[*]pantalla[*]rota[*]", "[*]rota[*]pantalla[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la pantalla de tu varprettybrandname varprettydevicename, tendremos que cambiarla por una nueva", "Si la pantalla de tu dispositivo está rota, necesitaremos sustituirla" ]
      }
    }, {
      "id": 2,
      "name": "No carga",
      "rivescript": {
        "triggers": [ "[*]bateria[*]carga[*]", "[*]carga[*]bateria[*]", "[*]bateria[*]varbrandname[*]vardevicename[*]carga[*]", "[*]varbrandname[*]vardevicename[*]carga[*]bateria[*]", "[*]carga[*]bateria[*]varbrandname[*]vardevicename[*]", "[*]bateria[*]carga[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Si no se carga tu dispositivo, podemos sustituir la batería por una nueva", "Podemos cambiar la batería de tu dispositivo por una nueva, eso solucionará el problema" ]
      }
    }, {
      "id": 3,
      "name": "No escucho en llamada",
      "rivescript": {
        "triggers": [ "[*]no[*]escucho[*]", "[*]escucho[*]nada[*]", "[*]no[*]varbrandname[*]vardevicename[*]escucho[*]", "[*]varbrandname[*]vardevicename[*]escucho[*]nada[*]", "[*]no[*]nada[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la sonido de tu varprettybrandname varprettydevicename, podemos repararlo", "Si el sonido de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 4,
      "name": "No suena cuando me llaman, música o videos",
      "rivescript": {
        "triggers": [ "[*]no[*]suena[*]", "[*]suena[*]nada[*]", "[*]no[*]varbrandname[*]vardevicename[*]suena[*]", "[*]varbrandname[*]vardevicename[*]no[*]suena[*]", "[*]no[*]suena[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con el altavoz de tu varprettybrandname varprettydevicename, podemos repararlo", "Si el altavoz de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 5,
      "name": "No se bloquea cuando llamo",
      "rivescript": {
        "triggers": [ "[*]no[*]bloqueo[*]", "[*]no[*]bloquea[*]", "[*]no[*]varbrandname[*]vardevicename[*]bloquea[*]", "[*]varbrandname[*]vardevicename[*]no[*]bloquo[*]", "[*]no[*]bloquea[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con el bloqueo de tu varprettybrandname varprettydevicename, podemos solucionarlo", "Si el bloqueo en las llamadas de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 6,
      "name": "No hace fotos",
      "rivescript": {
        "triggers": [ "[*]no[*]foto[*]", "[*]camara[*]mal[*]", "[*]no[*]varbrandname[*]vardevicename[*]fotos[*]", "[*]varbrandname[*]vardevicename[*]camara[*]mal[*]", "[*]no[*]fotos[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la cámara de tu varprettybrandname varprettydevicename, podemos repararla", "Si la cámara de tu teléfono no funciona correctamente, podemos sustituirla" ]
      }
    }, {
      "id": 7,
      "name": "No enciende ni apaga",
      "rivescript": {
        "triggers": [ "[*]no[*]enciende[*]", "[*]no[*]apaga[*]", "[*]no[*]varbrandname[*]vardevicename[*]enciende[*]", "[*]varbrandname[*]vardevicename[*]no[*]apaga[*]", "[*]no[*]enciende[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la interruptor de tu varprettybrandname varprettydevicename, podemos repararlo", "Si el encendido o apagado de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 8,
      "name": "Volumen mal",
      "rivescript": {
        "triggers": [ "[*]no[*]volumen[*]", "[*]volumen[*]mal[*]", "[*]no[*]varbrandname[*]vardevicename[*]volumen[*]", "[*]varbrandname[*]vardevicename[*]volumen[*]mal[*]", "[*]no[*]volumen[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con botón del volumen de tu varprettybrandname varprettydevicename, podemos repararlo", "Si el botón del volumen de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 9,
      "name": "No coge wifi",
      "rivescript": {
        "triggers": [ "[*]no[*]wifi[*]", "[*]wifi[*]mal[*]", "[*]no[*]conecta[*]", "[*]mal[*]varbrandname[*]vardevicename[*]wifi[*]", "[*]no[*]conecta[*]varbrandname[*]vardevicename[*]wifi[*]", "[*]varbrandname[*]vardevicename[*]wifi[*]mal[*]", "[*]mal[*]wifi[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la antena wifi de tu varprettybrandname varprettydevicename, podemos repararla", "Si la wifi de tu teléfono falla, podemos solucionarlo" ]
      }
    }, {
      "id": 10,
      "name": "No me escuchan",
      "rivescript": {
        "triggers": [ "[*]no[*]me[*]escuchan[*]", "[*]escuhan[*]nada[*]", "[*]no[*]varbrandname[*]vardevicename[*]escuchan[*]", "[*]varbrandname[*]vardevicename[*]escuchan[*]nada[*]", "[*]no[*]escuchan[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con el micrófono de tu varprettybrandname varprettydevicename, podemos repararlo", "Si el micro de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 11,
      "name": "No coge cobertura",
      "rivescript": {
        "triggers": [ "[*]no[*]cobertura[*]", "[*]cobertura[*]nada[*]", "[*]no[*]varbrandname[*]vardevicename[*]cobertura[*]", "[*]varbrandname[*]vardevicename[*]cobertura[*]mal[*]", "[*]no[*]cobertura[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la cobertura en tu varprettybrandname varprettydevicename, podemos solucionarlo", "Si la cobertura de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 12,
      "name": "No funcionan los auriculares",
      "rivescript": {
        "triggers": [ "[*]no[*]auriculares[*]", "[*]auriculares[*]mal[*]", "[*]auriculares[*]varbrandname[*]vardevicename[*]fallan[*]", "[*]varbrandname[*]vardevicename[*]auriculares[*]mal[*]", "[*]mal[*]auriculares[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con los auriculares de tu varprettybrandname varprettydevicename, podemos repararlo", "Si los auriculares de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 13,
      "name": "Botón home no funciona",
      "rivescript": {
        "triggers": [ "[*]mal[*]boton[*]", "[*]boton[*]mal[*]", "[*]boton[*]varbrandname[*]vardevicename[*]mal[*]", "[*]varbrandname[*]vardevicename[*]boton[*]falla[*]", "[*]falla[*]boton[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con el botón inicio de tu varprettybrandname varprettydevicename, podemos repararlo", "Si el botón de inicio de tu teléfono falla, podemos solucionarlo" ]
      }
    }, {
      "id": 14,
      "name": "Batería se gasta rápido",
      "rivescript": {
        "triggers": [ "[*]bateria[*]rapido[*]", "[*]rapido[*]bateria[*]", "[*]bateria[*]varbrandname[*]vardevicename[*]gasta[*]", "[*]varbrandname[*]vardevicename[*]poco[*]bateria[*]", "[*]poco[*]bateria[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la batería de tu varprettybrandname varprettydevicename, podemos repararla", "Si la duración de la batería de tu teléfono es muy corta, podemos solucionarlo" ]
      }
    }, {
      "id": 15,
      "name": "No vibra",
      "rivescript": {
        "triggers": [ "[*]no[*]vibra[*]", "[*]vibracion[*]falla[*]", "[*]no[*]vibra[*]varbrandname[*]vardevicename[*]", "[*]varbrandname[*]vardevicename[*]no[*]vibra[*]", "[*]no[*]vibra[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la vibración de tu varprettybrandname varprettydevicename, podemos repararlo", "Si el sistema de vibración de tu teléfono falla, podemos solventarlo" ]
      }
    }, {
      "id": 16,
      "name": "No lee la tarjeta de memoria",
      "rivescript": {
        "triggers": [ "[*]no[*]tarjeta[*]", "[*]tarjeta[*]memoria[*]", "[*]no[*]varbrandname[*]vardevicename[*]tarjeta[*]", "[*]varbrandname[*]vardevicename[*]tarjeta[*]mal[*]", "[*]lee[*]tarjeta[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas con la tarjeta de tu varprettybrandname varprettydevicename, podemos repararlo", "Si la tarjeta de tu teléfono falla, podemos arreglarlo" ]
      }
    }, {
      "id": 17,
      "name": "No pasa del logo",
      "rivescript": {
        "triggers": [ "[*]no[*]logo[*]", "[*]logo[*]bloqueado[*]", "[*]no[*]varbrandname[*]vardevicename[*]logo[*]", "[*]varbrandname[*]vardevicename[*]logo[*]bloqueado[*]", "[*]no[*]pasa[*]logo[*]varbrandname[*]vardevicename[*]" ],
        "responses": [ "Bien, parece que tienes problemas durante el inicio de tu varprettybrandname varprettydevicename, podemos solucionarlo", "Si el inicio de tu teléfono falla, podemos repararlo" ]
      }
    } ];
    return new Promise( ( resolve, reject ) => {
      try {
        data.map( doc => {
          console.info( 'Saving...', doc );
          self.save( new Issue( { data: doc } ) ).subscribe(
            res => {
              console.info( 'saved, res: ', res );
            },
            error => {
              console.info( 'AbstractDetails.save error', error );
              reject( error );
            }
          );
        } );
        resolve();
      } catch( e ) {
        reject( e )
      }


    });
  }


}
