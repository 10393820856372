// Core
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { ServiceIssuesService } from '../../service-issues/service-issues.service';
import { LogService } from '../../../../shared/log.service';

// Components and directives
import { AbstractListComponent } from '../../../../abstract/app/feature/list/abstract-list.component';

@Component({
    selector: 'chatandbot-service-issues-list',
    templateUrl: 'service-issues-list.component.html'
})
export class ServiceIssuesListComponent extends AbstractListComponent {
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public service: ServiceIssuesService,
        public logService: LogService
    ) {
        super( route, router, location, platformService, appService, service, logService );
    }
    loadBaseData() {
      var self = this;
      
          self.service.loadBaseData()
            .then( res => self.platformService.addMessage( 'Demo data loaded!' ) )
            .catch( error => self.platformService.addMessage( <any>error ) );
      

    }
}
