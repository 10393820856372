// Modules
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders }  from '@angular/core';

// Main component
import { TapappsPOSComponent } from './tapapps-pos.component';

// Feature components

// Services
import { AuthGuardService } from '../../auth/auth-guard.service';

//TODO: Hay que revisar esto, si me traigo aquí las rutas de xiroco (que están en platform.routing) me da error de duplicación del módulo ????

const tapappsposRoutes: Routes = [
    { path: 'tapapps-pos', component: TapappsPOSComponent, canActivate: [ AuthGuardService ] },
    { path: 'tapapps-pos', canActivate: [ AuthGuardService ],
        children: [
            // Feature routes
        ]
    }
];

//export const xirocoRouting = RouterModule.forChild( xirocoRoutes );
export const tapappsposRouting: ModuleWithProviders = RouterModule.forChild( tapappsposRoutes );