/**
 * Company model
 */


import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';
import { ICompany } from './company.interface';


export class Company extends AbstractModel {
    constructor( company?: ICompany ) {

        super( company );

        // Default values
        if ( typeof( this.data.name ) === 'undefined' ) this.data.name = '';
        if ( typeof( this.data.currency ) === 'undefined' ) this.data.currency = '€';
        if ( typeof( this.data.addresses ) === 'undefined' ) this.data.addresses = [];
        if ( typeof( this.data.status ) === 'undefined' ) this.data.status = 'enabled';

        // Fixed info
        this.type = 'company';
        this.app = 'xiroco';
        this.params.collection = 'company';
        this.params.type = 'company';


    }
}