import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { CORE_DIRECTIVES, FORM_DIRECTIVES } from '@angular/common';
//ROUTER_DIRECTIVES, CORE_DIRECTIVES, FORM_DIRECTIVES
import { FormsModule, ReactiveFormsModule, FormControl, FormGroup  } from '@angular/forms';
import { HttpModule } from '@angular/http'
import { BrowserModule } from '@angular/platform-browser';

import { AddressComponent } from '../apps/xiroco/company/details/address/address.component';
import { YamlJsonComponent } from '../layout/forms/yaml-json/yaml-json.component';

import { BarChartComponent } from '../abstract/app/d3/bar-chart/bar-chart.component';
import { LineChartComponent } from '../abstract/app/d3/line-chart/line-chart.component';
import { CalendarChartComponent } from '../abstract/app/d3/calendar-chart/calendar-chart.component';
import { TreemapComponent } from '../abstract/app/d3/treemap/treemap.component';
import { AppStorageStatsComponent } from '../abstract/app/stats/abstract-app-storage-stats.component';
import { CardsStorageStatsComponent } from '../apps/cards/stats/cards-storage-stats.component';


@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule
    ],
    declarations: [
        AddressComponent,
        BarChartComponent,
        LineChartComponent,
        CalendarChartComponent,
        TreemapComponent,
        AppStorageStatsComponent,
        CardsStorageStatsComponent,
        YamlJsonComponent
    ],
    exports: [

        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AddressComponent,
        BarChartComponent,
        LineChartComponent,
        CalendarChartComponent,
        TreemapComponent,
        AppStorageStatsComponent,
        CardsStorageStatsComponent,
        YamlJsonComponent
    ]
})
export class SharedModule { }
