import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {

    private cache: any = {};

    constructor() {}

    public getCache(): any {
        return this.cache;
    }

    public setCache( value: any ): void {
        this.cache = value;
    }
}