import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlatformService } from '../../platform.service';
import { AbstractAppService } from '../../abstract/app/abstract-app.service';
@Injectable()
export class XirocoService extends AbstractAppService {
    constructor(
        public platformService: PlatformService,
        public http: HttpClient
    ) {
        super( platformService, 'xiroco', http );
    }
}
