// Core
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// Services and directives
import { AppService } from '../../../../../..//app/app.service';
import { PlatformService } from '../../../../../../../../platform.service';

@Component({
    selector: 'xiroco-app-feature-form-group-row',
    templateUrl: 'app-feature-form-group-row.component.html'
})
export class AppFeatureFormGroupRowComponent implements OnInit {

    @Input( 'group' ) formGroup: FormGroup;

    public newFieldInputValue: string = '';

    constructor(
        public service: AppService,
        public platformService: PlatformService
    ) {
    }

    ngOnInit() {}

    getFields() {
        return this.formGroup.controls[ 'fields' ].value;
    }

    removeField( i: number ): void {
        var self = this;
        
                self.formGroup.controls['fields'].value.splice(i, 1);
            
    }

    // TODO: Implements validator to disable add button if no data in field and avoid duplicates
    addField() {
        this.formGroup.controls[ 'fields' ].value.push( this.newFieldInputValue );
        this.newFieldInputValue = '';
    }
}
