// Core
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { PlatformService } from '../../../../platform.service';
import { BitacoraService } from '../../bitacora.service';
import { BeaconsService } from '../beacons.service';
import { LogService } from '../../../../shared/log.service';

// Components and directives
import { AbstractListComponent } from '../../../../abstract/app/feature/list/abstract-list.component';

@Component({
    selector: 'bitacora-beacons-list',
    templateUrl: 'beacons-list.component.html'
})
export class BeaconsListComponent extends AbstractListComponent {
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public platformService: PlatformService,
        public appService: BitacoraService,
        public service: BeaconsService,
        public logService: LogService
    ) {
        super( route, router, location, platformService, appService, service, logService );
    }
}
