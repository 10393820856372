import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

// Components
import { BitacoraComponent } from './bitacora.component';

// Feature components
import { LogbookListComponent } from './logbook/list/logbook-list.component';
import { LogbookCardComponent } from './logbook/card/logbook-card.component';
import { LogbookDetailsComponent } from './logbook/details/logbook-details.component';

import { BeaconsListComponent } from './beacons/list/beacons-list.component';
import { BeaconsCardComponent } from './beacons/card/beacons-card.component';
import { BeaconsDetailsComponent } from './beacons/details/beacons-details.component';


// Routing
import { bitacoraRouting } from './bitacora.routing';

// Main service
import { BitacoraService } from './bitacora.service';

// Feature services
import { LogbookService } from './logbook/logbook.service';
import { BeaconsService } from './beacons/beacons.service';

@NgModule({
    imports: [
        SharedModule,
        bitacoraRouting
    ],
    declarations: [
        // Feature declarations
        LogbookListComponent, LogbookCardComponent, LogbookDetailsComponent,
        BeaconsListComponent, BeaconsCardComponent, BeaconsDetailsComponent,
        BitacoraComponent
    ],
    providers: [
        BitacoraService,
        // Feature providers
		LogbookService,
        BeaconsService
    ]
})
export class BitacoraModule {}
