// Core
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Components
import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';
// Models and services
import { App } from '../app.model';
import { AppService } from '../app.service';

@Component({
    selector: 'xiroco-app-card',
    templateUrl: 'app-card.component.html',
})
export class AppCardComponent extends AbstractCardComponent {

    @Input( 'feature' ) feature: string;
    @Input( '_id' ) _id: string;
    
    public doc: App = new App();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: AppService,
        public location: Location,
    public platformService: PlatformService, public logService: LogService ) {
            super( route, router, service, location, platformService, logService );
            this.setFeature( 'app' );
    }
}
