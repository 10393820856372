// Core modules
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Xiroco modules
import { AbstractAppComponent } from '../../abstract/app/abstract-app.component';

// Services
import { PlatformService } from '../../platform.service';
import { BitacoraService } from './bitacora.service';

@Component({
    selector: 'bitacora-root',
    templateUrl: 'bitacora.component.html'
})
export class BitacoraComponent extends AbstractAppComponent {

    constructor(
        public router: Router,
        public platformService: PlatformService,
        public appService: BitacoraService
    ) {
        super( router, platformService, appService );
    }

}
