/**
 * Model
 */
import { IOrder,  } from './order.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';


export class Order extends AbstractModel {

    id: string;

    constructor( obj: IOrder = <IOrder>{} ) {

        super( obj );

        // Default values
        if ( typeof( this.id ) === 'undefined' ) this.data.id = 'no-id';
        if ( typeof( this.data.reference ) === 'undefined' ) this.data.reference = '';
        if ( typeof( this.data.status ) === 'undefined' ) this.data.status = '';

        // Fixed info
        this.type = 'order';
        this.app = 'chatandbot';
        this.params.collection = 'order';
        this.params.type = 'order';

    }
}
