/**
 * Abstract interface (KSO), extended in other interfaces
 */
export interface iAbstractModelParams {
    level: number,
    collection: string,
    type: string
}

export interface iPosition {
   
        geospatial: {
            type: string,
            coordinates: any[]
        },
        location: {
            x: number,
            y: number
        },
        elevation: number
} 

export interface iAbstractModel {
    _id?: string,
    deleted?: boolean,
    updatedAt?: Date,
    type?: string,
    app?: string,
    UID?: string,
    parentId?: string,
    cloudUpdated?: boolean,
    data?: any,
    params?: iAbstractModelParams,
    identity?: any,
    history?: any,
    created?: {
        at: Date,
        position: iPosition,
        by: {
            id: string,
            name: string
        }    
    },
    updated?: {
        at: Date,
        position: iPosition,
        by: {
            id: string,
            name: string
        }    
    }

}

// Simplified version of the docs
// Useful for external relations from another documents
export interface ISummaryDoc {
    id: number,
    name: string,
    _id: string
}

export const AbstractModelStatusOptions = [ 'Enabled', 'Disabled' ];
