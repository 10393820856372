import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { Response } from '@angular/http';

import { Observable } from 'rxjs/Observable';

import { AbstractModel } from '../abstract.model';
import { AbstractModelStatusOptions } from '../abstract.interface';
import { AbstractService } from '../abstract.service';
import { LogService } from '../../../../shared/log.service';
import { AuthService } from '../../../../auth/auth.service';
import { PlatformService } from '../../../../platform.service';
import { AbstractAppService } from '../../abstract-app.service';

import { Helper } from '../../../../shared/helper.service';
import { PromiseObservable } from 'rxjs/observable/PromiseObservable';
import { YamlJsonComponent } from '../../../../layout/forms/yaml-json/yaml-json.component';

import { InputTextModule } from 'primeng/primeng';
import { ToggleButtonModule } from 'primeng/primeng';

import * as yaml from 'js-yaml';
import { i18nApply } from '@angular/core/src/render3/i18n';

@Component( {
  selector: 'xiroco-app-feature-details',
  templateUrl: 'abstract-details.component.html'
} )
export class AbstractDetailsComponent implements OnInit, OnDestroy {

  // Used for getting URL params
  private sub: any;

  // In this var store current URL
  public url: string = '';
  public urlName: string = '';

  private cont: number = 0;

  // The database cloud id (null in add mode)
  public _id: string;

  // Detemine if this form is for add a new doc or update an existing doc
  public addMode: boolean = false;

  // The most importnat thing, the document
  public doc: AbstractModel = new AbstractModel();

  public statusOptions = AbstractModelStatusOptions;
  public submitted: boolean = false;
  public dataModified: boolean = false;
  private initialValues: any = {};
  private initialValuesTxt: string = '';

  public errorInRawDoc = false;

  public form: FormGroup;

  // Set renderView when everything it's loaded, loading sniped must be hidden and
  // the form fields rendered
  private renderView: boolean = false;

  public coreDocInfoPanelCollapsed: boolean = true;

  isOn = false;
  isDisabled = false;

  floatingButtonsVisible: boolean = false;

  // TODO: Analizar bien si han de ser públicos o provados, me da que tienen que ser privados.
  constructor( public route: ActivatedRoute,
               public router: Router,
               public platformService: PlatformService,
               public appService: AbstractAppService,
               public service: AbstractService,
               public location: Location,
               public logService: LogService,
               public helper: Helper,
               public authService: AuthService,
               public formBuilder: FormBuilder ) {

    // form must be a FormGroup, later we populate it with controls, form arrays, etc
    this.form = new FormGroup( {} );

    // Get URL params...
    this.sub = this.route.params.subscribe( params => {

      this.logService.log( 'AbstractDetailsComponent', 'Getting params...' );

      // Get data from params

      // If we get appSettings from params (abstract components) then set in the abstract server
      // Featured services don't need it, they know it
      if ( params[ 'app' ] ) {
        this.appService.setApp( params[ 'app' ] );
        this.logService.log( 'AbstractDetailsComponent', 'Set App ' + this.appService.getApp() );
      } else {
        this.logService.log( 'AbstractDetailsComponent', 'No App in params' );
      }

      // If we get feature from params (abstract components) then set in the abstract server
      // Featured services don't need it, they know it
      if ( params[ 'feature' ] ) {
        this.service.setFeature( params[ 'feature' ] );
        this.logService.log( 'AbstractDetailsComponent', 'Set Feature ' + this.service.getFeature() );
      } else {
        this.logService.log( 'AbstractDetailsComponent', 'No Feature in params' );
      }


      // Test params and check if _id is valid, then select if we are in edit or add mode
      if ( !params[ '_id' ] ) {
        this.platformService.addMessage( 'No _id neither add path' );
        this.logService.log( 'AbstractDetailsComponent', 'No id neither add path' );
      } else if ( params[ '_id' ] == 'add' ) {
        this.addMode = true;
      } else if ( this.helper.checkValidDatabaseId( params[ '_id' ] ) ) {
        this._id = params[ '_id' ];
      } else {
        this.platformService.addMessage( 'Invalid id' );
        this.logService.log( 'AbstractDetailsComponent', 'Invalid database id ' + this._id );
      }

      this.logService.log( 'AbstractDetailsComponent', 'Constructor ok!' );
    } );
  }

  updateDoc( obj ) {
    this.doc = obj;
  }

  /**
   * When the component it is loaded, if in edit mode, get the document from the remote server
   */
  ngOnInit() {

    this.service.incrementUsage();

    console.log( 'ngOnInit cont', ++this.cont, this.cont > 1 
      ? '**** ngOnInit called twice due to an error, with no error this must be called once' 
      : 'ngOnInit first call' );

    const self = this;

    // First of all create an empty doc
    self.initEmptyDoc();

    self.initDoc().then( () => {
      // Now its time to hide loading sniper and render the form
      self.renderView = true;
      // Save a descriptive name of the doc in order to show it in router history
      self.urlName = self.getDocName();
      self.logService.log( 'AbstractDetailsComponent', 'Doc initialized for custom form' );
    }).catch( err => {
      self.platformService.showError( 'error_in_document_loading' );
      console.error( 'Error in AbstractDetailsComponent.initDoc', err );
    });
    

    // Store current URL for later (onDestroy) save it to history
    this.url = this.router.url;

  }

  i18n( value: string ): string {
    return this.platformService.i18n( value );
  }

  /**
   * This method returns 
   */
  getDocName(): string {
    return this.doc && this.doc.data 
              ? this.doc.data.name || this.doc.data.shortDescription || this.doc.data.description || this.doc.data.id 
              : ( this.doc 
                ? this.doc._id || this.i18n( 'no_name' ) 
                : this.i18n( 'no_doc_loaded' ) );
  }
  

  ngOnDestroy() {
    // Save current URL to navigation history
    this.platformService.addRouteToHistory( this.urlName, this.url );  
  }

  /**
   * If we are editing get data from a remote server
   * If not, add mode, we already have an empty doc
   */
  initDoc() {
    const self = this;
    return new Promise( ( resolve, reject ) => {      
      if ( !self.addMode ) {
        // Get document from a remote server
        self.getDoc().then( () => {
          self.logService.log( 'AbstractDetailsComponent', 'initDoc for ' 
            + self.appService.getApp() + '/' + this.service.feature + '/' + this._id );
          resolve();
        } ).catch( err => {
          reject( err );
        } );
      } else {
        self.logService.log( 'AbstractDetailsComponent', 'initDoc for ' + self.appService.getApp() + '/' + this.service.feature + '/add' );
        resolve();
      }
    });
  }

  /**
   * this creates an empty doc based on model
   * Must be overridden in each details file with its own model
   */
  initEmptyDoc(): void {
    this.doc = this.service.getNewDoc();
  }

  getId(): string {
    return this._id;
  }
  /**
   * Get doc from a remote server and fill the form
   */
  getDoc(): Promise<any> {
    const self = this;
    return new Promise( function( resolve, reject ) {
      if ( !self.service.feature ) {
        console.error( '*** ERROR in abstract-card.getDoc: No feature' );
      } else if ( !self._id ) {
        console.error( '*** ERROR in abstract-card.getDoc: No _id' );
      } else {
        console.log( 'getDoc', self._id );
        self.platformService.loaderVisible = true;
        self.service.getDoc( self._id )
          .subscribe(
            doc => {
              console.log( 'getDoc response', doc );
              self.doc = doc[ 0 ];
              self.logService.log( 'AbstractDetailsComponent', 'getDoc ok ' );
              self.platformService.loaderVisible = false;
              resolve();
            },
            errorResponse => {
              self.platformService.loaderVisible = false;
              self.logService.log( 'AbstractDetailsComponent', 'Error in getDocs', true, errorResponse.error );
              self.platformService.HTTPErrorHandle( errorResponse );
              reject( errorResponse );
            }
          );
      }
    } );
  }

  /**
   * Form save, set the doc object and put data if _id existes or post if not to remote server
   */
  save() {

    const self = this;

    // Call the save method of the service
    const doSave = () => {
      self.platformService.loaderVisible = true;
      self.service.save( self.doc ).subscribe(
        response => {
          self.platformService.loaderVisible = false;
          self.submitted = true;
          self.service.saveCacheToDisk();
          self.navigateToAll();
        },
        errorResponse => {
          self.platformService.loaderVisible = false;
          self.logService.log( 'AbstractDetailsComponent', 'Error in save', true, errorResponse.error );
          self.platformService.HTTPErrorHandle( errorResponse );
        }
      );
    };

    doSave();

  }

  /**
   * Delete button click
   */
  delete() {
    const self = this;
    self.platformService.loaderVisible = true;
    self.service.deleteDoc( self.doc._id ).then( () => {
      self.platformService.loaderVisible = false;
      self.navigateToAll();
    }).catch( errorResponse => {
      self.platformService.loaderVisible = false;
      self.logService.log( 'AbstractDetailsComponent', 'Error in delete', true, errorResponse.error );
      self.platformService.HTTPErrorHandle( errorResponse );
    });

  }

  get updatedAt() {
    return this.doc.updatedAt;
  }

  /**
   * this method determine if the doc can be deleted
   */
  canDelete(): boolean {
    return true;
  }

  /**
   * this method determine if the doc can be saved, could be read only or not necessary (not modified)
   */
  canSave(): boolean {
    return true;
  }

  /**
   * Activate / deactivate undo button
   */
  canUndo(): boolean {
    return this.dataModified;
  }

  /**
   * Activate / deactivate duplicate button
   */
  canDuplicate(): boolean {
    return true;
  }

  /**
   * Method fired when return button is clicked going back to previous page
   */
  goBack(): void {
    this.location.back();
  }

  /**
   * This method set the docs properties to the form controls
   */
  doc2form() {
    const self = this;
    self.form.patchValue( self.doc.data );
  }

  /**
   * This method set the form values to doc object
   */
  form2doc( callback: any ) {
    this.doc.data = this.form.value;
    callback();
  }

  getModelName(): string {
    if ( !this.doc ) {
      return 'no-model-name';
    } else {
      return this.service.getFeature();
    }
  }

  
  getDocId(): string {
    return this._id;
  }

  /**
   * Return to the feature list
   */
  navigateToAll() {
    this.location.back();
  }

  /**
   * Adds an alert message
   * @param errorResponse Error message to be shown
   */
  addMessage( errorResponse: any ) {
    const self = this;
    self.platformService.addMessage( self.platformService.i18n( errorResponse.error.message || 'undefined_error' ) );
    // Not authorized, redirect to sign in
    if ( errorResponse.status === 401 ) {
      this.authService.signInAgain();
    }

  }

  /**
   * Restore original data
   */
  undo() {
    const self = this;
    self.doc2form();
  }

  /**
   * Creates a new doc with current data and edit it
   */
  duplicate() {
  }

  getDataArray() {
    return Object.keys( this.doc.data );
  }

  changeCheckBox( event: any ) {
    alert( this.form.controls[ 'required' ] );
    console.log( event );
  }

  toggleCollapse( newState: boolean ) {
    if ( !this.isDisabled ) {
      this.isOn = newState;
    }
  }

  currentRouterLink(): string {
    return this.appService.getSettings().path + '/' + this.service.getSettings().path;
  }


  floatingButtonsMouseOver(): void {
    //Disabled by now, in future think about show/hide when over/leave
    //this.floatingButtonsVisible = true;
  }

  floatingButtonsMouseLeave(): void {
    //Disabled by now, in future think about show/hide when over/leave
    //this.floatingButtonsVisible = false;
  }

  floatingButtonsMouseClick(): void {
    this.floatingButtonsVisible = !this.floatingButtonsVisible;
  }


  getFormObject(): string {
    return JSON.stringify( this.form );
  }

  /**
   * Uses for string arrays input handlers
   */
  trackByFn(index: any, item: any) {
    return index;
  }

  get rawDoc(): string {
    return JSON.stringify( this.doc, null, 2 );
  }

  set rawDoc( stringDoc: string ) {
    try {
      this.doc = JSON.parse( stringDoc );
      this.errorInRawDoc = false;
    } catch ( error ) {
      this.errorInRawDoc = true;
    }
  }
 
  get YAMLDoc(): string {
    return yaml.safeDump( this.doc );

  }
  set YAMLDoc( stringDoc: string ) {
    try {
      this.doc = yaml.safeLoad( stringDoc );
      this.errorInRawDoc = false;
    } catch ( error ) {
      this.errorInRawDoc = true;
    }

  }

  suscribeValueChanges() {
    this.form.valueChanges.subscribe( value => {
      // TODO: Compare original data with current data to determine if it is really changed
      this.dataModified = true;
    } );
  }


  /**
   * Show get location button
   */
  canSetLocation() {
    return true;
  }

  /**
   * Save current position to document
   */
  setLocation() {
    if ( navigator.geolocation ) {
      navigator.geolocation.getCurrentPosition( position => {
        console.log( position );
      }, err => {
          switch ( err.code ) {
            case err.PERMISSION_DENIED:
              console.error( "User denied the request for Geolocation." );
              break;
            case err.POSITION_UNAVAILABLE:
              console.error( "Location information is unavailable." );
              break;
            case err.TIMEOUT:
              console.error( "The request to get user location timed out." );
              break;
          };
      });
    } else {
        console.error( 'Geolocation is not supported by this browser.' );
    }
  }


  getNavbarTitle(): string {
    const name = this.getDocName();
    return name !== '' ? name : this.platformService.i18n( 'editing_document' );
  }

}
