// Modules
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

// Main component
import { CardsComponent } from './cards.component';

// Feature components
import { CardsListComponent } from './cards/list/cards-list.component';
import { CardsDetailsComponent } from './cards/details/cards-details.component';

import { TriggerListComponent } from './trigger/list/trigger-list.component';
import { TriggerDetailsComponent } from './trigger/details/trigger-details.component';

import { TemplateListComponent } from './template/list/template-list.component';
import { TemplateDetailsComponent } from './template/details/template-details.component';

// Services
import { AuthGuardService } from '../../auth/auth-guard.service';

const cardsRoutes: Routes = [
    { path: 'cards', component: CardsComponent, canActivate: [ AuthGuardService ] },
    { path: 'cards', canActivate: [ AuthGuardService ],
        children: [
            // Feature routes
            { path: 'card', component: CardsListComponent, canActivate: [ AuthGuardService ] },
            { path: 'card/:_id', component: CardsDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'trigger', component: TriggerListComponent, canActivate: [ AuthGuardService ] },
            { path: 'trigger/:_id', component: TriggerDetailsComponent, canActivate: [ AuthGuardService ] },
            { path: 'template', component: TemplateListComponent, canActivate: [ AuthGuardService ] },
            { path: 'template/:_id', component: TemplateDetailsComponent, canActivate: [ AuthGuardService ] }
        ]
    }
];

export const cardsRouting: ModuleWithProviders = RouterModule.forChild( cardsRoutes );