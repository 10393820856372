// Core
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { GroupService } from '../group.service';
import { LogService } from '../../../../shared/log.service';

// Components and directives
import { AbstractListComponent } from '../../../../abstract/app/feature/list/abstract-list.component';

@Component({
    selector: 'chatandbot-group-list',
    templateUrl: 'group-list.component.html'
})
export class GroupListComponent extends AbstractListComponent {
    
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public service: GroupService,
        public logService: LogService
    ) {
        super( route, router, location, platformService, appService, service, logService );
    }

    downloadFromPrestashop() {
        /*
        self.appService.getGroups()
            .subscribe(
                res  => self.platformService.addMessage( 'Obtaining groups has begun, you will be notified by email when the process is complete' ),
                error =>  self.platformService.addMessage( <any>error )
            );
            */
    }
}
