import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractCardComponent } from '../../../../abstract/app/feature/card/abstract-card.component';
import { PlatformService } from '../../../../platform.service';import { LogService } from '../../../../shared/log.service';

import { Synonym } from '../synonym.model';
import { SynonymService } from '../synonym.service';

@Component({
    selector: 'synonym-card',
    templateUrl: 'synonym-card.component.html'
})
export class SynonymCardComponent extends AbstractCardComponent {

    @Input() feature: string;
    @Input() _id: string;
    public doc: Synonym = new Synonym();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public service: SynonymService,
        public location: Location,
        public platformService: PlatformService, public logService: LogService ) {
        super( route, router, service, location, platformService, logService );
        this.setFeature( 'synonym' );
    }
}
