import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { PlatformService } from '../platform.service';
import { XirocoService } from '../apps/xiroco/xiroco.service';

@Injectable()
export class AuthService {

    private signedIn = false;

    public autoSaveSettingsTimerLocally: number = 60000;
    public autoSaveSettingsTimerRemote: number = 600000;

    constructor(
        public router: Router,
        public http: HttpClient,
        private xirocoService: XirocoService,
        private platformService: PlatformService ) {

            this.signedIn = !!localStorage.getItem( 'auth_token' );
            this.initAutoSaveSettings();

    }

    initAutoSaveSettings(): void {
        const self = this;

        setInterval( () => {
            self.platformService.saveSettingsLocally();
            console.log( 'Settings locally saved' );
        }, self.autoSaveSettingsTimerLocally );

        setInterval( () => {
            self.saveSettingsRemote();
            console.log( 'Settings remote saved' );
        }, self.autoSaveSettingsTimerRemote );
    }

    /**
     * Save all settings, user and platform settings in cloud server
     */
    saveSettingsRemote(): void {
        const self = this;
        self.platformService.loaderVisible = true;
        this.platformService.saveUserSettings();
        this.platformService.savePlatformSettings();
        self.saveUser( this.platformService.getUser() ).subscribe(
            data => {
              self.saveUserData( this.platformService.getUser() );
              self.platformService.loaderVisible = false;
            },
            errorResponse => {
              self.platformService.loaderVisible = false;
              console.error( 'User saveChanges error', errorResponse );
            }
          );
    }



    public getHeaders(): HttpHeaders {
        const contentHeaders = new HttpHeaders();
        contentHeaders.append( 'Accept', 'application/json' );
        contentHeaders.append( 'Content-Type', 'application/json' );
        contentHeaders.append( 'Access-Control-Allow-Origin', '*' );
        return contentHeaders;
    }

    public getHeadersWithBasicAuth( username: string, password: string ): HttpHeaders {
        return new HttpHeaders( {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Basic ' + btoa( username + ':' + password )
        } );
    }

    /**
     * Set the sign in status
     */
    setSignedIn( value: boolean ) {
        this.signedIn = value;
    }

    /**
     * Updates http headers object with Auth info
     * @param headers Headers object where to add auth data
     * @param username User name
     * @param password Password
     */
    appendAuthorizationHeader( headers: HttpHeaders, username: string, password: string ) {
        headers.append( 'Authorization', 'Basic ' + btoa( username + ':' + password ) );
        return headers;
    }

    /**
     * Store locally all data (platform, user, token...)
     * @param data Platform, token and user data
     */
    saveSessionData( data: any ): void {
        localStorage.setItem( 'auth_token', data.token );
        this.platformService.setPlatformSettings( data.platformSettings );
        console.log( data );
        this.saveUserData( data.user );
        this.setSignedIn( true );
    }

    /**
     * Save locally (local storage) user data
     * @param data User data
     */
    saveUserData( data: any ): void {
        this.platformService.setUser( data );
    }


    /**
     * Call API in order to register a new user
     * @param email Email address
     * @param password Password
     */
    signUp( name: string, email: string, password: string ) {

        const self = this;

        return this.http.post( this.platformService.getServerURL() + '/user/register', {
            "name": name,
            "password": password,
            "email": email
        }, {
            headers: self.getHeadersWithBasicAuth( email, password )
        } );

    }

    /**
     * Call API in order to update user account
     */
    saveUser( data: any ) {
        const self = this;

        const headers = this.getHeaders();

        return self.http.put(
            self.platformService.getServerURL() + '/user/' + data._id + self.xirocoService.getTokenParam(),
            data,
            {
                headers
            }
        );

    }

    /**
     * Make loggin process
     * @param email Email address
     * @param password Password
     */
    signIn( email: string, password: string ) {
        const self = this;

        return self.http.post( self.platformService.getServerURL() + '/user/login', {}, {
            headers: self.getHeadersWithBasicAuth( email, password )
        } );

    }

    /**
     * Log out! Remove token and platform settings saved locally
     */
    signOut() {
        this.platformService.removeLocalStorageData();
        this.signedIn = false;
        return this;
    }

    /**
     * This method it is called when an http request response with 401 (Unauthrized) and
     * redirect to sign in page
     */
    signInAgain() {
        this.signOut().router.navigate( [ 'signin' ]);
    }

    /**
     * Returns if the user it is signed in, to show/hide username/sign in word and the Apps or register page
     */
    isSignedIn() {
        return this.signedIn;
    }
}
