import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';

import { AbstractDetailsComponent } from '../../../../abstract/app/feature/details/abstract-details.component';

import { PlatformService } from '../../../../platform.service';
import { ChatAndBotService } from '../../chatandbot.service';
import { GroupService } from '../group.service';

import { Group } from '../group.model';

import { LogService } from '../../../../shared/log.service';
import { AuthService } from '../../../../auth/auth.service';
import { Helper } from '../../../../shared/helper.service';


import { InputTextModule } from 'primeng/primeng';
import { ToggleButtonModule } from 'primeng/primeng';

@Component({
    selector: 'chatandbot-group-details',
    templateUrl: 'group-details.component.html'
})
export class GroupDetailsComponent extends AbstractDetailsComponent {

  public doc: Group;
  
  constructor(
        public route: ActivatedRoute,
        public router: Router,
        public platformService: PlatformService,
        public appService: ChatAndBotService,
        public service: GroupService,
        public location: Location,
        public logService: LogService,
        public helper: Helper,
        public authService: AuthService,
        public formBuilder: FormBuilder
    ) {
        super( route, router, platformService, appService, service, location, logService, helper, authService, formBuilder );
    }


    get imageURL(): string {
        return 'http://' + this.doc.data.img;
    }

}
