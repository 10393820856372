// Global core modules
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Formbuilder specific modules
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

import { AbstractDetailsComponent } from '../../../../abstract/app/feature/details/abstract-details.component';

import { XirocoService } from '../../xiroco.service';

import { CompanyService } from '../../company/company.service';
import { IAddress } from '../../../../shared/interfaces/address.interface';
import { Company } from '../../company/company.model';


import { PlatformService } from '../../../../platform.service';

import { LogService } from '../../../../shared/log.service';
import { AuthService } from '../../../../auth/auth.service';
import { Helper } from '../../../../shared/helper.service';

@Component( {
  selector: 'xiroco-company-details',
  templateUrl: 'company-details.component.html'
} )
export class CompanyDetailsComponent extends AbstractDetailsComponent {

  public addressesGroupCollapsed: boolean = false;
  public panelsStatus: any = {};
  public doc: Company = new Company();

  private ADDRESSES_FORM_ARRAY_NAME: string = 'addresses';

  constructor( public route: ActivatedRoute,
               public router: Router,
               public platformService: PlatformService,
               public appService: XirocoService,
               public service: CompanyService,
               public location: Location,
               public logService: LogService,
               public helper: Helper,
               public authService: AuthService,
               public formBuilder: FormBuilder
  ) {
    super( route, router, platformService, appService, service, location, logService, helper, authService, formBuilder );
    this.logService.log( 'CompanyDetailsComponent', 'Constructor ok!' );

    console.info( 'constructor-exists-formArray', this.form.get( this.ADDRESSES_FORM_ARRAY_NAME ) as FormArray );

  }

  /**
   * Call ngOnInit an initilize nested form groups or form arrays
   */
  ngOnInit() {
    super.ngOnInit();

    // It is necessary add a form array control to this form, if not an error it is throw due to DOM binding (formArrayName=...)
    this.form.addControl( this.ADDRESSES_FORM_ARRAY_NAME, new FormArray( [] ) );
  }

  doc2form() {
    super.doc2form();
    console.info( 'doc2form-exists-formArray', this.form.get( this.ADDRESSES_FORM_ARRAY_NAME ) as FormArray );

    try {
      this.doc.data.addresses.forEach( ( address: IAddress ) => {
        console.info( 'Adding address', address );
        this.addAddress( address );
      } );

    } catch ( e ) {
      console.error( e );
    }

  }

  // ********* ADDRESSES ********* //

  initAddress( address: IAddress ): FormGroup {
    var self = this;
    console.groupCollapsed( 'INIT ADDRESS' );
    let theFormGroup = new FormGroup( {} );
    console.info( 'Final formGroup', theFormGroup );
    console.groupEnd();
    return theFormGroup;
  }

  getEmptyAddress(): any {
    return <IAddress>{
      street: "",
      postcode: "",
      city: "",
      state: "",
      country: ""
    };
  }


  get addresses(): FormArray {
    console.info( 'addresses', this.form.controls[ this.ADDRESSES_FORM_ARRAY_NAME ] );

    console.info( this.form.controls[ this.ADDRESSES_FORM_ARRAY_NAME ] );

    return this.form.controls[ this.ADDRESSES_FORM_ARRAY_NAME ] as FormArray;
  }

  // Add a brand new feature (click button)
  newAddress(): void {
    this.addAddress( this.getEmptyAddress() );
  }

  addAddress( address: IAddress ): void {
    const control = <FormArray>this.form.controls[ this.ADDRESSES_FORM_ARRAY_NAME ];
    control.push( this.initAddress( address ) );
  }

  removeAddress( i: number ): void {
    var self = this;
    
        const control = <FormArray>self.form.controls[ self.ADDRESSES_FORM_ARRAY_NAME ];
        control.removeAt( i );
      
  }

  getPanelsObject( id: string ): any {
    if ( !this.panelsStatus[ id ] ) {
      // By default, all features not collapsed
      this.panelsStatus[ id ] = {
        "collapsed": false
      }
    }
    return this.panelsStatus[ id ];
  }

  setPanelsCollapsed( id: string, status: boolean ): void {
    this.getPanelsObject( id ).collapsed = status;
  }

  getPanelsCollapsed( id: string ): boolean {
    return this.getPanelsObject( id ).collapsed;
  }

  togglePanelsCollapsed( id: string ): void {
    this.setPanelsCollapsed( id, !this.getPanelsCollapsed( id ) );
  }


  /**
   * Companies can't be saved or deleted
   */
  canSave() {
    return false;
  }

  /**
   * Companies can't be saved or deleted
   */
  canDelete() {
    return false;
  }

  /**
   * Companies can't be saved or deleted
   */
  canUndo() {
    return false;
  }
}
