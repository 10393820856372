// Core modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

// Components
import { PlatformComponent } from './platform.component';
import { NavComponent } from './layout/nav/nav.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MessagesComponent } from './layout/messages/messages.component';
import { AbstractCardComponent } from './abstract/app/feature/card/abstract-card.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { D3Component } from './abstract/app/d3/d3.component';
import { ScatterPlotComponent } from './abstract/app/d3/scatter-plot/scatter-plot.component';
import { FeatureNameCardComponent } from './apps/scaffolding/feature/card/featureName-card.component';
import { FeatureNameDetailsComponent } from './apps/scaffolding/feature/details/featureName-details.component';
import { FeatureNameListComponent } from './apps/scaffolding/feature/list/featureName-list.component';

import { AbstractListComponent } from './abstract/app/feature/list/abstract-list.component';
import { AbstractDetailsComponent } from './abstract/app/feature/details/abstract-details.component';


// https://github.com/tomwanzek/d3-ng2-service
import { D3Service } from 'd3-ng2-service';

// Routing and services
import { platformRouting, appRoutingProviders } from './platform.routing';
import { PlatformService } from './platform.service';
import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { LogService } from './shared/log.service';
import { Helper } from './shared/helper.service';


// Apps
import { CoreModule } from './shared/core.module';
import { SharedModule } from './shared/shared.module';

// Apps
import { XirocoModule } from './apps/xiroco/xiroco.module';
import { ChatAndBotModule } from './apps/chatandbot/chatandbot.module';
import { PSConnectModule } from './apps/psconnect/psconnect.module';
import { TapappsModule } from './apps/tapapps/tapapps.module';
import { TapappsPOSModule } from './apps/tapapps-pos/tapapps-pos.module';
import { BitacoraModule } from './apps/bitacora/bitacora.module';
import { CardsModule } from './apps/cards/cards.module';

import { HomeComponent } from './layout/home';
import { AppGadgetComponent } from './layout/home/app-gadget/app-gadget.component';

import { PageNotFoundComponent } from './layout/page-not-found';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { UserAccountComponent } from './auth/user-account/user-account.component';

// Services
import { CardsAppService } from './apps/cards/cards-app.service';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    RouterModule,
    platformRouting,
    BrowserAnimationsModule,

    TapappsModule,
    XirocoModule,
    PSConnectModule,
    ChatAndBotModule,
    CardsModule,
    TapappsPOSModule,
    BitacoraModule


  ],
  declarations: [
    PlatformComponent,
    NavComponent,
    FooterComponent,
    MessagesComponent,
    HomeComponent,
    AppGadgetComponent,
    PageNotFoundComponent,
    SigninComponent,
    SignupComponent,
    UserAccountComponent,
    AbstractCardComponent,
    LoaderComponent,
    D3Component,
    AbstractListComponent,
    AbstractDetailsComponent,
    ScatterPlotComponent
    // FeatureNameCardComponent,
    // FeatureNameDetailsComponent,
    // FeatureNameListComponent
  ],

  providers: [
    appRoutingProviders,
    PlatformService,
    AuthService,
    AuthGuardService,
    LogService,
    Helper,
    CardsAppService,
    D3Service
  ],
  entryComponents: [
    PlatformComponent
  ],
  bootstrap: [
    PlatformComponent
  ]
})
export class PlatformModule {}
