import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { AbstractDetailsComponent } from '../../../../abstract/app/feature/details/abstract-details.component';

import { PlatformService } from '../../../../platform.service';
import { XirocoService } from '../../xiroco.service';
import { AppService } from '../../app/app.service';
import { App } from '../../app/app.model';

import { IAppFeature } from '../../app/app-feature.interface';


import { LogService } from '../../../../shared/log.service';
import { AuthService } from '../../../../auth/auth.service';
import { Helper } from '../../../../shared/helper.service';


@Component( {
  selector: 'xiroco-app-details',
  templateUrl: 'app-details.component.html'
} )
export class AppDetailsComponent extends AbstractDetailsComponent {

  public featuredGroupCollapsed: boolean = false;
  public featuresGroupCollapsed: boolean = false;

  public doc: App;

  private FEATURES_FORM_ARRAY_NAME: string = 'features';

  constructor( public route: ActivatedRoute,
               public router: Router,
               public platformService: PlatformService,
               public appService: XirocoService,
               public service: AppService,
               public location: Location,
               public logService: LogService,
               public helper: Helper,
               public authService: AuthService,
               public formBuilder: FormBuilder ) {
    super( route, router, platformService, appService, service, location, logService, helper, authService, formBuilder );
    this.logService.log( 'AppDetailsComponent', 'Constructor ok!' );
  }

  /**
   * Call ngOnInit an initilize nested form groups or form arrays
   */
  ngOnInit() {
    super.ngOnInit();

    // It is necessary add a form array control to this form, if not an error it is throw due to DOM binding
    // (formArrayName=...)
    this.form.addControl( this.FEATURES_FORM_ARRAY_NAME, new FormArray( [] ) );
  }

  /**
   * This method set the docs properties to the form controls
   */
  doc2form() {
    super.doc2form();
    try {
      this.doc.data.features.forEach( ( feature: IAppFeature ) => {
        // Check if collapsed exists
        if ( typeof( feature.collapsed ) == 'undefined' ) {
          feature.collapsed = true;
        }
        this.addFeature( feature );
      } );

    } catch ( e ) {
      console.error( e );
    }
  }


  // ********* FEATURES ********* //

  /**
   * Get a new feature entity
   * @param feature
   * @returns {FormGroup}
   */
  initFeature( feature: IAppFeature ): FormGroup {
    var self = this;
    console.groupCollapsed( 'INIT FEATURE' );
    let theFormGroup = new FormGroup( {} );
    console.info( 'Final formGroup', theFormGroup );
    console.groupEnd();
    return theFormGroup;
  }

  getEmptyFeature(): any {
    return {
      id: "new",
      name: "New feature",
      path: "",
      collapsed: false,
      card: {
        titleField: "",
        featuredFields: []
      },
      form: {
        groups: []
      }
    };
  }

  // Add a brand new feature (click button)
  newFeature(): void {
    this.addFeature( this.getEmptyFeature() );
  }


  // Insert a new feature into features array
  // TODO: When add new feature increments the id with a number and test that
  // no 2 similars ids
  addFeature( feature: IAppFeature ): void {
    const control = <FormArray>this.form.controls[ 'features' ];
    console.info( 'adding', feature );
    control.push( this.initFeature( feature ) );
  }

  // Removes a feature from the feature array
  removeFeature( i: number ): void {
    var self = this;
   
        const control = <FormArray>self.form.controls[ 'features' ];
        control.removeAt( i );
      
  }

  getFeatureFormControls( id: string ): any {
    const featuresFormArray = <FormArray>this.form.controls[ 'features' ];

    console.info( 'Getting', featuresFormArray.getRawValue() );
    return featuresFormArray.getRawValue().filter( feature => {
      return feature.id == id;
    } )[ 0 ];
  }

  setFeatureCollapsedStatus( id: string, status: boolean ): void {
    console.info( 'setting', this.getFeatureFormControls( id ), 'to', status );
    this.getFeatureFormControls( id ).collapsed = status;
    console.info( 'result', this.getFeatureFormControls( id ).collapsed );
  }

  getFeatureCollapsedStatus( id: string ): boolean {
    return this.getFeatureFormControls( id ).collapsed;
  }

  toggleFeatureCollapsed( id: string ): void {
    console.info( 'toggle', id );
    this.setFeatureCollapsedStatus( id, !this.getFeatureCollapsedStatus( id ) );
  }


}
