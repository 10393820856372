import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { PlatformService } from '../../platform.service';
import { Observable } from "rxjs";
import * as _ from 'lodash';
import { IAppFeature } from '../../apps/xiroco/app/app-feature.interface';

import { AbstractService } from './feature/abstract.service';

@Injectable()
export class AbstractAppService {

    private app = '';
    public instanceDateTime: Date;
    private _stats = {};

    // Override this in child clases in order to store cache data
    // locally and have it accesible offline
    public localStorage = true;

    public navBar: any = {
        "left": [],
        "right": []
      };

    public settings: any = {};

    // Show or hide home app navbar
    public showNav = true;

    public showHomeButton = true;

    // Features processed
    _features: any;

    // Here we have registered underlying features services
    public _featureServices: any = {};
    public _featureServicesCollection: any[] = [];

    constructor(
        public platformService: PlatformService,
        public id: string,
        public http: HttpClient
    ) {
        this.setApp( this.id );
        this.settings = this.platformService.settings.apps[ this.id ];

        console.log( 'App', id, 'constructor with default settings:', this.settings );

        this.instanceDateTime = new Date();

        // Register as an app in the platform
        platformService.apps.push( this );
        console.log( id, 'registered as app in the platform at ', this.instanceDateTime );

        // Restore data from local storage, if it is saved
        this.loadCacheFromDisk();
    }

    /**
     * This method register an underlying feature service in an object to acccess it by id and in 
     * an array (collection) in order to loop throw
     * @param featureService Feature service to be registered
     */
    registerFeatureService( featureService ): void {
        this._featureServices[ featureService.getId() ] = featureService;
        this._featureServicesCollection.push( featureService );
    }

    /**
     * Manage local storage for cache syste
     * Read when load app and store when get docs
     * in order to have a data copy when offline
     */
    localStorageItemName(): string {
        return this.platformService.localStorageCachePrefix + this.getApp();
    }

    /**
     * Save cache to local storage
     */
    saveCacheToDisk(): void {
        if ( this.localStorage ) {
            localStorage.setItem( this.localStorageItemName(), JSON.stringify( this.getStats() ) );
        }
    }

    /**
      * Retrieve cache from local storage
      */
    loadCacheFromDisk(): void {
        console.log( 'Cargamos cache del disco' );
        const storedData = localStorage.getItem( this.localStorageItemName() );
        if ( storedData ) {
            try {
               this._stats = JSON.parse( storedData );
            } catch ( err ) {
                console.error( err );
            }
        }
    }

    getSettings(): any {
      if ( !this.settings ) {
        console.warn( 'No app settings in abstract app service getSettings', this.getApp() );
        return {};
      } else {
        return this.settings || {};
      }
    }

    public getImage(): string {
        return this.getSettings().image || '';
    }

    public getServerURL(): string {
        return ( this.getSettings().serverURL || this.platformService.getServerURL() )
            + '/' + ( this.getSettings().path || this.getSettings().id );
    }

    public getSubtitle(): string {
        return this.getSettings().subtitle || '';
    }

    public hideFeaturesInHome(): boolean {
        return this.getSettings().hideFeaturesInHome || false;
    }

    public setServerURL( value: string ): void {
        this.getSettings().serverURL = value;
        this.platformService.savePlatformSettings();
    }

    public getStyles(): string {
        return this.getApp();
    }

    public setApp( value: string ): void {
        this.app = value;
    }
    public getApp(): string {
        return this.app;
    }

    public getAppName(): string {
        return this.getSettings().name || this.app;
    }


    setSettings(): void {
        this.settings = this.platformService.getAppSettings( this.getApp() );
    }

    public getTokenParam() {
        return '?token=' + this.getToken();
    }
    /**
     * Returns the full URL with token param
     */
    public getURLWithToken( path: string ): string {

        let tmp = this.getServerURL();
        if ( path ) {
            tmp += '/' + path;
        }
        tmp += this.getTokenParam();
        return tmp;
    }

    public getToken(): string {
        return localStorage.getItem( 'auth_token' );
    }

    public getHeaders(): HttpHeaders {
        const contentHeaders = new HttpHeaders();
        contentHeaders.append( 'Accept', 'application/json' );
        contentHeaders.append( 'Content-Type', 'application/json' );
        contentHeaders.append( 'Access-Control-Allow-Origin', '*' );
        contentHeaders.append( 'X-Auth-Token', this.getToken() );
        return contentHeaders;
    }

    public handleResponse( res: Response ) {
        let body = res.json();
        return body.data || { };
    }

    public handleError( error: any ) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error( errMsg );
        return Observable.throw( errMsg );
    }

    public getPath(): string {
        return this.getSettings().path || this.getSettings().id || 'no-path'; 
    }

    /**
     * Returns feature services object
     */
    public getFeatureServices(): any {        
            return this._featureServices;
    }

    public getFeatureServicesCollection(): any[] {
        return this._featureServicesCollection;
    }

    /**
     * Returns all app features sorted by usage
     */
    getAppAllFeaturesSortedByUsage(): any[] {
        const self = this;
        return self.getFeaturesArray().sort( 
            ( a: any, b: any ) => {
                return ( self.getFeatureUsage( a ) ) - ( self.getFeatureUsage( b ) );
            } 
        );
    }

    getFeaturesArray(): any[] {
        const features = this.getFeaturesFromSettings();
        if ( !Array.isArray( features ) ) {
            return _.values( features );
        } else {
            return features;
        }
    }
    getFeatureUsage( featureId: string ): number {
        return 22;
    }
    
    getFeaturesFromSettings(): any[] {
      const appSettings = this.platformService.getAppSettings( this.getApp() );
      if ( !appSettings ) {
        console.warn( 'No app settings found in platform service getFeaturesFromSettings', this.getApp() );
        return [];
      } else {
        return appSettings.features || [];
      }
    }

    /**
     * Return de amount of features highlihted
     */
    getNumberOfPrimaryFeatures(): number {
      const appSettings = this.getSettings();
      if ( !appSettings ) {
        console.warn( 'No app settings found in platform service getNumberOfPrimaryFeatures', this.getApp() );
        return 4;
      } else {
        return appSettings.numberOfPrimaryFeatures 
                    ? ( this.getSettings().numberOfPrimaryFeatures.appDesktop || this.getSettings().numberOfPrimaryFeatures )
                    : 4;
      }
        
    }

    /**
     * Returns an app more often used features 
     */
    getAppMainFeatures() {
        const allFeatureServices = this.getAppAllFeaturesSortedByUsage();
        const howMany = this.getNumberOfPrimaryFeatures();
        return allFeatureServices.slice( 0, howMany );
    }

    /**
     * Returns an app less often used features
     */
    getAppSecondaryFeatures() {
        const allFeatureServices = this.getAppAllFeaturesSortedByUsage();
        const howMany = allFeatureServices.length - this.getNumberOfPrimaryFeatures();
        return howMany ? allFeatureServices.slice( this.getNumberOfPrimaryFeatures() ) : [];
    }


    getName(): string {
        return this.getSettings().name || this.getSettings().id;
    }

    localStorageStatsName(): string {
        return this.platformService.localStorageCachePrefix + this.getAppName() + '-stats';
    }

    // Save stats cache to local storage
    saveStatsCacheToDisk(): void {
        if ( this.localStorage ) {
           localStorage.setItem( this.localStorageStatsName(), JSON.stringify( this.getStats() ) );
        }
    }

    /**
     * This method call stats endpoint in order to retrieve statistics for 
     * all app features
     */
    downloadStats(): Observable<any[]> {
        const self = this;

        const httpOptions = {
            headers: this.getHeaders()
        };

        const url = self.getURLWithToken( 'stats' );

        return self.http.get( url, httpOptions ).map( featuresStats => {
            const stats = {
                "timestamp": new Date(),
                "features": featuresStats
            };
            self._stats = stats;
            self.saveCacheToDisk();
            console.log( stats );

            return self.getStats();
        } );
    }

    getStats(): any {
        return this._stats;
    }

    /**
     * This method returns feature statistics
     * @param feature Feature name
     */
    getFeatureStats( featureName: string ): any {
        return this.getStats() && this.getStats().features && this.getStats().features.rows
            ? this.getStats().features.rows.filter( feature => feature.collection == featureName )[ 0 ]
            : null;
    }

    getUsage(): number {
        return _.get( this.platformService.getUserCustomSettings(), this.getCustomSettingsPath() + '.usage' ) || 0;
    }

    setUsage( value: number ): void {
        _.set( this.platformService.getUserCustomSettings(), this.getCustomSettingsPath() + '.usage', value );
    } 
    
    incrementUsage(): void {
        this.setUsage( this.getUsage() + 1 );
    }

    getCustomSettingsPath(): string {
        return this.getId();
    }

    getId(): string {
        return this.getApp();
    }

}
