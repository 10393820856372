// Modules
import { Routes, RouterModule } from '@angular/router';

// Services
import { AuthGuardService } from './auth/auth-guard.service';

// Layout components
import { HomeComponent } from './layout/home';
import { PageNotFoundComponent } from './layout/page-not-found';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { UserAccountComponent } from './auth/user-account/user-account.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'signin', component: SigninComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'user-account', component: UserAccountComponent, canActivate: [ AuthGuardService ] },
    { path: '**', component: PageNotFoundComponent }
];
export const appRoutingProviders: any[] = [];
export const platformRouting = RouterModule.forRoot( appRoutes );