/**
 * Model
 */
import { IItem } from './item.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';
import { IGroup } from '../group/group.interface';
export class Item extends AbstractModel {

    id: number;
    groupId: number;
    name: string;
    img: string;
    keywords: string[];
    issues: number[];
    group: IGroup;

    constructor( obj: IItem = <IItem>{} ) {

        super( obj );

        // Default values
        if ( typeof( this.id ) === 'undefined' ) this.id = 0;
        if ( typeof( this.groupId ) === 'undefined' ) this.groupId = 0;
        if ( typeof( this.name ) === 'undefined' ) this.name = '';
        if ( typeof( this.type ) === 'undefined' ) this.type = '';
        if ( typeof( this.img ) === 'undefined' ) this.img = '';
        if ( typeof( this.keywords ) === 'undefined' ) this.keywords = [];
        if ( typeof( this.issues ) === 'undefined' ) this.issues = [];
        if ( typeof( this.group ) == 'undefined' ) this.group = <IGroup>{};
        
        // Fixed info
        this.type = 'item';
        this.app = 'chatandbot';
        this.params.collection = 'item';
        this.params.type = 'item';

    }

    

}
