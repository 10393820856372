/**
 * A log service thats organize logs by componentes and display in a log console
 */
import { Injectable } from '@angular/core';

interface LogEntry {
    cont: number;
    text: string;
    alert: boolean;
    document: any;
    textDocument: string;
}


@Injectable()
export class LogService {
    
    public logs = {};
    private cont: number = 1;
    public active: boolean = true;
    
    constructor() {
        this.log( 'LogComponent', 'Log service created', true );
    }

    /**
     * Adds a log record to the log console
     * @param component
     * @param text
     * @param alert
     * @param document
     */
    log( component: string, text: string, alert: boolean = false, document?: any ) {
        // If the component group it is not already created, create it
        if ( !this.logs[ component ] ) {
            this.logs[ component ] = [];
        }

        // Add the log entry the the corresponding logs group
        this.logs[ component ].push( <LogEntry>{
            "cont": this.cont++,
            "text": text,
            "alert": alert,
            "document": document,
            "textDocument": JSON.stringify( document, null, 4 )
        });

        // If we send a document, print it by console so it is good to inspect
        if ( document ) {
            console.info( component, text, document );
        }
    }

    /**
     * Activate the log service
     * @param value
     * @returns {LogService}
     */
    setActive( value: boolean ) {
        this.active = value;
        return this;
    }

    /**
     * Return the keys of log object (groups of logs)
     * @returns {string[]}
     */
    public getLogKeys() {
        return Object.keys( this.logs );
    }

    /**
     * Clear the whole log record
     */
    clear() {
        this.getLogKeys().forEach( key => {
            this.logs[ key ] = [];
        });
        this.logs = {};
    }

    /**
     * Return if log it is empty
     * @returns {boolean}
     */
    logEmpty() {
        return Object.keys( this.logs ).length === 0;
    }
    
    
}