/**
 * Model
 */
import { IGroup,  } from './group.interface';
import { AbstractModel  } from '../../../abstract/app/feature/abstract.model';

export class Group extends AbstractModel {

    groupId: number;

    constructor( obj: IGroup = <IGroup>{} ) {

        super( obj );

        // Default values
        if ( typeof( this.groupId ) === 'undefined' ) this.groupId = 0;
        if ( typeof( this.data.weight ) === 'undefined' ) this.data.weight = 0;
        if ( typeof( this.data.name ) === 'undefined' ) this.data.name = '';
        if ( typeof( this.data.img ) === 'undefined' ) this.data.img = '';


        // Fixed info
        this.type = 'group';
        this.app = 'chatandbot';
        this.params.collection = 'group';
        this.params.type = 'group';

    }
}
