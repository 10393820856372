/**
 * This component is an adaptation of the "Brush & Zoom II" Example provided by
 * Mike Bostock at https://bl.ocks.org/mbostock/f48fcdb929a620ed97877e4678ab15e6
 */

import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Directive, Input, ViewChild} from '@angular/core';

import { D3Component } from '../d3.component';

import { PlatformService } from '../../../../platform.service';

import {
  D3Service,
  D3,
  Axis,
  BrushBehavior,
  BrushSelection,
  D3BrushEvent,
  ScaleLinear,
  ScaleOrdinal,
  Selection,
  Transition
} from 'd3-ng2-service';


// scatter plor

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
})
export class BarChartComponent extends D3Component implements OnInit, OnDestroy {

  public divId = 'bar-chart';

  constructor( public platformService: PlatformService, element: ElementRef, public ngZone: NgZone, d3Service: D3Service) {
    super( platformService, element, ngZone, d3Service );
  }

}
