/**
 * This component is an adaptation of the "Brush & Zoom II" Example provided by
 * Mike Bostock at https://bl.ocks.org/mbostock/f48fcdb929a620ed97877e4678ab15e6
 */

import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Directive, Input, ViewChild} from '@angular/core';

import { PlatformService } from '../../../platform.service';

import {
  D3Service,
  D3,
  Axis,
  BrushBehavior,
  BrushSelection,
  D3BrushEvent,
  ScaleLinear,
  ScaleOrdinal,
  Selection,
  Transition
} from 'd3-ng2-service';



// scatter plor

@Component({
  selector: 'app-d3-component',
  template: '<svg width="960" height="600"></svg>'
})


export class D3Component implements OnInit, OnDestroy {

  public divId = 'd3-component-contanier';
  public d3: D3;
  public parentNativeElement: any;
  public d3Svg: Selection<SVGSVGElement, any, null, undefined>;
  public data: any;
  public demoData: any;
  public dataSources: any[] = [
    {
      'text': 'demo_data'

    }
  ];

  public dataSourceSelected: any;
  public literals = {
    'select_data_source': 'select_data_source'
  };

  @HostListener( 'window:resize', [ '$event' ] )
  onResize( event ) {
    console.log( 'D3 Component windows Size', this.divId, this.getClientWidth(), this.getClientHeigth() );
    this.drawGraph();
  }

  constructor(
    public platformService: PlatformService,
    private el: ElementRef,
    public ngZone: NgZone,
    d3Service: D3Service
  ) {
    this.d3 = d3Service.getD3();

    // Default values
    this.dataSourceSelected = this.dataSources[ 1 ];

    // Translate fixed texts
    this.translateLiterals();
  }

  /**
   * This method translate literals to selected language
   */
  translateLiterals(): void {
    const self = this;
    self.dataSources.forEach( ( dataSource: any ) => {
      dataSource.text = self.platformService.i18n( dataSource.text );
    });

    Object.keys( self.literals ).forEach( literal => {
      self.literals[ literal ] = self.platformService.i18n( literal );
    });

  }

  ngOnInit() {
    if ( this.dataSourceSelected && this.hasData() ) {
      this.drawGraph();
    }
  }

  ngOnDestroy() {
  }

  hasData(): boolean {
    return this.data;
  }

  drawGraph(): void {
    console.log( 'Drawing graph', this.divId, '...' );
  }

  getClientWidth(): number {
    return document.getElementById( this.divId ).clientWidth;
  }

  getClientHeigth(): number {
    return document.getElementById( this.divId ).clientHeight;
  }

  selectDataSource( event: any, index: number ): void {
    const self = this;
    self.dataSourceSelected = self.dataSources[ index ];
    self.setData( index )
      .then( () => self.drawGraph() )
      .catch( error => {
        console.error( error );
      });
  }

  setData( i: number ): Promise<any> {

    return new Promise( ( resolve, reject ) => {
      try {
        this.data = this.demoData;
        resolve();
      } catch ( error ) {
        reject( error );
      }
    });
  }

  dataSourceName(): string {
    return this.dataSourceSelected ? this.dataSourceSelected.text : this.literals[ 'select_data_source' ];
  }

}
