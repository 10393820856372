// Modules
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders }  from '@angular/core';

// Main component
import { BitacoraComponent } from './bitacora.component';

// Feature components
import { LogbookListComponent } from './logbook/list/logbook-list.component';
import { LogbookDetailsComponent } from './logbook/details/logbook-details.component';

import { BeaconsListComponent } from './beacons/list/beacons-list.component';
import { BeaconsDetailsComponent } from './beacons/details/beacons-details.component';

// Services
import { AuthGuardService } from '../../auth/auth-guard.service';

//TODO: Hay que revisar esto, si me traigo aquí las rutas de xiroco (que están en platform.routing) me da error de duplicación del módulo ????

const psconnectRoutes: Routes = [
    { path: 'bitacora', component: BitacoraComponent, canActivate: [ AuthGuardService ] },
    { path: 'bitacora', canActivate: [ AuthGuardService ],
        children: [
            // Feature routes
			{ path: 'logbook', component: LogbookListComponent, canActivate: [ AuthGuardService ] },
			{ path: 'logbook/:_id', component: LogbookDetailsComponent, canActivate: [ AuthGuardService ] },
			{ path: 'beacons', component: BeaconsListComponent, canActivate: [ AuthGuardService ] },
			{ path: 'beacons/:_id', component: BeaconsDetailsComponent, canActivate: [ AuthGuardService ] }            
        ]
    }
];

//export const xirocoRouting = RouterModule.forChild( xirocoRoutes );
export const bitacoraRouting: ModuleWithProviders = RouterModule.forChild( psconnectRoutes );