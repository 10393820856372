import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';

import { PlatformService } from '../../platform.service';

@Component({
  selector: 'messages',
  templateUrl: 'messages.component.html'
})
export class MessagesComponent implements OnInit {

    public messages: string[] = [ 'hola', 'adios' ];

    constructor( private platformService: PlatformService ) {}

    ngOnInit() {
        //this.platformService.addMessage( "Error message test" );
    }

    getMessages() {
        return this.platformService.getMessages();
    }

    removeMessage( message: string ) {
        this.platformService.removeMessage( message );
    }

}
