import { Component, Input, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AbstractModel } from '../abstract.model';
import { AbstractService } from '../abstract.service';
import { PlatformService } from '../../../../platform.service';
import { LogService } from '../../../../shared/log.service';

import * as yaml from 'js-yaml';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component( {
  selector: 'abstract-card',
  templateUrl: 'abstract-card.component.html'
} )
export class AbstractCardComponent implements OnInit, OnDestroy {

  public submitted = false;
  public errorMessage: string;
  public serverError: boolean = true;
  public doc: AbstractModel;
  public cardFields: string[] = [];

  public titleField: string = '';
  public featuredFields: string[] = [];

  public theTitle: string = 'hola';
  public showCardMenu: boolean = false;

  @Input() feature: string;
  @Input() _id: string;
  @Input() id: string;


  @HostListener( 'mouseenter' )
  onMouseEnter() {
    this.mouseEnter();
  }

  @HostListener( 'mouseleave' )
  onMouseLeave() {
    this.mouseLeave();
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: AbstractService,
    public location: Location,
    public platformService: PlatformService,
    public logService: LogService
  ) { }

  ngOnInit() {
    const self = this;

    if ( !this.id ) {
      this.id = this.getRandomId();
    }

    self.service.setFeature( self.feature );
    self.doc = self.getDocCached();
    if ( !self.doc ) {
      self.getDoc().then( doc => {
        self.setCardFieldsArray();
      } ).catch( console.error );
    } else {
      self.setCardFieldsArray();
    }
  }
  
  mouseEnter() {
    // this.showCardMenu = true;
  }
  mouseLeave() {
    // this.showCardMenu = false;
  }

  /**
   * When click, redirect to details
   * @param row
   */
  onSelect() {
    this.showCardMenu = !this.showCardMenu;
  }
  
  onDoubleClick(): void {
    this.edit();
  }

  tagClick( event: any, tag: string ) {
    if ( _.findIndex( this.service.tagsClicked, tagIterated => tagIterated === tag ) === -1 ) {
      this.service.tagClick( tag );
    }
  }

  i18n( label: string ): string {
    return this.platformService.i18n( label );
  }

  getRandomId() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for ( let i = 0; i < 5; i++ ) {
      text += possible.charAt( Math.floor( Math.random() * possible.length ) );
    }
    return text;
  }

  getYAMLData(): string {

    try {
      const y = yaml.dump( this.doc.data || this.doc );
      return y;
    } catch ( error ) {
      console.warn( error );
    }

  }

  /**
   * This method returns the doc if it is in the cache
   */
  getDocCached(): any {
    return this.service.getCache( this._id );
  }



  setCardFieldsArray(): void {
    this.cardFields[0] = this.doc && this.doc.data ? this.doc.data[Object.keys( this.doc.data )[0]] : '';
    this.cardFields[1] = this.doc && this.doc.data ? this.doc.data[Object.keys( this.doc.data )[1]] : '';
    this.cardFields[2] = this.doc && this.doc.data ? this.doc.data[Object.keys( this.doc.data )[2]] : '';

  }
  getCSSClases() {
    return ( this.doc.draft ? ['draft'] : [] )
      .concat
      ( this.doc.data && this.doc.data.tags && Array.isArray( this.doc.data.tags )
        ? this.doc.data.tags : [] );
  }

  /**
   * This method get the document from cache if it is cached in user service or from an http request if not
   */
  getDoc() {
    const self = this;
    return new Promise( ( resolve, reject ) => {
      if ( !this._id ) {
        console.error();
        reject( { "message": "ERROR in abstract-card.getDoc: No _id" } );
      } else {

        // Not in service cache, going to download from server
        this.service.getDoc( this._id )
          .subscribe(
            doc => {
              this.doc = doc;
              resolve( doc );
            },
            errorResponse => {
              self.platformService.loaderVisible = false;
              self.logService.log( 'AbstractCardComponent', 'Error in getDocs', true, errorResponse.error );
              self.platformService.HTTPErrorHandle( errorResponse );
              reject( errorResponse );
            }
          );
      }
    } );

  }

  ngOnDestroy() { }

  

  edit() {
    this.router.navigate(
      [
        this.router.url,
        this._id
      ]
    );
  }

  getDataArray() {
    return Object.keys( this.doc.data );
  }

  getDescription(): string {
    return this.doc.data ? this.doc.data.description || '' : '';
  }


  getTags(): string[] {
    return this.doc.data
      && this.doc.data.tags
      && this.doc.data.tags.length
      ? this.doc.data.tags
      : null;
  }
  getMainTag(): string {
    const tags = this.getTags();
    return tags ? tags[0] : null;
  }

  getFields() {
    if ( !this.doc ) {
      return [];
    } else {
      return this.featuredFields.map( key => {
        return {
          "label": key,
          "value": this.doc.data[key]
        };
      } );
    }
  }

  setFeature( value: string ): void {
    this.feature = value;
  }

  updated(): string {
    return moment( this.doc[ 'updatedAt' ] ).fromNow() || '&nbsp;';
  }

  getTitle(): string {
    return this.cardFields[0];
  }

  confirm(): void {

  }

  isDraft(): boolean {
    return this.doc.data ? this.doc.data.draft : false;
  }

  canEdit(): boolean {
    return true;
  }

}
