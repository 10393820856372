// Modules
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders }  from '@angular/core';

// Components
import { PSConnectComponent } from './psconnect.component';
//import { ProductListComponent } from './product/list/product-list.component';
//import { ProductCardComponent } from './product/details/product-details.component';

// Services
import { AuthGuardService } from '../../auth/auth-guard.service';

//TODO: Hay que revisar esto, si me traigo aquí las rutas de xiroco (que están en platform.routing) me da error de duplicación del módulo ????

const psconnectRoutes: Routes = [
    { path: 'psconnect', component: PSConnectComponent, canActivate: [ AuthGuardService ] },
    { path: 'psconnect', canActivate: [ AuthGuardService ],
        children: [
            //{ path: 'product', component: ProductListComponent, canActivate: [ AuthGuardService ] },
            //{ path: 'product/:_id', component: ProductDetailsComponent, canActivate: [ AuthGuardService ] }
        ]
    }
];

//export const xirocoRouting = RouterModule.forChild( xirocoRoutes );
export const psconnectRouting: ModuleWithProviders = RouterModule.forChild( psconnectRoutes );